import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeaturedcityAds from "../ads/FeaturedcityAds";

const FeaturedCities = () => {
  return (
    <div>
      <div id="sec3">
        <div className="container text-center" style={{backgroundColor:"#f8f2fb",padding:"40px 25px"}}>
        <div className="col-md-6">
                    <h6 className="jobcategory_sec_2_heading_1 mb-4">
                      Featured Jobs By Companies
                    </h6>
                  </div>
          
          <div class="grid-container">
            {/* <div className="row mt-2"> */}
            {/* <div className="col-lg-12"> */}
            {/* <div className="row"> */}
            {/* <div className="col-lg-2 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0 0 8px rgb(0 0 0 / 25%)",
                    }}
                  >
                    <Link to={`/browsefilterlist?locate=hyderabad`}>
                      <div className="d-flex" style={{ padding: "2px 10px" }}>
                        <img
                          className="img-fluid "
                          src="images/Hyderabad-01.png"
                          alt=""
                          style={{ height: "50px" }}
                        />
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#562a9b",
                            fontWeight: "bold",
                          }}
                          className="m-auto"
                        >
                          Hyderabad
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div> */}
            {/* <div className="col-lg-2 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0 0 8px rgb(0 0 0 / 25%)",
                    }}
                  >
                    <Link to={`/browsefilterlist?locate=mumbai`}>
                      <div className="d-flex" style={{ padding: "2px 10px" }}>
                        <img
                          className="img-fluid"
                          src="images/Mumbai-01.png"
                          alt=""
                          style={{ height: "50px" }}
                        />
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#562a9b",
                            fontWeight: "bold",
                          }}
                          className="m-auto"
                        >
                          Mumbai
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div> */}
            {/* <div className="col-lg-2 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0 0 8px rgb(0 0 0 / 25%)",
                    }}
                  >
                    <Link to={`/browsefilterlist?locate=chennai`}>
                      <div className="d-flex" style={{ padding: "2px 10px" }}>
                        <img
                          className="img-fluid"
                          src="images/Chennai-01.png"
                          alt=""
                          style={{ height: "50px" }}
                        />
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#562a9b",
                            fontWeight: "bold",
                          }}
                          className="m-auto"
                        >
                          Chennai
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div> */}
            {/* <div className="col-lg-2 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0 0 8px rgb(0 0 0 / 25%)",
                    }}
                  >
                    <Link to={`/browsefilterlist?locate=pune`}>
                      <div className="d-flex" style={{ padding: "2px 10px" }}>
                        <img
                          className="img-fluid"
                          src="images/Pune-01.png"
                          alt=""
                          style={{ height: "50px" }}
                        />
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#562a9b",
                            fontWeight: "bold",
                          }}
                          className="m-auto"
                        >
                          Pune
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div> */}
            {/* <div className="col-lg-2 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0 0 8px rgb(0 0 0 / 25%)",
                    }}
                  >
                    <Link to={`/browsefilterlist?locate=bangalore`}>
                      <div className="d-flex" style={{ padding: "2px 10px" }}>
                        <img
                          className="img-fluid"
                          src="images/Bengaluru-01.png"
                          alt=""
                          style={{ height: "50px" }}
                        />
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#562a9b",
                            fontWeight: "bold",
                          }}
                          className="m-auto"
                        >
                          Bengaluru
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div> */}
            {/* <div className="col-lg-2 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0 0 8px rgb(0 0 0 / 25%)",
                    }}
                  >
                    <Link to={`/browsefilterlist?locate=delhi`}>
                      <div className="d-flex" style={{ padding: "2px 10px" }}>
                        <img
                          className="img-fluid"
                          src="images/Delhi-01.png"
                          alt=""
                          style={{ height: "50px" }}
                        />
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#562a9b",
                            fontWeight: "bold",
                          }}
                          className="m-auto"
                        >
                          Delhi
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div> */}
            {/* </div> */}

            <div class="grid-item">
              <Link to={`/browsefilterlist?locate=hyderabad`}>
                <div className="d-flex" style={{ padding: "2px 10px" }}>
                  <img
                    className="img-fluid "
                    src="images/Hyderabad-01.png"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#562a9b",
                      fontWeight: "bold",
                    }}
                    className="m-auto"
                  >
                    Hyderabad
                  </h3>
                </div>
              </Link>
            </div>
            <div class="grid-item">
              <Link to={`/browsefilterlist?locate=mumbai`}>
                <div className="d-flex" style={{ padding: "2px 10px" }}>
                  <img
                    className="img-fluid"
                    src="images/Mumbai-01.png"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#562a9b",
                      fontWeight: "bold",
                    }}
                    className="m-auto"
                  >
                    Mumbai
                  </h3>
                </div>
              </Link>
            </div>
            <div class="grid-item">
              <Link to={`/browsefilterlist?locate=chennai`}>
                <div className="d-flex" style={{ padding: "2px 10px" }}>
                  <img
                    className="img-fluid"
                    src="images/Chennai-01.png"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#562a9b",
                      fontWeight: "bold",
                    }}
                    className="m-auto"
                  >
                    Chennai
                  </h3>
                </div>
              </Link>
            </div>
            <div class="grid-item">
              <Link to={`/browsefilterlist?locate=pune`}>
                <div className="d-flex" style={{ padding: "2px 10px" }}>
                  <img
                    className="img-fluid"
                    src="images/Pune-01.png"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#562a9b",
                      fontWeight: "bold",
                    }}
                    className="m-auto"
                  >
                    Pune
                  </h3>
                </div>
              </Link>
            </div>
            <div class="grid-item">
              <Link to={`/browsefilterlist?locate=bangalore`}>
                <div className="d-flex" style={{ padding: "2px 10px" }}>
                  <img
                    className="img-fluid"
                    src="images/Bengaluru-01.png"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#562a9b",
                      fontWeight: "bold",
                    }}
                    className="m-auto"
                  >
                    Bengaluru
                  </h3>
                </div>
              </Link>
            </div>
            <div class="grid-item">
              <Link to={`/browsefilterlist?locate=delhi`}>
                <div className="d-flex" style={{ padding: "2px 10px" }}>
                  <img
                    className="img-fluid"
                    src="images/Delhi-01.png"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#562a9b",
                      fontWeight: "bold",
                    }}
                    className="m-auto"
                  >
                    Delhi
                  </h3>
                </div>
              </Link>
            </div>
          </div>

<div style={{textAlign:"right"}}>
          <Link to="/locationaljobs" className="float-right All-Links">
            <i class="fas fa-arrow-right pr-2"></i> View All Locations
          </Link>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCities;
