import React from 'react'
import { Link } from 'react-router-dom'
import AdminLoginForm from './AdminLoginForm'
import Updatehrprofile from './Updatehrprofile'
import CreateAdminProfile from './CreateAdminProfile'

const ViewAdminProfile = () => {
    return (
        <div className='view_admin' style={{ marginTop: "110px", backgroundColor: "#ecf0f1", paddingTop: "100px", paddingBottom: "1px" }}>
            <div className='container card pb-5 mb-5' style={{ backgroundColor: "#ffffff", fontFamily: "'tawk-icon'" }}>
                <div className='hr_profile_backdivbtn ml-auto'>
                    <Link to="/dashboard">
                        <button className='hr_profile_backbtn'>Back</button>
                    </Link></div>

                <div className='row mt-4'>
                    <div className='col-lg-3 col-md-4 col-sm-5 text-center'>
                        <label for="file">
                            <p href="#">
                                <img
                                    className="resume_img img-responsive"
                                    alt=""
                                    // src={
                                    //     profile.profileImage
                                    //         ? profile.profileImage
                                    //         : `images/girl_avtar.png`
                                    // }
                                    src='images/ashokk.jpeg'
                                />
                            </p>

                            <i class="fas fa-camera img_edit_sidebar"></i>
                        </label>
                    </div>
                    <div className='col-lg-9 col-md-8 col-sm-7 my-auto hr_profile_details'>
                        <p><b style={{ fontSize: "40px" }}>Admin Name</b>,<i>Admin Position</i></p>
                        <p><b>Id:</b>123456789</p>
                    </div>

                </div>


                <hr />




                <div id="exTab3" class="container">

                    {/* Tabs */}

                    <ul class="nav nav-pills dash_navpills mt-5">
                        <li style={{ marginBottom: "20px" }}>
                            <a href="#1b" data-toggle="tab" className='dash_tab_links active'>Data Base</a>
                        </li>
                        <li><a href="#2b" data-toggle="tab" className='dash_tab_links'>Job Posting</a>
                        </li>
                        <li><a href="#3b" data-toggle="tab" className='dash_tab_links'>Combo Products</a>
                        </li>
                        <hr />
                    </ul>

                    <div class="tab-content clearfix">


                        {/* Dashboard1 */}
                        <div class="tab-pane active" id="1b">


                            {/* Dashboard Cards starting */}
                            <div className='row'>

                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>Excel Downloads</span>
                                        <h3>200</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>

                                <div className='col-md-3' >
                                    <div className='card dashboard_card'>
                                        <span>Profile Views</span>
                                        <h3>100</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>SMS</span>
                                        <h3>50</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>Job Postings</span>
                                        <h3>00</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>

                            </div>
                            {/* Dashboard card Ending */}


                            <div className="table-responsive dashboard_total_table">
                                <table className="table table-hover table-nowrap text-center">


                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Position</th>
                                            {/* <th scope="col">Offer</th>
                                            <th scope="col">Meeting</th> */}
                                            <th scope="col">Employee Id</th>
                                            <th scope="col">Action</th>

                                        </tr>
                                    </thead>


                                    <tbody>
                                        <tr style={{ backgroundColor: "#ffffff" }}>
                                            <td>
                                                <img alt="..." src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" className="avatar avatar-sm rounded-circle me-2 ml-4" />
                                                <a className="text-heading font-semibold" href="#">
                                                    HR Name
                                                </a>
                                            </td>
                                            <td>
                                                oct 5, 2021
                                            </td>
                                            <td>
                                                <img alt="..." src="images/ProHireeLogo.png" className="avatar avatar-xs rounded-circle me-2" />
                                                <a className="text-heading font-semibold" href="#">
                                                    HR Manager
                                                </a>
                                            </td>
                                            <td>
                                                123456789
                                            </td>
                                            {/* <td>
                                                <span className="text-success">
                                                    Scheduled
                                                </span>
                                            </td> */}
                                            <td className="">
                                                <a href="#" className="btn btn-sm btn-neutral admin_view_btn" data-toggle="modal"
                                                    data-target="#update_HR_Profile">Edit</a>
                                                <Link to="/viewhrprofile" className="btn btn-sm btn-neutral admin_view_btn">View</Link>
                                                <button type="button" className="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="fas fa-trash"></i>
                                                </button>

                                            </td>
                                        </tr>

                                        {/* Create HR Profile Modal */}
                                        <div
                                            class="modal fade "
                                            id="Create_HR_Profile"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="Create_HR_ProfileTitle"
                                            aria-hidden="true"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                                                role="document"
                                            >
                                                <div class="modal-content search_candidates_modal">
                                                    <div className="modal-header modal-header--sticky">
                                                        <button
                                                            type="button"
                                                            className="close modal_srcand_close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className="modal_content">
                                                            {/* <CreateProfile /> */}
                                                            <CreateAdminProfile />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        {/* Create HR Profile Modal */}


                                    </tbody>


                                </table>
                            </div>



                        </div>



                        {/* Dashboard1 */}



                        {/* Dashboard2 */}
                        <div class="tab-pane" id="2b">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <div className=' d-inline'>
                                            <span>Excel Downloads</span>
                                            <h3>00</h3>
                                            {/* <span>Since last month</span> */}
                                        </div>
                                        {/* <div>Hello</div> */}
                                    </div>

                                </div>

                                <div className='col-md-3' >
                                    <div className='card dashboard_card'>
                                        <span>Profile Views</span>
                                        <h3>00</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>SMS</span>
                                        <h3>00</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>Job Postings</span>
                                        <h3>100</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>
                            </div>


                            <div className="table-responsive dashboard_total_table">
                                <table className="table table-hover table-nowrap text-center">


                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Position</th>
                                            {/* <th scope="col">Offer</th>
                                            <th scope="col">Meeting</th> */}
                                            <th scope="col">Employee Id</th>
                                            <th scope="col">Action</th>

                                        </tr>
                                    </thead>


                                    <tbody>
                                        <tr style={{ backgroundColor: "#ffffff" }}>
                                            <td>
                                                <img alt="..." src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" className="avatar avatar-sm rounded-circle me-2 ml-4" />
                                                <a className="text-heading font-semibold" href="#">
                                                    HR Name
                                                </a>
                                            </td>
                                            <td>
                                                oct 5, 2021
                                            </td>
                                            <td>
                                                <img alt="..." src="images/ProHireeLogo.png" className="avatar avatar-xs rounded-circle me-2" />
                                                <a className="text-heading font-semibold" href="#">
                                                    HR Manager
                                                </a>
                                            </td>
                                            <td>
                                                123456789
                                            </td>
                                            {/* <td>
                                                <span className="text-success">
                                                    Scheduled
                                                </span>
                                            </td> */}
                                            <td className="">
                                                <a href="#" className="btn btn-sm btn-neutral admin_view_btn" data-toggle="modal"
                                                    data-target="#update_HR_Profile">Edit</a>
                                                <Link to="/viewhrprofile" className="btn btn-sm btn-neutral admin_view_btn">View</Link>
                                                <button type="button" className="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="fas fa-trash"></i>
                                                </button>

                                            </td>
                                        </tr>

                                        {/* Create HR Profile Modal */}
                                        <div
                                            class="modal fade "
                                            id="Create_HR_Profile"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="Create_HR_ProfileTitle"
                                            aria-hidden="true"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                                                role="document"
                                            >
                                                <div class="modal-content search_candidates_modal">
                                                    <div className="modal-header modal-header--sticky">
                                                        <button
                                                            type="button"
                                                            className="close modal_srcand_close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className="modal_content">
                                                            {/* <CreateProfile /> */}
                                                            <CreateAdminProfile />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        {/* Create HR Profile Modal */}


                                    </tbody>


                                </table>
                            </div>

                        </div>

                        {/* Dashboard2 */}



                        {/* Dashboard3 */}

                        <div class="tab-pane" id="3b">
                            <div className='row'>


                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <div className=' d-inline'>
                                            <span>Excel Downloads</span>
                                            <h3>250</h3>
                                            {/* <span>Since last month</span> */}
                                        </div>
                                        {/* <div>Hello</div> */}
                                    </div>
                                </div>


                                <div className='col-md-3' >
                                    <div className='card dashboard_card'>
                                        <span>Profile Views</span>
                                        <h3>1000</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>



                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>SMS</span>
                                        <h3>50</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>


                                <div className='col-md-3'>
                                    <div className='card dashboard_card'>
                                        <span>Job Postings</span>
                                        <h3>10</h3>
                                        {/* <span>Since last month</span> */}
                                    </div>
                                </div>


                            </div>

                            <div className="table-responsive dashboard_total_table">
                                <table className="table table-hover table-nowrap text-center">


                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Position</th>
                                            {/* <th scope="col">Offer</th>
                                            <th scope="col">Meeting</th> */}
                                            <th scope="col">Employee Id</th>
                                            <th scope="col">Action</th>

                                        </tr>
                                    </thead>


                                    <tbody>
                                        <tr style={{ backgroundColor: "#ffffff" }}>
                                            <td>
                                                <img alt="..." src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" className="avatar avatar-sm rounded-circle me-2 ml-4" />
                                                <a className="text-heading font-semibold" href="#">
                                                    HR Name
                                                </a>
                                            </td>
                                            <td>
                                                oct 5, 2021
                                            </td>
                                            <td>
                                                <img alt="..." src="images/ProHireeLogo.png" className="avatar avatar-xs rounded-circle me-2" />
                                                <a className="text-heading font-semibold" href="#">
                                                    HR Manager
                                                </a>
                                            </td>
                                            <td>
                                                123456789
                                            </td>
                                            {/* <td>
                                                <span className="text-success">
                                                    Scheduled
                                                </span>
                                            </td> */}
                                            <td className="">
                                                <a href="#" className="btn btn-sm btn-neutral admin_view_btn" data-toggle="modal"
                                                    data-target="#update_HR_Profile">Edit</a>
                                                <Link to="/viewhrprofile" className="btn btn-sm btn-neutral admin_view_btn">View</Link>
                                                <button type="button" className="btn btn-sm btn-square btn-neutral text-danger-hover">
                                                    <i class="fas fa-trash"></i>
                                                </button>

                                            </td>
                                        </tr>

                                        {/* Create HR Profile Modal */}
                                        <div
                                            class="modal fade "
                                            id="Create_HR_Profile"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-labelledby="Create_HR_ProfileTitle"
                                            aria-hidden="true"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                                                role="document"
                                            >
                                                <div class="modal-content search_candidates_modal">
                                                    <div className="modal-header modal-header--sticky">
                                                        <button
                                                            type="button"
                                                            className="close modal_srcand_close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className="modal_content">
                                                            {/* <CreateProfile /> */}
                                                            <CreateAdminProfile />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        {/* Create HR Profile Modal */}


                                    </tbody>


                                </table>
                            </div>

                        </div>

                        {/* Dashboard3 */}





                    </div>
                </div>

            </div>


            {/* Update HR Profile Modal */}
            <div
                class="modal fade "
                id="update_HR_Profile"
                tabindex="-1"
                role="dialog"
                aria-labelledby="update_HR_ProfileTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content search_candidates_modal">
                        <div className="modal-header modal-header--sticky">
                            <button
                                type="button"
                                className="close modal_srcand_close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="modal_content">
                                <Updatehrprofile />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/* Update HR Profile Modal */}





        </div>
    )
}

export default ViewAdminProfile
