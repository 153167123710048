import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
// import apiList from "../../../lib/apiList";
import apiList from "../../lib/apiList";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Jobposting = () => {

  const result = useSelector((state) => state.data);
  console.log(result);
  const [modalShow, setModalShow] = React.useState(false);
  const [totalAmount, setTotalamount] = useState();
  const [user, setUser] = useState();
  const [priceDetails, setPriceDetails] = useState({
    value: "",
    gst: "",
  });
  const onHide = () => setModalShow(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const modalDynamic = (e) => {
    setPriceDetails({
      ...priceDetails,
      value: e.value,
      gst: e.gst,
    });
    setTotalamount(Number(e.value) + Number(e.gst));
    setModalShow(true);
  };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    axios
      .get(apiList.user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setUser(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }
  function isObj(val) {
    return typeof val === "object";
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    return fetch(`${apiList.paytmpayment}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const makePayment = () => {
    if (result) {
      // console.log("working")
      getData({
        name: user.companyname,
        email: user.email,
        phone: JSON.stringify(user.contactNumber),
        amount: JSON.stringify(totalAmount),
      }).then((response) => {
        var information = {
          action: "https://securegw.paytm.in/theia/processTransaction",
          params: response,
        };
        post(information);
      });
    } else {
      toast.error("You Must Login First");
    }
  };

  return (
    <div>

      <Modal
        isOpen={modalShow}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalShow(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="payment_modal">
          <div className="close_modal_btn">
            <a
              className="close_modal"
              onClick={() => setModalShow(false)}
            >
              <i className="fas fa-times"></i>
            </a>
          </div>
          <div className="payment_details_heading ">
            <h5>Payment Details</h5>
          </div>

          <div className="payment_details">
            <p>
              <span className="payment_1">Total </span> :
              <span className="payment_2">
                {" "}
                &#8377; {priceDetails.value}
              </span>{" "}
            </p>
            <p>
              <span className="payment_1">Estimated GST </span> :
              <span className="payment_2">
                {" "}
                &#8377; {priceDetails.gst}
              </span>{" "}
            </p>
          </div>
          <hr className="hr_divider" />
        </div>
        <div className="payment_details">
          <p>
            {" "}
            <span className="payment_1">
              Total Payable Amount
            </span> :{" "}
            <span className="payment_2"> &#8377; {totalAmount}</span>
          </p>
        </div>
        <div className="buynow_payment">
          <button className="buynow_here" onClick={makePayment}>
            Proceed
          </button>
        </div>
      </Modal>

      {/* <div className="container">
        <div className="row">
          <div className="col-md-4">
            <article className="card__content1 grid">
              <div className="card__pricing">
                <div className="card__pricing-number">
                  <span className="card__pricing-symbol">₹ </span>24999
                </div>
                <span className="card__pricing-month">+ GST</span>
              </div>

              <header className="card__header">
                <div className="card__header-circle grid">
                  <img src="assets/img/free-coin.png" alt="" className="card__header-img" />
                </div>

                <span className="card__header-subtitle"></span>
                <h1 className="card__header-title">3 Months Plan</h1>
              </header>

              <ul className="card__list grid">
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">3 user request</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">10 downloads per day</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Daily content updates</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Fully editable files</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Fully editable files</p>
                </li>
              </ul>

              <button className="card__button">Buy Now</button>
            </article>
          </div>

          {/* <!--==================== CARD 2 ====================--> *
          <div className="col-md-4">
            <article className="card__content1 grid">
              <div className="card__pricing">
                <div className="card__pricing-number">
                  <span className="card__pricing-symbol">₹</span>44999
                </div>
                <span className="card__pricing-month">+ GST</span>
              </div>

              <header className="card__header">
                <div className="card__header-circle grid">
                  <img src="assets/img/pro-coin.png" alt="" className="card__header-img" />
                </div>

                {/* <span className="card__header-subtitle">6 Months Plan</span> *
                <h1 className="card__header-title">6 Months Plan</h1>
              </header>

              <ul className="card__list grid">
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">100 user request</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Unlimited downloads</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Unlock all features from our site</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Daily content updates</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Fully editable files</p>
                </li>
              </ul>

              <button className="card__button">Buy Now</button>
            </article>
          </div>

          {/* <!--==================== CARD 3 ====================--> *
          <div className="col-md-4">
            <article className="card__content1 grid">
              <div className="card__pricing">
                <div className="card__pricing-number">
                  <span className="card__pricing-symbol">₹</span>79999
                </div>
                <span className="card__pricing-month">+ GST</span>
              </div>

              <header className="card__header">
                <div className="card__header-circle grid">
                  <img src="assets/img/enterprise-coin.png" alt="" className="card__header-img" />
                </div>

                {/* <span className="card__header-subtitle">1 Year Plan</span> *
                <h1 className="card__header-title">1 Year Plan</h1>
              </header>

              <ul className="card__list grid">
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Unlimited  user request</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Unlimited downloads</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Unlock all features from our site</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Daily content updates</p>
                </li>
                <li className="card__list-item">
                  <i className="fas fa-check card__list-icon"></i>
                  <p className="card__list-description">Fully editable files</p>
                </li>
              </ul>

              <button className="card__button">Buy Now</button>
            </article>
          </div>
        </div>
      </div> */}



    

      

      

  

      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="card p-4" style={{borderRadius:"10px"}}>
              <h1 className="jp_head">Free Job Posting</h1>

              <div className="jp_money">
                <h1 className="">₹ 0</h1>
                <p>month</p>
              </div>

              <p className="jp_para">Maximum 20 unlocks next unlock 50 rupees per unlock or convert to premium</p>

              <button type="button" className="btn jp_btn">Get Started</button>
            </div>

          </div>
          <div className="col-lg-3">
            <div className="card p-4  " style={{borderRadius:"10px"}}>
              <h1 className="jp_head">Free Job Posting</h1>

              <div className="jp_money">
                <h1 className="">₹ 799</h1>
                <p>month</p>
              </div>

              <p className="jp_para">Maximum 20 unlocks next unlock 50 rupees per unlock or convert to premium</p>

              <button type="button" className="btn jp_btn" onClick={() => {
            modalDynamic({
              value: "799",
              gst: "143",
            })
          }}>Buy Now</button>
            </div>

          </div>
          <div className="col-lg-3">
            <div className="card p-4" style={{borderRadius:"10px"}}>
              <h1 className="jp_head">Free Job Posting</h1>

              <div className="jp_money">
                <h1 className="">₹ 1499</h1>
                <p>month</p>
              </div>

              <p className="jp_para">Maximum 20 unlocks next unlock 50 rupees per unlock or convert to premium</p>

              <button type="button" className="btn jp_btn" onClick={() => {
            modalDynamic({
              value: "1499",
              gst: "269",
            })
          }}>Buy Now</button>
            </div>

          </div>
          <div className="col-lg-3">
            <div className="card p-4" style={{borderRadius:"10px"}}>
              <h1 className="jp_head">Free Job Posting</h1>

              <div className="jp_money">
                <h1 className="">₹ 2299</h1>
                <p>month</p>
              </div>

              <p className="jp_para">Maximum 20 unlocks next unlock 50 rupees per unlock or convert to premium</p>

              <button type="button" className="btn jp_btn" onClick={() => {
            modalDynamic({
              value: "2299",
              gst: "413",
            })
          }}>Buy Now</button>
            </div>

          </div>
        </div>
      </div>
    </div>


  );
};

export default Jobposting;
