import React from "react";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiList from "../lib/apiList";
import Modal from "react-modal";
import "./auth.css";
import { GoogleLogin, GoogleLogout, useGoogleLogout } from "react-google-login";
import { Link } from "react-router-dom";

const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const RecruiterFair = () => {
  const [selectedClient, setSelectedClient] = useState("");
  const dispatch = useDispatch();
  const [mainTab, setMainTab] = useState("signup");
  const [subTab, setSubTab] = useState(false);
  const [phone, setPhone] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showVerifyBtn, setVerifyBtn] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [contactSessionId, setContactSessionId] = useState();
  const [isContactVerified, setContactVerified] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const navigate = useNavigate();

  const handleLogin = (e) => {
    let haveError = false;
    if (e.target.email.value == "") {
      haveError = true;
      setEmailError("Email is required!");
    }
    if (e.target.password.value == "") {
      haveError = true;
      setPasswordError("Password is required!");
    }

    e.preventDefault();
    if (!haveError) {
      let loginDetails = {
        email: e.target.email.value,
        password: e.target.password.value,
      };
      axios
        .post(apiList.login, loginDetails)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          localStorage.setItem("isAuth", "true");
          dispatch({ type: "USER", payload: response.data });
          toast.success("Login Successful");
          console.log(response);
          if (response.data.type === "applicant") {
            navigate("/appliedjobs");
          } else {
            navigate("/jobfair");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          console.log(err.response);
        });
    }
  };

  const handleSignUp = (e) => {
    console.log("working");
    e.preventDefault();
    let haveError = false;
    if (e.target.email.value == "") {
      haveError = true;
      setEmailError("Email is required!");
    }

    if (!e.target.contactNumber.value.match(/^[6-9]\d{9}$/)) {
      haveError = true;
      setPhoneError("Enter a valid contactNumber number.");
    }
    if (e.target.contactNumber.value == "") {
      haveError = true;
      setPhoneError("Phone is required!");
    }
    if (haveError) {
      return;
    }

    if (!isContactVerified) {
        toast.error("Contact needs to be verified!")
    }
    
    let signupDetails = {
      email: e.target.email.value,
      name: e.target.name.value,
      // password: e.target.password.value,
      hrname: e.target.hrname.value,
      description: e.target.description.value,
      type: e.target.type.value,
      contactNumber: e.target.contactNumber.value,
    };
    axios
      .post(apiList.signup, signupDetails)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        dispatch({ type: "USER", payload: response.data });
        toast.success("Registered Successful");
        console.log(response);
        console.log("success data");

        navigate("/fairsuccessimg");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
        console.log("error data");
      });
  };

  const handleOTPSend = (e) => {
    let haveError = false;
    if (e.target.phone.value == "") {
      haveError = true;
      setPhoneError("Phone number is required!");
    }
    if (e.target.phone.value.length != 10) {
      haveError = true;
      setPhoneError("Enter valid phone number!");
    }
    e.preventDefault();
    if (haveError) {
      return;
    }
    e.preventDefault();
    let otpDetails = {
      phone: e.target.phone.value,
    };
    axios
      .post(apiList.sendOTP, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        toast.success("OTP Sended");
        console.log(response);
        setSubTab("verifyOtp");
        setPhone(otpDetails.phone);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (!phone) {
      setSubTab(false);
      return;
    }
    let otpDetails = {
      phone,
      otp: e.target.otp.value,
    };
    axios
      .post(apiList.verifyOTP, otpDetails)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        dispatch({ type: "USER", payload: response.data });
        toast.success("Login Successfully");
        console.log(response);

        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleForgotPassword = (e) => {
    let haveError = false;
    if (e.target.phone.value == "") {
      haveError = true;
      setPhoneError("Phone number is required!");
    }
    if (e.target.phone.value.length != 10) {
      haveError = true;
      setPhoneError("Enter valid phone number!");
    }
    e.preventDefault();
    if (haveError) {
      return;
    }
    let otpDetails = {
      phone: e.target.phone.value,
    };
    axios
      .post(apiList.forgotPassword, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        toast.success("New password send to your number");
        console.log(response);
        setSubTab(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleContactInput = (e) => {
    let value = e.target.value;
    setContactNumber(value);
    setContactVerified(false);
    if (!value || value.trim() === "") {
      setVerifyBtn(false);
      return "contactNumber number is Required";
    } else if (!value.match(/^[6-9]\d{9}$/)) {
      setVerifyBtn(false);
      return "Enter a valid contactNumber number.";
    } else {
      setVerifyBtn(true);
    }
  };

  const handleContactVerify = () => {
    let otpDetails = {
      phone: contactNumber,
    };
    axios
      .post(apiList.contactSendOTP, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        setIsOpen(true);
        setContactSessionId(response.data.sessionId);
        console.log(response);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleContactOTPVerify = (e) => {
    console.log("contactSessionId", contactSessionId);
    e.preventDefault();
    let otpDetails = {
      sessionId: contactSessionId,
      otp: e.target.otp.value,
    };
    axios
      .post(apiList.contactVerifyOTP, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        setIsOpen(false);
        toast.success("Contact Verified");
        setContactSessionId();
        setContactVerified(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [isSignupPwd, setIsSignupPwd] = useState(false);

  const [isConfirmPwd, setIsConfirmPwd] = useState(false);

  const resetError = () => {
    setEmailError("");
    // setPasswordError("");
    setPhoneError("");
  };

  const handleSelectChange = (e) => {
    setSelectedClient(e.target.value);
    // console.log(selectedClient);
  };

  const handleRecruiter = (e) => {
    setSelectedClient(e.target.value);
    // console.log(selectedClient);
  };

  const handleApplicant = (e) => {
    setSelectedClient(e.target.value);
    // console.log(selectedClient);
  };

  const onGoogleSignUpSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    console.log("Token - ", res.tokenObj);
    let signupDetails = {
      email: res.profileObj.email,
      name: res.profileObj.name,
      // password: e.target.password.value,
      type: selectedClient,
      // contactNumber: e.target.contactNumber.value,
    };
    axios
      .post(apiList.signup + "/google", signupDetails, {
        headers: {
          Authorization: res.tokenObj.id_token,
        },
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        dispatch({ type: "USER", payload: response.data });
        toast.success("Registered Successful");
        console.log(response);
        signOut();
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
        signOut();
      });
    // refreshTokenSetup(res);
  };

  const onGoogleLoginSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    console.log("Token - ", res.tokenObj);
    let loginDetails = {
      email: res.profileObj.email,
      // password: e.target.password.value,
      // contactNumber: e.target.contactNumber.value,
    };
    axios
      .post(apiList.login + "/google", loginDetails, {
        headers: {
          Authorization: res.tokenObj.id_token,
        },
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("isAuth", "true");
        dispatch({ type: "USER", payload: response.data });
        toast.success("Login Successful");
        console.log(response);
        signOut();
        if (response.data.type === "applicant") {
          navigate("/appliedjobs");
        } else {
          navigate("/Manage_jobs");
        }
      })
      .catch((err) => {
        signOut();
        toast.error(err.response.data.message);
        console.log(err.response);
      });
    // refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    toast.error("Failed to login");
  };

  const onLogoutSuccess = (res) => {
    console.log("Logged out Success");
    // alert('Logged out Successfully ✌');
  };

  const onLogoutFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onLogoutFailure,
  });

  return (
    <div>
      <div style={{ marginTop: "160px" }}>
        <div className="container">
          <div className="card mb-5">
            <div className="row">
              <form
                className="pt-5 pb-5"
                onSubmit={handleSignUp}
                onChange={resetError}
              >
                <div className="row job_fair_forms_student">
                  <div className="col-lg-12 col-md-12 mb-4">
                    <div className="form-group hire_group">
                      <select
                        className="input__field form_control form_hire"
                        name="type"
                        value={selectedClient}
                        onChange={handleSelectChange}
                      >
                        <option value="recruiter">Recruiter</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Company Name:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          style={{ borderRadius: "4px" }}
                          type="text"
                          required
                          name="name"
                          placeholder=" "
                        />
                        <span className="input__label">Enter Company Name</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>HR Name:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          required
                          style={{ borderRadius: "4px" }}
                          name="hrname"
                          placeholder=" "
                        />
                        <span className="input__label">Enter HR Name</span>
                      </label>
                    </div>
                  </div>

                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Email ID:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="email"
                          required
                          style={{ borderRadius: "4px" }}
                          name="email"
                          placeholder=" "
                        />
                        <span className="input__label">Enter Email</span>
                      </label>
                    </div>
                  </div>

                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Mobile Number:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          style={{ borderRadius: "5px" }}
                          // required
                          name="contactNumber"
                          placeholder=" "
                          maxLength={10}
                          onChange={handleContactInput}
                        />

                        <span className="input__label">Enter Number</span>
                      </label>
                      <button
                        type="button"
                        className="verfy-studentfair-btn"
                        onClick={handleContactVerify}
                        disabled={!showVerifyBtn || isContactVerified}
                      >
                        {isContactVerified ? "Verified" : "Verify"}
                      </button>
                    </div>
                    {phoneError != "" && (
                      <small style={{ color: "red" }}>{phoneError}</small>
                    )}
                  </div>

                  <div className=" col-lg-12 col-md-12">
                    <div className="form-group hire_group">
                      <label>Job Description:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <textarea
                          className="input__field form_control form_hire"
                          type="text"
                          style={{ borderRadius: "4px" }}
                          rows={3}
                          required
                          name="description"
                          placeholder=" "
                        />
                        <span className="input__label">
                          Enter Job Description
                        </span>
                      </label>
                    </div>
                  </div>

                  <button className="jobfair_btn jobfair_regbtn mt-3">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div class="  text-center">
          <h6>
            Please enter the one time password <br /> to verify your account
          </h6>
          {/* <div> <span>A code has been sent to</span> <small>***9897</small> </div> */}
          <form onSubmit={handleContactOTPVerify}>
            <div
              id="otp"
              class="inputs d-flex flex-row justify-content-center mt-4"
            >
              <input
                type="text"
                className="form-control w-50"
                id="exampleInputName"
                placeholder="Enter OTP"
                maxLength="6"
                name="otp"
                required
              />
            </div>
            <div>
              {" "}
              <button
                type="submit"
                class="btn btn-verify px-4 validate mt-4"
                aria-label="Close"
                data-dismiss="modal"
              >
                Validate
              </button>{" "}
            </div>
          </form>
        </div>
        <div class="card-2 mt-3">
          <div class="content d-flex justify-content-center align-items-center">
            {" "}
            <span>Didn't get the code</span>{" "}
            <a href="#" class="text-decoration-none ms-3">
              {" "}
              Resend
            </a>{" "}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RecruiterFair;