import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiList from "../lib/apiList";
import Modal from "react-modal";
import "./auth.css";
import { GoogleLogin, GoogleLogout, useGoogleLogout } from "react-google-login";
import { Link } from "react-router-dom";
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Auth = (props) => {
  const [selectedClient, setSelectedClient] = useState("applicant");
  const dispatch = useDispatch();
  const [mainTab, setMainTab] = useState("signup");
  const [subTab, setSubTab] = useState(false);
  const [phone, setPhone] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showVerifyBtn, setVerifyBtn] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [contactSessionId, setContactSessionId] = useState();
  const [isContactVerified, setContactVerified] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");

  const navigate = useNavigate();

  const switchMainTab = (tab) => {
    setMainTab(tab);
    setSubTab(false);
  };

  console.log("mmmm", mainTab);

  const handleLogin = (e) => {
    let haveError = false;
    if (e.target.email.value == "") {
      haveError = true;
      setEmailError("Email is required!");
    }
    if (e.target.password.value == "") {
      haveError = true;
      setPasswordError("Password is required!");
    }

    e.preventDefault();
    if (!haveError) {
      let loginDetails = {
        email: e.target.email.value,
        password: e.target.password.value,
      };
      axios
        .post(apiList.login, loginDetails)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          localStorage.setItem("isAuth", "true");
          dispatch({ type: "USER", payload: response.data });
          toast.success("Login Successful");
          console.log(response);
          if (response.data.type === "applicant") {
            navigate("/appliedjobs");
          } else {
            navigate("/searchcandidate");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          console.log(err.response);
        });
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    setEmailError("");
    setPasswordError("");
    setPhoneError("");
    setNameError("");
  
    let haveError = false;
  
    // Regular expression to match letters (upper and lower case) with spaces
    const lettersWithSpacesRegex = /^[A-Za-z\s]+$/;
  
    if (e.target.name.value === "") {
      haveError = true;
      setNameError("Name is required!");
    } else if (!e.target.name.value.match(lettersWithSpacesRegex)) {
      haveError = true;
      setNameError("should only contain letters and spaces (no special characters or numbers)!");
    }
    
    // let haveError = false;

    if (e.target.email.value == "") {
      haveError = true;
      setEmailError("Email is required!");
    }
    if (e.target.password.value == "") {
      haveError = true;
      setPasswordError("Password is required!");
    }

    if (!e.target.contactNumber.value.match(/^[6-9]\d{9}$/)) {
      haveError = true;
      setPhoneError("Enter a valid contactNumber number.");
    }
    if (e.target.contactNumber.value == "") {
      haveError = true;
      setPhoneError("Phone is required!");
    }
    if (haveError) {
      return;
    }
    // if (e.target.password.value !== e.target.confirmPassword.value) {
    //   toast.error("Password is not matching");
    //   e.target.rest();
    //   return;
    // }

    // if (!isContactVerified) {
    //     toast.error("Contact needs to be verified!")
    // }
    let signupDetails = {
      email: e.target.email.value,
      name: e.target.name.value,
      password: e.target.password.value,
      // type: e.target.type.value,
      // gender:e.target.loginGender,
      type: selectedClient,
      contactNumber: e.target.contactNumber.value,
    };
    axios
      .post(apiList.signup, signupDetails)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        dispatch({ type: "USER", payload: response.data });
        toast.success("Registered Successful");
        console.log(response);

        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleOTPSend = (e) => {
    let haveError = false;
    if (e.target.phone.value == "") {
      haveError = true;
      setPhoneError("Phone number is required!");
    }
    if (e.target.phone.value.length != 10) {
      haveError = true;
      setPhoneError("Enter valid phone number!");
    }
    e.preventDefault();
    if (haveError) {
      return;
    }
    e.preventDefault();
    let otpDetails = {
      phone: e.target.phone.value,
    };
    axios
      .post(apiList.sendOTP, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        toast.success("OTP Sended");
        console.log(response);
        setSubTab("verifyOtp");
        setPhone(otpDetails.phone);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (!phone) {
      setSubTab(false);
      return;
    }
    let otpDetails = {
      phone,
      otp: e.target.otp.value,
    };
    axios
      .post(apiList.verifyOTP, otpDetails)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        dispatch({ type: "USER", payload: response.data });
        toast.success("Login Successfully");
        console.log(response);

        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleForgotPassword = (e) => {
    let haveError = false;
    if (e.target.phone.value == "") {
      haveError = true;
      setPhoneError("Phone number is required!");
    }
    if (e.target.phone.value.length != 10) {
      haveError = true;
      setPhoneError("Enter valid phone number!");
    }
    e.preventDefault();
    if (haveError) {
      return;
    }
    let otpDetails = {
      phone: e.target.phone.value,
    };
    axios
      .post(apiList.forgotPassword, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        toast.success("New password send to your number");
        console.log(response);
        setSubTab(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleContactInput = (e) => {
    let value = e.target.value;
    setContactNumber(value);
    setContactVerified(false);
    if (!value || value.trim() === "") {
      setVerifyBtn(false);
      return "contactNumber number is Required";
    } else if (!value.match(/^[6-9]\d{9}$/)) {
      setVerifyBtn(false);
      return "Enter a valid contactNumber number.";
    } else {
      setVerifyBtn(true);
    }
  };

  const handleContactVerify = () => {
    let otpDetails = {
      phone: contactNumber,
    };
    axios
      .post(apiList.contactSendOTP, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        setIsOpen(true);
        setContactSessionId(response.data.sessionId);
        console.log(response);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const handleContactOTPVerify = (e) => {
    console.log("contactSessionId", contactSessionId);
    e.preventDefault();
    let otpDetails = {
      sessionId: contactSessionId,
      otp: e.target.otp.value,
    };
    axios
      .post(apiList.contactVerifyOTP, otpDetails)
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("type", response.data.type);
        // dispatch({ type: "USER", payload: response.data })
        setIsOpen(false);
        toast.success("Contact Verified");
        setContactSessionId();
        setContactVerified(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const [state, setState] = useState({
    image: <img src="/images/Signup.jpg" className="signup_image img-fluid" />,
  });

  const imgColumn = () => {
    setState((state) => ({
      image: <img src="images/Login.jpg" className="signup_image img-fluid" />,
    }));
  };

  const imgColumn2 = () => {
    setState((state) => ({
      image: (
        <img src="/images/Signup.jpg" className="signup_image img-fluid" />
      ),
    }));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [isSignupPwd, setIsSignupPwd] = useState(false);

  const [isConfirmPwd, setIsConfirmPwd] = useState(false);

  const resetError = () => {
    setEmailError("");
    setPasswordError("");
    setPhoneError("");
  };

  const handleSelectChange = (e) => {
    setSelectedClient(e.target.value);
    // console.log(selectedClient);
  };

  const handleRecruiter = (e) => {
    setSelectedClient(e.target.value);
    // console.log(selectedClient);
  };

  const handleApplicant = (e) => {
    setSelectedClient(e.target.value);
    // console.log(selectedClient);
  };

  const onGoogleSignUpSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    console.log("Token - ", res.tokenObj);
    let signupDetails = {
      email: res.profileObj.email,
      name: res.profileObj.name,
      // password: e.target.password.value,
      type: selectedClient,
      // contactNumber: e.target.contactNumber.value,
    };
    axios
      .post(apiList.signup + "/google", signupDetails, {
        headers: {
          Authorization: res.tokenObj.id_token,
        },
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        dispatch({ type: "USER", payload: response.data });
        toast.success("Registered Successful");
        console.log(response);
        signOut();
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response);
        signOut();
      });
    // refreshTokenSetup(res);
  };

  const onGoogleLoginSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    console.log("Token - ", res.tokenObj);
    let loginDetails = {
      email: res.profileObj.email,
      // password: e.target.password.value,
      // contactNumber: e.target.contactNumber.value,
    };
    axios
      .post(apiList.login + "/google", loginDetails, {
        headers: {
          Authorization: res.tokenObj.id_token,
        },
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("isAuth", "true");
        dispatch({ type: "USER", payload: response.data });
        toast.success("Login Successful");
        console.log(response);
        signOut();
        if (response.data.type === "applicant") {
          navigate("/appliedjobs");
        } else {
          navigate("/Manage_jobs");
        }
      })
      .catch((err) => {
        signOut();
        toast.error(err.response.data.message);
        console.log(err.response);
      });
    // refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    toast.error("Failed to login");
  };

  const onLogoutSuccess = (res) => {
    console.log("Logged out Success");
    // alert('Logged out Successfully ✌');
  };

  const onLogoutFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onLogoutFailure,
  });

  return (
    <div style={{ backgroundColor: "#F8F2FB ", padding: "1px" }}>
      <div className="signin_signup container " id="main_form">
        <div className="row">
          <div className="col-lg-6 card modal_grid">
            <section className="main">
              <div className="form_wrapper">
                <input
                  type="radio"
                  className="radio"
                  name="radio"
                  id="login"
                  defaultChecked=""
                />
                <input
                  type="radio"
                  className="radio"
                  name="radio"
                  id="signup"
                />
                <div className="tile py-2">
                  {mainTab === "login" ? (
                    <>
                      <h3 className="login">Login</h3>
                      <p style={{ color: "#5319A8" }}>
                        It only takes a couple of minutes to get started!
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className="signup">Create an account</h3>
                      <p style={{ color: "#5319A8" }}>
                        It only takes a couple of minutes to get started!
                      </p>
                    </>
                  )}
                </div>

                {/* <label
                  id="signup_tab"
                  className={`tab login_tab ${
                    mainTab === "signup" && "active-tab"
                  }`}
                  htmlFor="signup"
                  onClick={() => {
                    switchMainTab("signup");
                    imgColumn2();
                    scrollToTop();
                  }}
                >
                  {" "}
                  Signup<i className="fas fa-solid fa-circle-check"></i>
                </label>

                <label
                  id="login_tab"
                  className={`tab login_tab ${
                    mainTab === "login" && "active-tab"
                  }`}
                  // active-tab
                  htmlFor="login"
                  onClick={() => {
                    switchMainTab("login");
                    imgColumn();
                    scrollToTop();
                  }}
                >
                  {" "}
                  Login <i className="fas fa-solid fa-circle-check"></i>
                </label> */}
                <div className="form_wrap">
                  {mainTab === "login" && (
                    <div className="form_fild login_form">
                      {!subTab && (
                        <div id="Menu1">
                          <form onSubmit={handleLogin} onChange={resetError}>
                            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <GoogleLogin
                                                            clientId={clientId}
                                                            buttonText="Log In with Google"
                                                            onSuccess={onGoogleLoginSuccess}
                                                            onFailure={onFailure}
                                                            cookiePolicy={'single_host_origin'}
                                                            style={{ marginTop: '100px' }}
                                                            isSignedIn={true}
                                                        />
                                                    </div> */}
                            <div className="input_group ">
                              <label>Email ID</label>
                              <input
                                type="email"
                                name="email"
                                className="signup_ip form-control"
                                placeholder="Email Address"
                              />
                              {/* <span className="input_email">
                                {" "}
                                <i
                                  class="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>{" "}
                              </span> */}
                            </div>
                            {emailError != "" && (
                              <small style={{ color: "red" }}>
                                {emailError}
                              </small>
                            )}
                            <div className="input_group">
                              <label>Password</label>
                              <input
                                type={isRevealPwd ? "text" : "password"}
                                name="password"
                                className="signup_ip form-control"
                                placeholder="Password"
                              />
                              {/* <span className="input_email">
                                {" "}
                                <i
                                  class="fa fa-lock"
                                  aria-hidden="true"
                                ></i>{" "}
                              </span> */}

                              <span
                                className="password_hide"
                                onClick={() =>
                                  setIsRevealPwd((prevState) => !prevState)
                                }
                              >
                                {isRevealPwd ? (
                                  <i
                                    class="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                )}
                              </span>
                            </div>
                            {passwordError != "" && (
                              <small style={{ color: "red" }}>
                                {passwordError}
                              </small>
                            )}
                            <div className="forgot">
                              <a
                                className="forgot_pass sign_forgot_pass"
                                onClick={() => setSubTab("forgotPassword")}
                              >
                                {" "}
                                Forgot password ?{" "}
                              </a>
                            </div>
                            <input
                              type="submit"
                              className="btn"
                              defaultValue="Login"
                            />
                            <div className="forgot text-center">
                              <a
                                href="#"
                                className="login_otp my-3"
                                onClick={() => setSubTab("otp")}
                              >
                                Login With OTP{" "}
                              </a>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "-15px",
                              }}
                            >
                              <GoogleLogin
                                clientId={clientId}
                                buttonText="Log In with Google"
                                onSuccess={onGoogleLoginSuccess}
                                onFailure={onFailure}
                                cookiePolicy={"single_host_origin"}
                                style={{ marginTop: "100px" }}
                                isSignedIn={true}
                                className="login_google"
                              />
                            </div>

                            <div className="already pt-3">
                              <label
                                htmlFor="signup"
                                onClick={() => {
                                  switchMainTab("signup");
                                  imgColumn2();
                                  scrollToTop();
                                }}
                              >
                                Not a member?{" "}
                                <span className="font-weight-bold">
                                  {" "}
                                  Signup now
                                </span>
                              </label>
                            </div>
                          </form>
                        </div>
                      )}

                      {subTab === "forgotPassword" && (
                        <div id="Menu2">
                          <form
                            onSubmit={handleForgotPassword}
                            onChange={resetError}
                          >
                            <label>Mobile Number</label>
                            <div className="input_group input-group">
                              <select className="signup_ip mr-3">
                                <option>+91</option>
                              </select>
                              <input
                                type="text"
                                className="signup_ip form-control"
                                placeholder="Enter Registered Mobile Number..."
                                name="phone"
                              />
                              {/* <span className="input_email">
                                {" "}
                                <i class="fas fa-phone"></i>{" "}
                              </span> */}
                            </div>
                            {phoneError != "" && (
                              <small style={{ color: "red" }}>
                                {phoneError}
                              </small>
                            )}
                            <input
                              type="submit"
                              className="btn"
                              defaultValue="Get password"
                            />
                            <div className="not_mem">
                              <a onClick={() => setSubTab(false)}>
                                {" "}
                                <label> Login With Email</label>
                              </a>
                            </div>
                          </form>
                        </div>
                      )}

                      {subTab === "otp" && (
                        <div id="Menu3">
                          <form onSubmit={handleOTPSend} onChange={resetError}>
                            <div className="input_group">
                              <label>Mobile Number</label>
                              <input
                                type="number"
                                className="signup_ip form-control"
                                placeholder="Enter Mobile Number"
                                name="phone"
                              />
                              {/* <span className="input_email">
                                {" "}
                                <i class="fas fa-phone"></i>{" "}
                              </span> */}
                            </div>
                            {phoneError != "" && (
                              <small style={{ color: "red" }}>
                                {phoneError}
                              </small>
                            )}
                            <a href="#" onclick="toggleVisibility('Menu4');">
                              {" "}
                              <input
                                type="submit"
                                className="btn"
                                defaultValue="Get OTP"
                              />
                            </a>
                            <div className="not_mem">
                              <a href="#" onClick={() => setSubTab(false)}>
                                {" "}
                                <label> Login With Email</label>
                              </a>
                            </div>
                          </form>
                        </div>
                      )}

                      {subTab === "verifyOtp" && (
                        <div id="Menu4">
                          <form onSubmit={handleVerifyOTP}>
                            <h6>Enter Your Received OTP</h6>
                            <div className="input_group">
                              <input
                                type="number"
                                className="signup_ip form-control"
                                placeholder="Enter Your OTP"
                                name="otp"
                                required
                              />
                              <span className="input_email">
                                {" "}
                                {/* <i
                                  class="fas fa-key"
                                  aria-hidden="true"
                                ></i>{" "} */}
                              </span>
                            </div>
                            <input
                              type="submit"
                              className="btn"
                              defaultValue="Submit OTP"
                            />
                            <div className="not_mem">
                              <a href="#" onClick={() => setSubTab(false)}>
                                {" "}
                                <label> Login With Email</label>
                              </a>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  )}
                  {mainTab === "signup" && (
                    <div className="form_fild signup_form">
                      <form onSubmit={handleSignUp} onChange={resetError}>
                        <div className="input_group">
                          {/* <select class="form-control multiple" name="type" value={selectedClient} onChange={handleSelectChange}>
                                                    <option hidden>Years</option>
                                                    <option value="applicant">Job Seekers</option>
                                                    <option value="recruiter">Recruiter</option>
                                                    </select> */}

                          {/* <select
                            className="form_control"
                            name="type"
                            value={selectedClient}
                            onChange={handleSelectChange}
                          >
                            <option hidden>Select Option</option>
                            <option value="applicant">JobSeekers</option>
                            <option value="recruiter">Recruiter</option>
                          </select> */}

                          <div>
                            <div
                              className="row"
                              onChange={handleSelectChange}
                              style={{ marginLeft: "0px" }}
                            >
                              <div
                                className={`col-lg-6 tab tab1 tab2 login_tab ${
                                  selectedClient === "applicant" && "active-tab"
                                }`}
                                onClick={() => setSelectedClient("applicant")}
                              >
                                Job Seekers{" "}
                                <i className="fas fa-solid fa-circle-check"></i>
                              </div>
                              <div
                                className={`col-lg-6 tab tab1 tab3 login_tab ${
                                  selectedClient === "recruiter" && "active-tab"
                                }`}
                                onClick={() => setSelectedClient("recruiter")}
                              >
                                Recruiter{" "}
                                <i className="fas fa-solid fa-circle-check"></i>
                              </div>
                            </div>
                          </div>

                          {/* <div className="bg-light">
                          <button type="button">Recruiter</button>
                          <button type="button">Job Seeker</button>
                          </div> */}

                          {/* <div className="row" style={{ width: "100%", marginLeft: "initial", marginTop: "-30px", marginBottom: "-20px" }}>

                            <div className="col-md-6" value={selectedClient} onChange={handleSelectChange}>
                              <div className="" >
                                <input
                                  className="visibility"
                                  type="radio"
                                  id="recruiter"
                                  name="login_form"
                                  value="recruiter"
                                  // onChange={(e) => handleRecruiter(e)}
                                />
                                <label for="recruiter" className="hello" style={{ verticalAlign: "bottom" }}><img src="images/recruiter-01.png" style={{ width: "35px", marginTop: "6px" }} />Recruiter</label>
                              </div>
                            </div>

                            <div className="col-md-6" value={selectedClient} onChange={handleSelectChange}>
                              <div className="">
                                <input
                                  className="visibility"
                                  type="radio"
                                  id="applicant"
                                  name="login_form"
                                  value="applicant"
                                  // onChange={(e) => handleApplicant(e)}
                                />
                                <label for="applicant" className="hello" style={{ verticalAlign: "bottom" }}><img src="images/student-01.png" style={{ width: "35px", marginTop: "6px" }} />Job Seekar</label>
                              </div>
                            </div>


                          </div> */}
                        </div>

                        {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                                <GoogleLogin
                                                    clientId={clientId}
                                                    buttonText="Sign Up with Google"
                                                    onSuccess={onGoogleSignUpSuccess}
                                                    onFailure={onFailure}
                                                    cookiePolicy={'single_host_origin'}
                                                    style={{ marginTop: '100px' }}
                                                    isSignedIn={true}
                                                />
                                                {/* <GoogleLogout
                                                    clientId={clientId}
                                                    buttonText="Logout"
                                                // onLogoutSuccess={onSuccess}
                                                ></GoogleLogout> *
                                            </div> */}

                        <div className="input_group">
                          <label>
                            {selectedClient === "applicant"
                              ? "Enter Name"
                              : "Company Name"}
                          </label>
                          <input
                            type="text"
                            // className="input"
                            className="form-control signup_ip"
                            placeholder={
                              selectedClient === "applicant"
                                ? "Enter Name"
                                : "Company Name"
                            }
                            name="name"
                          />
                          {nameError !== "" && (
                          <small style={{ color: "red" }}>{nameError}</small>
                        )}
                          {/* <span className="input_email">
                            {" "}
                            {selectedClient === "applicant" ? (
                              <i class="fa fa-user" aria-hidden="true"></i>
                            ) : (
                              <img
                                src="images/company-01.png"
                                style={{ width: "35px" }}
                              />
                            )}
                          </span> */}
                        </div>

                        <div
                          className="input_group"
                          style={{ position: "relative" }}
                        >
                          <label>Mobile Number</label>

                          <div className="input-group">
                            <select className="signup_ip mr-3">
                              <option>+91</option>
                            </select>

                            <input
                              type="text"
                              // className="text"
                              className="form-control signup_ip"
                              placeholder="Phone Number "
                              name="contactNumber"
                              maxLength={10}
                              onChange={handleContactInput}
                            />
                          </div>
                          {/* <span className="input_email">
                            {" "}
                            <i class="fas fa-phone"></i>{" "}
                          </span> */}
                          {/* <button type="button" className="verfy-special-btn btn" onClick={handleContactVerify} disabled={!showVerifyBtn || isContactVerified}>{isContactVerified ? 'Verified': 'Verify'}</button> */}
                        </div>

                        {phoneError != "" && (
                          <small style={{ color: "red" }}>{phoneError}</small>
                        )}

                        <div className="input_group">
                          <label>Email ID</label>
                          <input
                            type="email"
                            // className="input"
                            className="form-control signup_ip"
                            placeholder="Email Address"
                            name="email"
                          />
                          {/* <span className="input_email">
                            {" "}
                            <i
                              class="fa fa-envelope"
                              aria-hidden="true"
                            ></i>{" "}
                          </span> */}
                        </div>

                        {emailError != "" && (
                          <small style={{ color: "red" }}>{emailError}</small>
                        )}

                        <div className="input_group">
                          <label>Password</label>
                          <input
                            type={isSignupPwd ? "text" : "password"}
                            // className="input password_input"
                            className="form-control signup_ip"
                            placeholder="Password"
                            name="password"
                          />
                          {/* <span className="input_email">
                            {" "}
                            <i class="fa fa-lock" aria-hidden="true"></i>{" "}
                          </span> */}

                          <span
                            className="password_hide"
                            onClick={() => setIsSignupPwd(!isSignupPwd)}
                          >
                            {isSignupPwd ? (
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            ) : (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </span>
                        </div>
                        {passwordError != "" && (
                          <small style={{ color: "red" }}>
                            {passwordError}
                          </small>
                        )}
                        <div className="input_group">
                        <label>Confirm Password</label>
                          <input
                            type={isConfirmPwd ? "text" : "password"}
                            className="signup_ip form-control"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                          />
                          {/* <span className="input_email">
                            {" "}
                            <i class="fa fa-lock" aria-hidden="true"></i>{" "}
                          </span> */}
                          <span
                            className="password_hide"
                            onClick={() =>
                              setIsConfirmPwd((prevState) => !prevState)
                            }
                          >
                            {isConfirmPwd ? (
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            ) : (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </span>
                        </div>
{/* {selectedClient === "applicant" ?
<>
                        <label>Gender</label>

                        <div>
                          <input
                            type="radio"
                            name="loginGender"
                            className="mr-1 ml-2"
                          />{" "}
                          Male
                          <input
                            type="radio"
                            name="loginGender"
                            className="mr-1 ml-2"
                          />{" "}
                          Female
                          <input
                            type="radio"
                            name="loginGender"
                            className="mr-1 ml-2"
                          />{" "}
                          Prefer not to say
                        </div>
                        </> : null } */}




                        <div className="mt-3 signup_wa">
                          <input type="checkbox" className="mr-2 " /> I would
                          like to get latest job updates on Whatsapp
                        </div>
                        <input
                          type="submit"
                          className="btn"
                          defaultValue="Signup"
                        />

                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <GoogleLogin
                            clientId={clientId}
                            buttonText={
                              selectedClient === "applicant"
                                ? "Signup as Applicant"
                                : "Signup as Recruiter"
                            }
                            onSuccess={onGoogleSignUpSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            style={{ marginTop: "100px" }}
                            isSignedIn={true}
                            className="SignUp_google"
                          />

                          {/* <GoogleLogout
                                                    clientId={clientId}
                                                    buttonText="Logout"
                                                // onLogoutSuccess={onSuccess}
                                                ></GoogleLogout> */}
                        </div>

                        <div className="already pt-3">
                          <label
                            htmlFor="signup"
                            onClick={() => {
                              switchMainTab("login");
                              imgColumn();
                              scrollToTop();
                            }}
                          >
                            Already have an Account?{" "}
                            <span className="font-weight-bold">
                              {" "}
                              Login here{" "}
                            </span>
                          </label>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-4 text-center   full_img">
            {/* {state.image} */}
            {mainTab === "signup" ? (
              <>
                <img
                  src="https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7963.jpg?w=740&t=st=1688530788~exp=1688531388~hmac=2f71a6871d2d3683d4cd467c112ad077269681434e2ad04c236b8db697f7f3b5"
                  className="img-fluid"
                  style={{ height: "auto", borderRadius: "20px", width: "80%" }}
                />

                <div>
                  <ul
                    className="mt-3"
                    style={{
                      color: "#270d44",
                      marginLeft: "30px",
                      fontSize: "12px",
                    }}
                  >
                    <li style={{fontFamily:"system-ui"}}>
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>
                      Build your profile and let recruiter find you
                    </li>
                       <li style={{fontFamily:"system-ui"}}>
                      {" "}
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>Get
                      job postings delivered right to your email
                    </li>
                       <li style={{fontFamily:"system-ui"}}>
                      {" "}
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>Find
                      a job and grow your career
                    </li>
                  </ul>
                  <div className="text-left ml-4 mt-2">
                    <button
                      className="loginpage_side_register"
                      onClick={() => {
                        switchMainTab("login");
                        // imgColumn2();
                        // scrollToTop();
                      }}
                    >
                     Log In
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <img
                  src="https://img.freepik.com/premium-vector/online-registration-sign-up-with-man-sitting-near-smartphone_268404-95.jpg?w=996"
                  className="img-fluid"
                  style={{ height: "auto", width: "85%", borderRadius: "20px" }}
                />
                <div>
                  <ul
                    className="mt-3"
                    style={{
                      color: "#270d44",
                      marginLeft: "30px",
                      fontSize: "12px",
                    }}
                  >
                    <li style={{fontFamily:"system-ui"}}>
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>
                      One click apply using pabjobs profile.
                    </li>
                 <li style={{fontFamily:"system-ui"}}>
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>Get
                      relevant job recommendations.
                    </li>
                  <li style={{fontFamily:"system-ui"}}>
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>
                      Showcase profile to top companies and consultants.
                    </li>
                  <li style={{fontFamily:"system-ui"}}>
                      <i class="fas fa-solid fa-circle-check mt-4" style={{marginRight:"3px"}}></i>know
                      application status on applied jobs.
                    </li>
                  </ul>
                  <div className="text-left ml-4 mt-2">
                    <button
                      className="loginpage_side_register"
                      onClick={() => {
                        switchMainTab("signup");
                        // imgColumn2();
                        // scrollToTop();
                      }}
                    >
                      Register for free
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div class="  text-center">
          <h6>
            Please enter the one time password <br /> to verify your account
          </h6>
          {/* <div> <span>A code has been sent to</span> <small>***9897</small> </div> */}
          <form onSubmit={handleContactOTPVerify}>
            <div
              id="otp"
              class="inputs d-flex flex-row justify-content-center mt-4"
            >
              <input
                type="text"
                className="form-control w-50"
                id="exampleInputName"
                placeholder="Enter OTP"
                maxLength="6"
                name="otp"
                required
              />
            </div>
            <div>
              {" "}
              <button
                type="submit"
                class="btn btn-verify px-4 validate mt-4"
                aria-label="Close"
                data-dismiss="modal"
              >
                Validate
              </button>{" "}
            </div>
          </form>
        </div>
        <div class="card-2 mt-3">
          <div class="content d-flex justify-content-center align-items-center">
            {" "}
            <span>Didn't get the code</span>{" "}
            <a href="#" class="text-decoration-none ms-3">
              {" "}
              Resend
            </a>{" "}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Auth;
