
import React from 'react'

const GettingStuList = () =>
{
    
return(
    <>

    <div style={{marginTop:'200px'}}>
        GettingStuList
    </div>

</>
)
}

export default GettingStuList;