import React from 'react'

const CreateAdminProfile = () => {
  return (
    <div>
      <div>
            <div className='container'>
                <div className='row'>

                    

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Employee Id:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="employeeid"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Employee Id</span>
                            </label>
                        </div>
                    </div>

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Name:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="adminname"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Name</span>
                            </label>
                        </div>
                    </div>


                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Email:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="employeeemail"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Email</span>
                            </label>
                        </div>
                    </div>




                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Position:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="adminposition"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Position</span>
                            </label>
                        </div>
                    </div>


                    {/* <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Employee Id:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="employeeid"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Your EmployeeId</span>
                            </label>
                        </div>
                    </div> */}

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Create Password:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="createpassword"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Your Password</span>
                            </label>
                        </div>
                    </div>

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Confirm Password:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="confirmpassword"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Your confirm Password</span>
                            </label>
                        </div>
                    </div>


                    {/* <div className="col-lg-12 col-md-12">
                        <label>Meeting</label>
                        <div className="form-group">
                            <div className="form-check form-check-inline">
                                <input
                                    defaultChecked
                                    className="form-check-input"
                                    type="radio"
                                    name="meeting"
                                    id="scheduled"
                                  value="Scheduled"
                                />
                                <label
                                    className="form-check-label"
                                    for="scheduled"
                                >
                                    Scheduled
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="meeting"
                                    id="Postponed"
                                  value="Postponed"
                                />
                                <label
                                    className="form-check-label"
                                    for="Postponed"
                                >
                                    Postponed
                                </label>
                            </div>
                        </div>
                    </div> */}


                    <button className="jobfair_btn jobfair_regbtn mt-3" >Register</button>


                </div>
            </div>
        </div>
    </div>
  )
}

export default CreateAdminProfile
