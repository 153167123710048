import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import ForgetPassword1 from './ForgetPassword1';



const AdminLoginForm = () => {

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <div className='Log_In_ADMIN_Form'>
                <div className='container'>
                    <div className='row'>

                        <div className=" col-lg-12 col-md-12">
                            <div className="form-group hire_group">
                                <label>Employee Id:</label>
                                <label
                                    className="input mt-1 mb-2"
                                    style={{
                                        border: "1px solid #270d44",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        className="input__field form_control form_hire"
                                        type="text"
                                        style={{ borderRadius: "5px" }}
                                        name="employeeid"
                                        placeholder=" "
                                    />
                                    <span className="input__label">Enter Your Admin id</span>
                                </label>
                            </div>
                        </div>

                        <div className=" col-lg-12 col-md-12">
                            <div className="form-group hire_group">
                                <label>Mail:</label>
                                <label
                                    className="input mt-1 mb-2"
                                    style={{
                                        border: "1px solid #270d44",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        className="input__field form_control form_hire"
                                        type="text"
                                        style={{ borderRadius: "5px" }}
                                        name="Mail"
                                        placeholder=" "
                                    />
                                    <span className="input__label">Enter Mail</span>
                                </label>
                            </div>
                        </div>



                        <div className=" col-lg-12 col-md-12">
                            <div className="form-group hire_group">
                                <label>Password:</label>
                                <label
                                    className="input mt-1 mb-2"
                                    style={{
                                        border: "1px solid #270d44",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        className="input__field form_control form_hire"
                                        type="text"
                                        style={{ borderRadius: "5px" }}
                                        name="password"
                                        placeholder=" "
                                    />
                                    <span className="input__label">Enter Your Password</span>
                                </label>
                            </div>
                        </div>




                        <a href='#' className="Admin_forget_login" onClick={toggleModal}>Forget Password</a>
                        <Link to="/viewadminprofile" > <button className="jobfair_btn jobfair_regbtn mt-3">Log In</button></Link>


                    </div>
                </div>
            </div>


            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                // style={{marginTop:"150px"}}
                className="Dashboard_Admin_login_form_modal"
            >

                <div className='position-relative'>
                    <ForgetPassword1/>
                    <button className='admin_logIn_modal_close' onClick={toggleModal}>x</button>
                </div>

            </Modal>
        </div>
    )
}

export default AdminLoginForm
