import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import skillsdata from "../../JsonData/Skill.json"
import data from "../../JsonData/Designation.json"
const useStyles = makeStyles({
  autocompleteInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Remove the border
    },
  },
});
const SearchFilter = ()=>{

    const classes = useStyles();
    // const [keyword, setKeyword] = useState("");
    // const [qlocation, setQLocation] = useState("");
    const [post, setPost] = useState({
        skillsets: [],
        cities: [],
      });
      const handleSearch1 = () => {
        if (post.cities.length > 0 || post.skillsets.length > 0) {
          navigate(
            `/browsefilterlist?keyword=${post.skillsets}&qlocation=${post.cities}`
          );
        }
      };


    const [keyword, setKeyword] = useState("");
    const [qlocation, setQLocation] = useState("");
    const [keywordError, setKeywordError] = useState("");
    const [locationError, setLocationError] = useState("");
    const navigate = useNavigate();


    const handleSearch = () => {
        let haveError = false
        if (keyword == '') {
            console.log('eee');
            haveError = true
            setKeywordError('Keyword is required!')
        }
        if (qlocation == '') {
            haveError = true
            setLocationError('Location is required!')
        }
        if (!haveError) {
            navigate(`/browsefilterlist?keyword=${keyword}&qlocation=${qlocation}`)
            // toast.error('All fields are required');
        }
        
    }
    console.log('keywordError', keywordError);
return(<>
<div className="home1">
                    {/* <div className="browse_joblocation_list-search_box">
                    <form className="form-control">
                        <div className="row">
                            <div className="col-lg-5 col-md-5" id="input1_joblocation">
                                <input type="text" className="form-control" id="search_box_input_joblocation"
                                    placeholder="Job Title, Keywords, or Phrase" name='keyword' value={keyword} onChange={(e) => {setKeyword(e.target.value); setKeywordError("")}} />
                                {keywordError != '' && <small style={{color: 'red'}}>{keywordError}</small>}
                            </div>
                            <div className="col-lg-5 col-md-5" id="input2_joblocation">
                                <input type="text" className="form-control" id="search_box_input_joblocation"
                                    placeholder="City ,Province or Region" name='qlocation' value={qlocation} onChange={(e) => {setQLocation(e.target.value); setLocationError("")}}  />
                                {locationError != '' && <small style={{color: 'red'}}>{locationError}</small>}
                            </div>
                            <div className="col-lg-2 col-md-2 col-xs-offset-3 col-xs-6 c0l-xs-offset-3"
                                id="input_btn_joblocation">
                                    {/* <Link to={`/browsefilterlist?keyword=${keyword}&qlocation=${qlocation}`} > *
                                    <a>
                                    <button type='button' id="search_box_btn_joblocation" className="btn-block" onClick={handleSearch} >
                                    Search</button>
                                    </a>
                                    {/* </Link> *
                            </div>

                        </div>
                    </form>
                </div> */}
                   <section className="home_bannerr1">
        <div className="container" style={{marginTop:"100px"}}>
          <div className="browse_joblocation_list-search_box">
            <form className="form-control home_form">
              <div className="row">
                {/* <h1 className="home_head">Find your dream job here</h1>
                <p className="home_head_c">5 lakh+ jobs for your explore</p> */}
                <div className="col-lg-6 col-md-5" id="input1_joblocation">
                  {/* <input type="text" className="form-control" id="search_box_input_joblocation"
                                                placeholder="Job Title, Keywords, or Phrase" name='keyword' value={keyword} />onChange={(e) => { setKeyword(e.target.value); setKeywordError("") }} />
                                            {keywordError != '' && <small style={{ color: 'red' }}>{keywordError}</small>} */}

                  <div>
                    {/* <label>Technical Skills :</label> */}
                    
                    <Autocomplete
                      classes={{ inputRoot: classes.autocompleteInput }}
                      id="combo-box-demo"
                      multiple
                      value={post.skillsets}
                      options={skillsdata.map((res) => {
                        return res.Skill;
                      })}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setPost({
                          ...post,
                          skillsets: value,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="multiple"
                          placeholder="Job Title, Keywords, or Phrase"
                          variant="outlined"
                          fullWidth
                          name="keyword"
                          value={keyword}
                          onChange={(e) => {
                            setKeyword(e.target.value);
                          }}
                        />
                      )}
                    />
                    {/* <span>Press enter to add skills</span> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-5" id="input2_joblocation">
                  {/* <input type="text" className="form-control" id="search_box_input_joblocation"
                                                placeholder="City ,Province or Region" name='qlocation' value={qlocation} onChange={(e) => { setQLocation(e.target.value); setLocationError("") }} />
                                            {locationError != '' && <small style={{ color: 'red' }}>{locationError}</small>} */}

                  {/* <div className="form-group"> */}
                  {/* <label>Locations</label> */}
                  <Autocomplete
                    classes={{ inputRoot: classes.autocompleteInput }}
                    id="combo-box-demo"
                    multiple
                    value={post.cities}
                    options={data.map((res) => {
                      return res.location;
                    })}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {
                      setPost({
                        ...post,
                        cities: value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="multiple"
                        placeholder="City, Province or Region"
                        variant="outlined"
                        fullWidth
                        name="qlocation"
                        value={qlocation}
                        onChange={(e) => {
                          setQLocation(e.target.value);
                        }}
                      />
                    )}
                  />

                  {/* <span>Press enter to add cities</span> */}
                  {/* </div> */}
                </div>
                <div className="row m-auto w-auto">
                  <div
                    className=" col-xs-offset-3 col-xs-6 c0l-xs-offset-3"
                    id="input_btn_joblocation"
                  >
                    <a>
                      <button
                        type="button"
                        id="search_box_btn_joblocation"
                        className="btn-block"
                        onClick={handleSearch1}
                      >
                        Search
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
                </div>
</>)

}
export default SearchFilter