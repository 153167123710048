import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import apiList, { server } from "../../lib/apiList";
import ReactPaginate from "react-paginate";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import moment from "moment";
// import {  useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import TopCompaniesFilter from "./filters/TopCompaniesFilter";
import ExperienceFilter from "./filters/ExperienceFilter";

import LocationFilter from "./filters/LocationFilter";

import IndustryFilter from "./filters/IndustryFilter";
import JobCategoryFilter from "./filters/DesignationFilter";
import EducationFilter from "./filters/EducationFilter";
import SalaryFilter from "./filters/SalaryFilter";
import ReactTimeAgo from "react-time-ago";
import DesignationFilter from "./filters/DesignationFilter";

import { useSelector, useDispatch } from "react-redux";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
// import skillsdata from '../JsonData/Skill.json';
import skillsdata from "../../JsonData/Skill.json";
import data from "../../JsonData/locations.json";
import Browsead from "../../ads/Browsead";
import Browsehrads from "../../ads/Browsehrads";
import Browseverads from "../../ads/Browseverads";
import Browsehomead from "../../ads/Browsehomead";
import SkillFilter from "./filters/SkillFilter";
import Chip from "@material-ui/core/Chip";
// import { SelectedCategories } from '../../redux/action';
import { useNavigate } from "react-router-dom";
import MobileAds from "../../ads/MobileAds";
import Jobdetailes from "../Jobdetailes";
import { RWebShare } from "react-web-share";

// import data from '../JsonData/locations.json'
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  autocompleteInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Remove the border
    },
  },
});

const BrowseFilterList = () => {
  const classes = useStyles();
  const isItFromMain = useSelector((state) => state.isItFromMain);
  const company = useSelector((state) => state.company);
  const newLocation = useSelector((state) => state.location);
  const newSkills = useSelector((state) => state.skill);
  const selectedCategories = useSelector((state) => state.selectedCategories);
  const selectedCompanies = useSelector((state) => state.selectedCompanies);
  const selectedLocations = useSelector((state) => state.selectedLocations);
  // const selectedLocations = useSelector(state => state.selectedLocations);
  const selectedSkills = useSelector((state) => state.selectedSkills);
  const [applyBtnText,setBtnText ] = useState("Apply Now");
  const selectedDesignations = useSelector(
    (state) => state.selectedDesignations
  );
  const newDesignation = useSelector((state) => state.designation);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [post, setPost] = useState({
    skillsets: [],
    cities: [],
  });

  const result = useSelector((state) => state.data);
  let { search } = useLocation();
  let locate = useLocation();

  const query = new URLSearchParams(search);
  console.log("search ", query.get("category"));

  let paramKeyword = "";
  let paramQLocation = "";
  let paramTopCompanies = [];
  let paramIndustryType = [];

  let paramLocation = [];

  let paramDesignation = [];
  let paramSkill = [];

  paramSkill = query.get("keyword") ? query.get("keyword")?.split(",") : [];
  paramLocation = query.get("qlocation")?.split(",")
    ? query.get("qlocation")?.split(",")
    : [];

  if (query.get("company")) {
    paramTopCompanies.push(query.get("company"));
  }

  if (query.get("category")) {
    paramIndustryType.push(query.get("category"));
  }

  if (query.get("locate")) {
    paramLocation.push(query.get("locate"));
  }

  if (query.get("skill")) {
    paramSkill.push(query.get("skill"));
  }

  if (query.get("designate")) {
    paramDesignation.push(query.get("designate"));
  }

  console.log({
    paramKeyword,
    paramQLocation,
  });
  const [keyword, setKeyword] = useState(paramKeyword);
  const [qlocation, setQLocation] = useState(paramQLocation);

  const [jobs, setJobs] = useState([]);
  console.log(jobs);
  const [selectedJob, setSeletedJob] = useState(jobs[0]);
  console.log(selectedJob);

  const [topCompanies, setTopCompanies] = useState(paramTopCompanies);
  const [experience, setExperience] = useState([]);

  const [location, setLocation] = useState(paramLocation);

  const [industryType, setIndustryType] = useState(paramIndustryType);
  const [education, setEducation] = useState([]);
  const [skill, setSkill] = useState(paramSkill);
  const [designation, setDesignation] = useState(paramDesignation);
  const [salary, setSalary] = useState();
  const [listType, setListType] = useState("list");
  const [isLoading, setLoading] = useState(false);
  const [onHold, setOnHold] = useState(false);
  const [keywordError, setKeywordError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [presentData, setPresentData] = useState([]);
  const [applications, setApplications] = useState([]);

  const list = [1, 2, 3, 4, 5, 6];

  // Pagination code
  const [offset, setOffset] = useState(1);
  //   const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [presentType, setPresentType] = useState("");
  const indexOfLastPost = offset * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentPosts = jobs.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
    // console.log("selectedPage", selectedPage);
    // fetchJobs(selectedPage);
    window.scrollTo({
      top: 450,
      behavior: "smooth",
    });
  };

  const handleTopCompaniesAdd = async (companies) => {
    setTopCompanies(companies);
    fetchJobs();
  };

  const handleTopCompaniesRemove = async (companies) => {
    setTopCompanies(companies);
    // fetchJobs();
  };

  const handleLocationAdd = async (locations) => {
    setLocation(locations);
    fetchJobs();
  };
  const handleLocationRemove = async (locations) => {
    setLocation(locations);
    // fetchJobs();
  };

  const handleIndustryTypeAdd = async (industry) => {
    setIndustryType(industry);
    fetchJobs();
  };

  const handleIndustryTypeRemove = async (industry) => {
    setIndustryType(industry);
    // fetchJobs();
  };

  const handleDesignationAdd = async (designate) => {
    setDesignation(designate);
    fetchJobs();
  };

  const handleDesignationRemove = async (designate) => {
    setDesignation(designate);
    // fetchJobs();
  };

  const handleEducationAdd = async (educations) => {
    setEducation(educations);
    fetchJobs();
  };

  const handleEducationRemove = async (educations) => {
    setEducation(educations);
    // fetchJobs();
    // resetFilter
  };

  const handleSkillAdd = async (educations) => {
    setSkill(educations);
    fetchJobs();
  };

  const handleSkillRemove = async (educations) => {
    setSkill(educations);
    // fetchJobs();
  };

  const resetFilter = () => {
    setTopCompanies([]);
    setExperience([]);
    setLocation([]);
    setEducation([]);

    setSkill([]);

    setSalary();
    setKeyword("");
    setQLocation("");
  };

  const handleAddWishlist = async (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    console.log("hhhh", headers);
    if (onHold) {
      return;
    }
    setOnHold(true);
    await axios
      .get(apiList.wishlist + "add/" + id, {
        headers,
      })
      .then((response) => {
        let jobList = jobs;
        jobList = jobList.map((jobItem) => {
          if (jobItem._id == id) {
            jobItem.wishlist = true;
          }
          return jobItem;
        });
        setJobs(jobList);
        toast.success("Added to Wishlist!");
        setOnHold(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      });
  };

  const handleRemoveWishlist = async (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    console.log("hhhh", headers);
    if (onHold) {
      return;
    }
    setOnHold(true);
    // if(isItFromMain){
    await axios
      .get(apiList.wishlist + "remove/" + id, {
        headers,
      })
      .then((response) => {
        let jobList = jobs;
        jobList = jobList.map((jobItem) => {
          if (jobItem._id == id) {
            jobItem.wishlist = false;
          }
          return jobItem;
        });
        setJobs(jobList);
        toast.success("Removed from Wishlist!");
        setOnHold(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      });
  };

  const fetchJobs = async (page = 0) => {
    console.log("====================================");
    console.log({ location, skill });
    console.log("====================================");
    setJobs([]);
    setLoading(true);
    console.log("topCompaniesssss", topCompanies);
    let token = localStorage.getItem("token");
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }
    let data = {
      location,
      experience,
      companies: topCompanies,
      educations: education,
      category: designation,
      industryType,
      skills: skill,
      // salaryMin: 0,
      // salaryMax: 18000
    };
    if (post.cities.length > 0) {
      data.location = post.cities;
    }
    if (post.skillsets.length > 0) {
      data.skills = post.skillsets;
    }
    //from here
    if (
      isItFromMain &&
      !company &&
      !newLocation &&
      !newSkills &&
      !newDesignation
    ) {
      setPresentData(selectedCategories);
      setPresentType("category");
      delete data.category;
      let rawData = [...selectedCategories];
      let result = rawData?.map(({ value }) => value);
      data.category = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / perPage);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.message);
        });
    } else if (company && !newLocation && !newSkills && !newDesignation) {
      setPresentData(selectedCompanies);
      setPresentType("company");
      delete data.companies;
      let rawData = [...selectedCompanies];
      let result = rawData?.map(({ key }) => key);
      data.companies = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / perPage);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.message);
        });
    } else if (!company && newLocation && !newSkills && !newDesignation) {
      setPresentData(selectedLocations);
      setPresentType("location");
      delete data.location;
      let rawData = [...selectedLocations];
      let result = rawData?.map(({ value }) => value);
      data.location = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / perPage);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data, "Yoooooo");
          toast.error(err.response.data.message);
        });
    } else if (!company && !newLocation && newSkills && !newDesignation) {
      setPresentType("skill");
      setPresentData(selectedSkills);
      delete data.skills;
      let rawData = [...selectedSkills];
      let result = rawData?.map(({ value }) => value);
      data.skills = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / perPage);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data, "Yoooooo");
          toast.error(err.response.data.message);
        });
    } else if (!company && !newLocation && !newSkills && newDesignation) {
      setPresentData(
        selectedDesignations.length > 0 ? selectedDesignations : []
      );
      setPresentType("designation");
      delete data.industryType;
      let rawData = [...selectedDesignations];
      let result = rawData?.map(({ value }) => value);
      data.industryType = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / perPage);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data, "Yoooooo");
          toast.error(err.response.data.message);
        });
    } else {
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / perPage);
          console.log("posts", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.message);
        });
    }
    // if (salary) {
    //   data.salaryMin = salary.salaryMin
    //   data.salaryMax = salary.salaryMax
    // }
    // if (keyword !== '') {
    //   data.q = keyword
    // }
    // if (qlocation !== '') {
    //   data.qlocation = qlocation
    // }
    //   if(!isItFromMain ){
    // if (!isItFromMain) {
    //   if (!company) {
    //     // alert(newLocation)
    //     if (!newLocation) {
    //       // alert("386")
    //       if (newDesignation) {

    //       } else {
    //         // alert("407")
    //         if (newSkills) {

    //         }
    //       }
    //     } else {
    //       // alert("430")
    //       if (!!newLocation) {

    //       } else {

    //       }

    //     }
    //   }
    //   else {
    //     // alert("395")

    //   }
    // }
    // else {
    //   // alert("421")
    //   //  else{

    // }
    // }

    // }
  };

  //fetching total applications
  const fetchApplications = async () => {
    axios
      .get(apiList.applications, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setApplications(response.data);
      })
      .catch((err) => {
        // history.push("/login")
        // console.log(err.response);
        console.log(err.response.data);
      });
  };

  const handleSearch = (e) => {
    let haveError = false;
    if (keyword == "") {
      haveError = true;
      setKeywordError("Keyword is required!");
    }
    if (qlocation == "") {
      haveError = true;
      setLocationError("Location is required!");
    }
    if (haveError) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    fetchApplications();
    fetchJobs();
 

    return () => {
      // console.log('unmounting')
      dispatch({ type: "NEW_CLEAR" });
      dispatch({ type: "FROM_MAIN_COMPANY_CLEAR" });
      // FROM_MAIN_DES_CLEAR
      // FROM_MAIN_LOCATION_CLEAR
      dispatch({ type: "FROM_MAIN_LOCATION_CLEAR" });
      dispatch({ type: "FROM_MAIN_DES_CLEAR" });
      dispatch({ type: "FROM_MAIN_SKILL_CLEAR" });
    };
  }, [topCompanies, experience, location, education, salary, industryType]);

  console.log("kkkkkkkk", keyword, qlocation);
  const handleDelete = (chipToDelete) => {
    //based on conditions delete the chip
    // console.log('chipToDelete', chipToDelete);
    // alert()
    let newData = [...presentData];
    let index = newData.findIndex((item) => item.value === chipToDelete.value);
    newData.splice(index, 1);
    setPresentData(newData);
    if (presentType === "category") {
      dispatch({
        type: "SELECTED_CATEGORIES",
        payload: chipToDelete.value,
      });
    } else if (presentType === "company") {
      // alert("company")
      // let newData = [...presentData];
      // let index = newData.findIndex(item => item.value === chipToDelete.value);
      // newData.splice(index, 1);
      // setPresentData(newData);
      dispatch({
        type: "SELECTED_COMPANIES",
        payload: chipToDelete,
      });
    } else if (presentType === "location") {
      // alert("loca")
      dispatch({
        type: "SELECTED_LOCATIONS",
        payload: chipToDelete.value,
      });
    } else if (presentType === "skill") {
      // alert("skills")
      dispatch({
        type: "SELECTED_SKILLS",
        payload: chipToDelete.value,
      });
    } else if (presentType === "designation") {
      // alert("des")
      dispatch({
        type: "SELECTED_DESIGNATIONS",
        payload: chipToDelete.value,
      });
    }
  };

  const handleCardClick = (job) => {
    setSeletedJob(job);
  };

  const handleApply = (e, job) => {
    e.preventDefault();
    axios
      .post(
        `${apiList.jobs}/${job._id}/applications`,
        {
          sop: "ksajdfk",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);

        //setApply(response.data.status)
        // navigate('/appliedjobs')

        toast.success(response.data.message);
        setBtnText("Applied")
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(err.response.data.message);
      });
    //fetching applications
    fetchApplications();
    
  };

  console.log("applications", applications);
  console.log("total_jobs", jobs);

  //finding status of job

  const getStatusForJobId = (jobId) => {
    const application = applications.find((app) => app.jobId === jobId);
    return application ? application.status : applyBtnText;
  };

  return (
    <div>
      <div className="job_detail_wrapper home_bannerr2">
        <div className="heading_pic_filter_list">
          {/* <h1 className="filter_list_heading_1 ">Browse Job List</h1> */}
          <p className="text-center filter_list_sub_heading pb-5">
            <Link to="/" className="filter_list_sub_heading_1">
              Home
            </Link>
            <i class="fas fa-greater-than text-black px-2"></i>
            <a href="#" className="filter_list_sub_heading_2 ">
              Browse Filter List
            </a>
          </p>
          <div className="container ">
            <div className="filter_list_search-box">
              <form
                className="form-control_browse py-3"
                onSubmit={handleSearch}
                style={{ boxShadow: "0 0 8px rgb(0 0 0 / 25%);" }}
              >
                <div className="row  justify-content-center align-items-center">
                  <div className="col-lg-6 col-md-6 my-2">
                    <div className="">
                      <div className="">
                        {/* <input type="text" name="keyword" className="form-control" id="search_filter_list_input"
                          placeholder="Job Title, Keywords, or Phrase" value={keyword} onChange={(e) => {setKeyword(e.target.value); setKeywordError("")}}  /> */}

                        <div>
                          {/* <label>Technical Skills :</label> */}

                          <Autocomplete
                            classes={{ inputRoot: classes.autocompleteInput }}
                            id="combo-box-demo"
                            multiple
                            value={post.skillsets}
                            options={skillsdata.map((res) => {
                              return res.Skill;
                            })}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                              setPost({
                                ...post,
                                skillsets: value,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="multiple"
                                placeholder="Job Title, Keywords, or Phrase"
                                variant="outlined"
                                fullWidth
                                // name='keyword'
                                value={keyword}
                                onChange={(e) => {
                                  setKeyword(e.target.value);
                                  setKeywordError("");
                                }}
                              />
                            )}
                          />

                          {/* <span>Press enter to add skills</span> */}
                        </div>

                        {/* <div className="input-group-append">
                          <span className="filter_list_group_icon">
                            <i className="fas fa-search ml-2" id="filter_list_search_icon1"></i>
                          </span>
                        </div> */}
                      </div>
                      {/* {keywordError != '' && <small style={{color: 'red'}}>{keywordError}</small>} */}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 my-2">
                    {/* <input type="text" className="form-control" name="qlocation" id="search_filter_list_input" value={qlocation} onChange={(e) => {setQLocation(e.target.value); setLocationError("")}}
                          placeholder="Location"  /> */}

                    <Autocomplete
                      classes={{ inputRoot: classes.autocompleteInput }}
                      id="combo-box-demo"
                      multiple
                      value={post.cities}
                      options={data.map((res) => {
                        return res.location;
                      })}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setPost({
                          ...post,
                          cities: value,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="multiple"
                          placeholder="City ,Province or Region"
                          variant="outlined"
                          fullWidth
                          // name='qlocation'
                          value={qlocation}
                          onChange={(e) => {
                            setQLocation(e.target.value);
                          }}
                        />
                      )}
                    />

                    {/* <div className="input-group-append">
                          <span className="filter_list_group_icon">
                            <i className="fas fa-map-marker-alt" id="filter_list_search_icon2"></i></span>
                        </div> */}

                    {/* {locationError != '' && <small style={{color: 'red'}}>{locationError}</small>} */}
                  </div>

                  <div
                    className=" col-xs-offset-3 col-xs-6 c0l-xs-offset-3 text-center"
                    id="input_btn_joblocation"
                  >
                    <a>
                      <button
                        type="button"
                        id="search_box_btn_joblocation"
                        className="btn-block"
                        onClick={fetchJobs}
                      >
                        Search
                      </button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="job_filter_list">
        <div className="container BFL">
          {/* <div className="d-flex mb-4" style={{width:'74.5%'}}>  
            <div className="view_list_grid ml-auto" >
              <button
                className={`btn list_view mb-2 ${listType === 'list' && 'browse_active'}`} onClick={() => setListType('list')}>List View</button>
              <button
                className={`btn list_view mb-2 ${listType === 'grid' && 'browse_active'}`} onClick={() => setListType('grid')}>Grid View</button>
              
            </div>
          </div> */}
          {/* 
          <div className="#"> */}
          <div id="accorrdion">
            <div className="grid-container">
              <div className="grid-content">
                <TopCompaniesFilter
                  topCompanies={topCompanies}
                  handleTopCompaniesAdd={handleTopCompaniesAdd}
                  handleTopCompaniesRemove={handleTopCompaniesRemove}
                />
              </div>
              <div className="grid-content">
                <ExperienceFilter
                  experience={experience}
                  setExperience={setExperience}
                />
              </div>
              <div className="grid-content">
                <LocationFilter
                  location={location}
                  handleLocationAdd={handleLocationAdd}
                  handleLocationRemove={handleLocationRemove}
                />
              </div>
              <div className="grid-content">
                <IndustryFilter
                  industryType={industryType}
                  handleIndustryTypeAdd={handleIndustryTypeAdd}
                  handleIndustryTypeRemove={handleIndustryTypeRemove}
                />
              </div>
              <div className="grid-content">
                <DesignationFilter
                  designation={designation}
                  handleDesignationAdd={handleDesignationAdd}
                  handleDesignationRemove={handleDesignationRemove}
                />
              </div>
              <div className="grid-content">
                <EducationFilter
                  education={education}
                  handleEducationAdd={handleEducationAdd}
                  handleEducationRemove={handleEducationRemove}
                />
              </div>
              <div className="grid-content">
                <SalaryFilter salary={salary} setSalary={setSalary} />
              </div>
              <div className="grid-content">
                <SkillFilter
                  skill={skill}
                  handleSkillAdd={handleSkillAdd}
                  handleSkillRemove={handleSkillRemove}
                />
              </div>
              {/* </div>*/}
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-4">
              <div className="#" style={{ display: "none" }}>
                <div id="accordion">
                  <TopCompaniesFilter
                    topCompanies={topCompanies}
                    handleTopCompaniesAdd={handleTopCompaniesAdd}
                    handleTopCompaniesRemove={handleTopCompaniesRemove}
                  />
                  <ExperienceFilter
                    experience={experience}
                    setExperience={setExperience}
                  />
                  <LocationFilter
                    location={location}
                    handleLocationAdd={handleLocationAdd}
                    handleLocationRemove={handleLocationRemove}
                  />
                  <IndustryFilter
                    industryType={industryType}
                    handleIndustryTypeAdd={handleIndustryTypeAdd}
                    handleIndustryTypeRemove={handleIndustryTypeRemove}
                  />
                  <DesignationFilter
                    designation={designation}
                    handleDesignationAdd={handleDesignationAdd}
                    handleDesignationRemove={handleDesignationRemove}
                  />
                  <EducationFilter
                    education={education}
                    handleEducationAdd={handleEducationAdd}
                    handleEducationRemove={handleEducationRemove}
                  />
                  <SalaryFilter salary={salary} setSalary={setSalary} />
                  <SkillFilter
                    skill={skill}
                    handleSkillAdd={handleSkillAdd}
                    handleSkillRemove={handleSkillRemove}
                  />
                </div>
              </div>

              {/* <div className='google_ads'>
                <Browsead />

                <Browseverads />

                <Browsead />

                <Browsehrads />

                <Browsead />

                <Browseverads />

                <Browsead />

                <Browsehrads />


                <Browseverads />

              </div> */}

              {/* <div className="card p-3">
                <div className="row">
                  <div className="col-8">
                    <h1 className="browse_filter_designation">
                      Graphic Designer
                    </h1>
                    <p className="browse_filter_company">Angle Broking</p>
                  </div>
                  <div className="col-4">
                    <h1 className="browse_filter_package">4 - 6 LPA</h1>
                  </div>
                </div>
                <div className="row browse_filter_details">
                  <p>Full Time</p>
                  <p>Hyderabad / Secunderabad, Telangana</p>
                  <p>Fresher</p>
                </div>
              </div> */}
            </div>

            <div className="list_view_width col-lg-12">
              {presentData && presentData?.length > 0 ? (
                <>
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-evenly",
                      marginTop: "20px",
                    }}
                  >
                    {presentData &&
                      presentData?.map((item, index) => (
                        <Chip
                          key={index}
                          color="primary"
                          label={item?.value}
                          onDelete={() => handleDelete(item)}
                        ></Chip>
                      ))}
                  </div>
                  {presentData.length > 0 && (
                    <center>
                      <button
                        class="btn btn-primary mt-4"
                        onClick={() => {
                          //  alert("onclick")
                          // await dispatch({ type: "FROM_MAIN_LOCATION" });
                          // navigate("/browsefilterlist");
                          fetchJobs();
                        }}
                      >
                        Filter Selected &nbsp; <i class="fas fa-search"></i>
                      </button>
                    </center>
                  )}
                </>
              ) : null}

              {/* new col-4 and col-8 updated */}
              {listType === "list" ? (
                <>
                  {currentPosts.length > 0 ? (
                    <>
                      <div className="container browse_none">
                        <div className="row">
                          <p className="showing-results">
                            Showing {jobs.length} results 
                          </p>

                          <div className="col-md-5 mt-1">
                            {currentPosts.map((job) => (
                              <div
                                key={job.id}
                                onClick={() => handleCardClick(job)}
                                className={`card br_side_cards mb-3 ${
                                  selectedJob === job ? "active_col_md" : " "
                                }`}
                              >
                                <div style={{ border: "none" }}>
                                  <div className="row b_side_card_line">
                                    <div className="col-md-10 col-sm-10">
                                      <h1 className="browse_filter_designation">
                                        {job.title}
                                      </h1>
                                      <p className="browse_filter_company">
                                        {/* {job.recruiter?.companyname} */}
                                        {job.recruiter?.companyname
                                          .charAt(0)
                                          .toUpperCase() +
                                          job.recruiter?.companyname
                                            .slice(1)
                                            .toLowerCase()}
                                      </p>
                                    </div>
                                    <div className="col-md-2 col-sm-2">
                                      {result?.type === "applicant" ? (
                                        <>
                                          {job.wishlist ? (
                                            <label
                                              className="job_filter_grid_wishlist"
                                              onClick={() =>
                                                handleRemoveWishlist(job._id)
                                              }
                                            >
                                              <span className="filter_grid_added">
                                                {/* <i
                                                  class="fas fa-thin fa-bookmark book_mark_icon"
                                                  style={{
                                                    fontSize: "28px",
                                                    color: "green",
                                                  }}
                                                /> */}
                                                <i
                                                  class="ri-bookmark-fill"
                                                  style={{ fontSize: "17px" }}
                                                ></i>
                                              </span>
                                            </label>
                                          ) : (
                                            <label
                                              className="job_filter_grid_wishlist"
                                              onClick={() =>
                                                handleAddWishlist(job._id)
                                              }
                                            >
                                              <span className="filter_grid_added">
                                                <i
                                                  class="ri-bookmark-line"
                                                  style={{ fontSize: "17px" }}
                                                ></i>
                                              </span>
                                            </label>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="row browse_filter_details">
                                    <p>
                                      <i
                                        class="ri-time-line"
                                        style={{
                                          color: "#646060",
                                          paddingRight: "5px",
                                          fontSize: "14px",
                                        }}
                                      ></i>
                                      <span
                                        style={{
                                          color: "#6e6565",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {job.jobType}
                                      </span>
                                    </p>
                                    <p className="">
                                      <i
                                        class="ri-bank-card-line"
                                        style={{
                                          color: "#646060",
                                          paddingRight: "5px",
                                          fontSize: "14px",
                                        }}
                                      ></i>
                                      <span
                                        style={{
                                          color: "#6e6565",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {job.salary}
                                      </span>
                                    </p>
                                    <p>
                                      <i
                                        class="ri-map-pin-line"
                                        style={{
                                          color: "#646060",
                                          paddingRight: "5px",
                                          fontSize: "14px",
                                        }}
                                      ></i>
                                      {job.cities.map((job, index, arr) => {
                                        return (
                                          <>
                                            {job}
                                            {index != arr.length - 1 ? "/" : ""}
                                          </>
                                        );
                                      })}
                                    </p>
                                    <p
                                      style={{
                                        borderBottom: "1px solid #e1e1e15e",
                                      }}
                                    >
                                      <i
                                        class="ri-user-settings-line"
                                        style={{
                                          color: "#646060",
                                          paddingRight: "5px",
                                          fontSize: "14px",
                                        }}
                                      ></i>
                                      {job.skillsets.map((each) => (
                                        <span className="b_side_card_skills">
                                          {" "}
                                          {each}
                                        </span>
                                      ))}
                                    </p>
                                  </div>
                                  <div className="row text-end">
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "left",
                                        fontFamily: "system-ui",
                                      }}
                                      className="pt-2"
                                    >
                                      {" "}
                                      <i
                                        class="ri-history-line"
                                        style={{ fontSize: "14px" }}
                                      ></i>{" "}
                                      Published{" "}
                                      {moment(
                                        job.postedAt
                                          ? Number(job.postedAt)
                                          : job.dateOfPosting
                                      ).fromNow()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="card sticky-top  col-md-7 brow_side_card">
                            {selectedJob ? (
                              <div>
                              {/* top card */}

                              <div className=" d-flex pt-3">
                                <div className="">
                                  <img
                                    src={
                                      selectedJob.recruiter?.profileImage
                                        ? selectedJob.recruiter.profileImage
                                        : "images/company icon-01.png"
                                    }
                                    alt=""
                                    style={{ width: "70px", height: "70px" }}
                                  />
                                </div>

                                <div className="bro_t_cname">
                                  <div>
                                    <h4>{selectedJob.title}</h4>
                                    <h5>
                                      {/* {selectedJob.recruiter?.companyname} */}
                                      {selectedJob.recruiter?.companyname
                                        .charAt(0)
                                        .toUpperCase() +
                                        selectedJob.recruiter?.companyname
                                          .slice(1)
                                          .toLowerCase()}
                                    </h5>
                                  </div>
                                </div>

                                <div className="m-auto">
                                  {result?.type === "applicant" ? (
                                    <button
                                      className="apply_now_btn"
                                      disabled={result?.type === "recruiter"}
                                      onClick={(e) =>
                                        handleApply(e, selectedJob)
                                      }
                                    >
                                      {getStatusForJobId(selectedJob._id)}
                                    </button>
                                  ) : (
                                    <Link to="/auth">
                                      <button className="btn btn-primary">
                                        Login to Apply
                                      </button>
                                    </Link>
                                  )}
                                </div>

                                <div className="package-card d-flex m-auto">
                                  {/* Share Button */}
                                  <a
                                    className="float-right share_icon"
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <RWebShare
                                      data={{
                                        text: selectedJob.title,
                                        url: `https://pabjobs.com/jobdetailes/${selectedJob._id}`,
                                        title: "Pab Jobs",
                                      }}
                                      onClick={() =>
                                        console.log("shared successfully!")
                                      }
                                    >
                                      <i class="fa fa-share-alt"></i>
                                    </RWebShare>
                                  </a>
                                  {/* Share Button */}

                                  {/* {result?.type === "applicant" ? (
                                    <>
                                      {selectedJob.wishlist ? (
                                        <label
                                          className="job_filter_grid_wishlist"
                                          onClick={() =>
                                            handleRemoveWishlist(selectedJob._id)
                                          }

                                        >
                                          <span className="filter_grid_added">
                                            <i
                                              class="fas fa-thin fa-bookmark book_mark_icon"
                                              style={{
                                                fontSize: "28px",
                                                color: "green",
                                              }}
                                            />
                                          </span>
                                        </label>
                                      ) : (
                                        <label
                                          className="job_filter_grid_wishlist"
                                          onClick={() =>
                                            handleAddWishlist(selectedJob._id)
                                          }

                                        >
                                          <span className="filter_grid_added">
                                            <i className="fas fa-thin fa-bookmark book_mark_icon" />
                                          </span>
                                        </label>
                                      )}
                                    </>
                                  ) : null} */}
                                </div>
                              </div>

                              <hr className="hr_line" />
                              <div className="d-flex card p-3">
                                <div className="">
                                  <p className="mb-0">
                                    <i
                                      class="ri-map-pin-line"
                                      style={{
                                        color: "#646060",
                                        paddingRight: "5px",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                    {selectedJob.cities.map(
                                      (jobss, index, arr) => {
                                        return (
                                          <>
                                            {jobss}
                                            {index != arr.length - 1
                                              ? "/"
                                              : ""}
                                          </>
                                        );
                                      }
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    <i
                                      class="ri-timer-flash-line"
                                      style={{
                                        color: "#646060",
                                        paddingRight: "5px",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                    <span
                                      style={{
                                        color: "#000",
                                        fontSize: "13px",
                                        borderRight: "1px solid #e1e1e1",
                                        paddingRight: "10px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {selectedJob.experience}
                                    </span>

                                    <i
                                      class="ri-bank-card-line"
                                      style={{
                                        color: "#646060",
                                        paddingRight: "5px",
                                        fontSize: "14px",
                                        paddingLeft: "10px",
                                      }}
                                    ></i>
                                    <span
                                      style={{
                                        color: "#000",
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {selectedJob.salary}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "left",
                                      fontFamily: "system-ui",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      class="ri-history-line"
                                      style={{ fontSize: "14px" }}
                                    ></i>{" "}
                                    Published{" "}
                                    {moment(
                                      selectedJob.postedAt
                                        ? Number(selectedJob.postedAt)
                                        : selectedJob.dateOfPosting
                                    ).fromNow()}
                                  </p>
                                </div>
                              </div>

                              <div className="filter_list_job_box filter_list_main">
                                <h2 className="job_description">
                                  Job Description
                                </h2>
                                <p className="roles">
                                  {" "}
                                  {selectedJob.description}{" "}
                                </p>

                                {/* <p className="job_roles_details">
                                  Roles and Responsibilties{" "}
                                </p> */}

                                <h2 className="job_description mt-3">
                                Skils & Responsibilties :
                                </h2>
                                <ul className="job_roles_details">
                                  
                                  {selectedJob.skillsets.map((each) => (
                                    <span className="b_side_card_skills">{each}</span>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            ) : (
                              <>
                                <div>
                              {/* top card */}

                              <div className=" d-flex pt-3">
                                <div className="">
                                  <img
                                    src={
                                      currentPosts[0].recruiter?.profileImage
                                        ? currentPosts[0].recruiter.profileImage
                                        : "images/company icon-01.png"
                                    }
                                    alt=""
                                    style={{ width: "70px", height: "70px" }}
                                  />
                                </div>

                                <div className="bro_t_cname">
                                  <div>
                                    <h4>{currentPosts[0].title}</h4>
                                    <h5>
                                      {/* {selectedJob.recruiter?.companyname} */}
                                      {currentPosts[0].recruiter?.companyname
                                        .charAt(0)
                                        .toUpperCase() +
                                        currentPosts[0].recruiter?.companyname
                                          .slice(1)
                                          .toLowerCase()}
                                    </h5>
                                  </div>
                                </div>

                                <div className="m-auto">
                                  {result?.type === "applicant" ? (
                                    <button
                                      className="apply_now_btn"
                                      disabled={result?.type === "recruiter"}
                                      onClick={(e) =>
                                        handleApply(e, currentPosts[0])
                                      }
                                    >
                                      {getStatusForJobId(currentPosts[0]._id)}
                                    </button>
                                  ) : (
                                    <Link to="/auth">
                                      <button className="btn apply_now_btn">
                                        Login to Apply
                                      </button>
                                    </Link>
                                  )}
                                </div>

                                <div className="package-card d-flex m-auto">
                                  {/* Share Button */}
                                  <a
                                    className="float-right share_icon"
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <RWebShare
                                      data={{
                                        text: currentPosts[0].title,
                                        url: `https://pabjobs.com/jobdetailes/${currentPosts[0]._id}`,
                                        title: "Pab Jobs",
                                      }}
                                      onClick={() =>
                                        console.log("shared successfully!")
                                      }
                                    >
                                      <i class="fa fa-share-alt"></i>
                                    </RWebShare>
                                  </a>
                                  {/* Share Button */}

                                  {/* {result?.type === "applicant" ? (
                                    <>
                                      {selectedJob.wishlist ? (
                                        <label
                                          className="job_filter_grid_wishlist"
                                          onClick={() =>
                                            handleRemoveWishlist(selectedJob._id)
                                          }

                                        >
                                          <span className="filter_grid_added">
                                            <i
                                              class="fas fa-thin fa-bookmark book_mark_icon"
                                              style={{
                                                fontSize: "28px",
                                                color: "green",
                                              }}
                                            />
                                          </span>
                                        </label>
                                      ) : (
                                        <label
                                          className="job_filter_grid_wishlist"
                                          onClick={() =>
                                            handleAddWishlist(selectedJob._id)
                                          }

                                        >
                                          <span className="filter_grid_added">
                                            <i className="fas fa-thin fa-bookmark book_mark_icon" />
                                          </span>
                                        </label>
                                      )}
                                    </>
                                  ) : null} */}
                                </div>
                              </div>

                              <hr className="hr_line" />
                              <div className="d-flex card p-3">
                                <div className="">
                                  <p className="mb-0">
                                    <i
                                      class="ri-map-pin-line"
                                      style={{
                                        color: "#646060",
                                        paddingRight: "5px",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                    {currentPosts[0].cities.map(
                                      (jobsss, index, arr) => {
                                        return (
                                          <>
                                            {jobsss}
                                            {index != arr.length - 1
                                              ? "/"
                                              : ""}
                                          </>
                                        );
                                      }
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    <i
                                      class="ri-timer-flash-line"
                                      style={{
                                        color: "#646060",
                                        paddingRight: "5px",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                    <span
                                      style={{
                                        color: "#000",
                                        fontSize: "13px",
                                        borderRight: "1px solid #e1e1e1",
                                        paddingRight: "10px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {currentPosts[0].experience}
                                    </span>

                                    <i
                                      class="ri-bank-card-line"
                                      style={{
                                        color: "#646060",
                                        paddingRight: "5px",
                                        fontSize: "14px",
                                        paddingLeft: "10px",
                                      }}
                                    ></i>
                                    <span
                                      style={{
                                        color: "#000",
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {currentPosts[0].salary}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "left",
                                      fontFamily: "system-ui",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      class="ri-history-line"
                                      style={{ fontSize: "14px" }}
                                    ></i>{" "}
                                    Published{" "}
                                    {moment(
                                      currentPosts[0].postedAt
                                        ? Number(currentPosts[0].postedAt)
                                        : currentPosts[0].dateOfPosting
                                    ).fromNow()}
                                  </p>
                                </div>
                              </div>

                              <div className="filter_list_job_box filter_list_main">
                                <h2 className="job_description">
                                  Job Description
                                </h2>
                                <p className="roles">
                                  {" "}
                                  {currentPosts[0].description}{" "}
                                </p>

                                {/* <p className="job_roles_details">
                                  Roles and Responsibilties{" "}
                                </p> */}

                                <h2 className="job_description mt-3">
                                Skils & Responsibilties :
                                </h2>
                                <ul className="job_roles_details">
                                  
                                  {currentPosts[0].skillsets.map((each) => (
                                    <span className="b_side_card_skills">{each}</span>
                                  ))}
                                </ul>
                              </div>
                            </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="container browse_none1">
                        <div className="row">
                          <p className="showing-results">
                            Showing {jobs.length} results for "{keyword}"
                          </p>

                          <div className="col-md-4 mt-1">
                            {currentPosts.map((job) => (
                              <>
                                <Link to={`/jobdetailes/${job._id}`}>
                                  <div
                                    key={job.id}
                                    onClick={() => handleCardClick(job)}
                                    className={`card p-3 mb-3 ${
                                      selectedJob === job
                                        ? "active_col_md"
                                        : " "
                                    }`}
                                  >
                                    <div style={{ border: "none" }}>
                                      <div className="row">
                                        <div className="col-md-10 col-sm-10">
                                          <h1 className="browse_filter_designation">
                                            {job.title}
                                          </h1>
                                          <p className="browse_filter_company">
                                            {job.recruiter?.companyname}
                                          </p>
                                        </div>
                                        <div className="col-md-2 col-sm-2"></div>
                                      </div>

                                      <div className="row browse_filter_details">
                                        <p>{job.jobType}</p>
                                        <p className="browse_filter_package">
                                          {job.salary}
                                        </p>
                                        <p>
                                          {" "}
                                          {job.cities.map((job, index, arr) => {
                                            return (
                                              <>
                                                {job}
                                                {index != arr.length - 1
                                                  ? "/"
                                                  : ""}
                                              </>
                                            );
                                          })}
                                        </p>
                                        <p>Experience</p>
                                      </div>
                                      <div className="row text-end">
                                        <p style={{ fontSize: "12px" }}>
                                          {" "}
                                          Published{" "}
                                          {moment(
                                            job.postedAt
                                              ? Number(job.postedAt)
                                              : job.dateOfPosting
                                          ).fromNow()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>

                                {result?.type === "applicant" ? (
                                  <>
                                    {job.wishlist ? (
                                      <label
                                        className="job_filter_grid_wishlist1"
                                        onClick={() =>
                                          handleRemoveWishlist(job._id)
                                        }
                                      >
                                        <span className="filter_grid_added">
                                          <i
                                            class="fas fa-thin fa-bookmark book_mark_icon"
                                            style={{
                                              color: "green",
                                            }}
                                          />
                                        </span>
                                      </label>
                                    ) : (
                                      <label
                                        className="job_filter_grid_wishlist1"
                                        onClick={() =>
                                          handleAddWishlist(job._id)
                                        }
                                      >
                                        <span className="filter_grid_added">
                                          <i className="fas fa-thin fa-bookmark book_mark_icon" />
                                        </span>
                                      </label>
                                    )}
                                  </>
                                ) : null}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {isLoading ? (
                        <div className="skeleton">
                          {list.map((item) => {
                            return (
                              <div className="contact__item mb-5" key={item}>
                                <ul className="job-post">
                                  <li>
                                    <SkeletonTheme
                                      color="#f3f3f3"
                                      highlightColor="#ecebeb"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        <Skeleton
                                          circle={false}
                                          height={50}
                                          width={50}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Skeleton
                                            height={12}
                                            width="30%"
                                            style={{
                                              marginLeft: "1rem",
                                              marginBottom: "0.5rem",
                                            }}
                                          />
                                          <Skeleton
                                            height={8}
                                            width="40%"
                                            style={{ marginLeft: "1rem" }}
                                          />
                                          <Skeleton
                                            height={8}
                                            width="50%"
                                            style={{
                                              marginLeft: "1rem",
                                              marginTop: 0,
                                            }}
                                          />
                                          <Skeleton
                                            height={12}
                                            width="80%"
                                            style={{
                                              marginLeft: "1rem",
                                              marginTop: "0.6rem",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </SkeletonTheme>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div style={{ textAlign: "-webkit-center" }}>
                          <div>
                            <img
                              src="images/NO JOBS FOUND-01-01.png"
                              className="no_jobs_found_img"
                            />
                          </div>
                          <p>
                            There is no jobs to list with the current filter
                          </p>
                          <button
                            className={`btn list_view mb-2 `}
                            onClick={resetFilter}
                          >
                            Reset Filter
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div class="row" id="job_filter_grid">
                  {jobs.length > 0 ? (
                    jobs?.map((job) => {
                      return (
                        <>
                          <div className="col-lg-6 col-sm-12">
                            <Link to={`/jobdetailes/${job._id}`}>
                              <div className="job_filter_grid_box position-relative">
                                <div className="d-flex mb-4">
                                  <div className="job_filter_grid_info">
                                    <div className="job_heading ">
                                      <h5>{job.title}</h5>
                                    </div>
                                    <div className="apply_grid_box">
                                      <label className="wishlist ">
                                        {result?.type === "applicant" ? (
                                          <button className="btn job_details_applybtn_grid apply_job_grid_new">
                                            {" "}
                                            Apply
                                          </button>
                                        ) : result?.type ===
                                          "recruiter" ? null : (
                                          <Link to="/auth">
                                            <div className="login_apply">
                                              {" "}
                                              <button className="btn job_details_applybtn_grid ">
                                                {" "}
                                                Login to Apply{" "}
                                              </button>{" "}
                                            </div>{" "}
                                          </Link>
                                        )}
                                      </label>
                                    </div>
                                    <div className="job_filter_grid_in_up">
                                      <i className="fas fa-map-marker-alt" />
                                      <span> Hyderabad</span>
                                      <i className="far fa-bookmark" />
                                      <span> {job.jobType}</span>
                                      <div className="job_filter_grid_in_down">
                                        <i className="far fa-clock" />{" "}
                                      </div>
                                    </div>
                                    <p />
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="job_filter_grid_type mr-auto mb-3">
                                    <span>Full Time</span>
                                  </div>
                                  <div className="job_filter_grid_salary">
                                    <span>
                                      <i className="fas fa-rupee-sign" />{" "}
                                      {job.salary}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {isLoading ? (
                        <div style={{ margin: "auto" }}>
                          <ReactLoading
                            type="balls"
                            color={"#270D44"}
                            height={150}
                            width={150}
                          />
                        </div>
                      ) : (
                        <div style={{ margin: "auto" }}>
                          <p>
                            There is no jobs to list with the current filter
                          </p>
                          <div className="text-center">
                            <button
                              className={`btn list_view mb-2 `}
                              onClick={resetFilter}
                            >
                              Reset Filter
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div class="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel="Prev"
                  nextLabel="Next"
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>

            {/* ad */}
            {/* <div className='col-md-2 google_ads'>

              <Browsead />

              <Browseverads />

              <Browsead />

              <Browsehrads />

              <Browsead />

              <Browseverads />


              <Browsehrads />

              <Browsead />

              <Browseverads />

              <Browsead />

              <Browsehrads />



            </div> */}

            {/* <ins className="adsbygoogle" style={{display: 'block'}} data-ad-client="ca-pub-3502028008615885" data-ad-slot={4102552451} data-ad-format="auto" data-full-width-responsive="true" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BrowseFilterList;