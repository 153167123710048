import React from 'react'
import CreateAdminProfile from './CreateAdminProfile'
import DatabaseDashboard from './DatabaseDashboard'
import JobpostingDatabase from './JobpostingDatabase'
import ComboproductDatabase from './ComboproductDatabase'

const Dashboard2 = () => {

    return (
        <div style={{ marginTop: "100px", backgroundColor: "#fbf7fd", paddingTop: "80px" }}>
            <div className='container  pb-5 mb-5'>





                {/* Create HR Profile Modal */}
                <div
                    class="modal fade "
                    id="Create_HR_Profile"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="Create_HR_ProfileTitle"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                        role="document"
                    >
                        <div class="modal-content search_candidates_modal">
                            <div className="modal-header modal-header--sticky">
                                <button
                                    type="button"
                                    className="close modal_srcand_close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="modal_content">
                                    <CreateAdminProfile />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* Create HR Profile Modal */}


                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="main_dashboard"><h1>Dashboard</h1></div>
                                </div>

                            </div>

                            <div className='row'>
                                <ul class="nav nav-pills dash_navpills ">
                                    <li style={{marginTop:"30px"}}>
                                        <a href="#1b" data-toggle="tab" className='dash_tab_links active '>Data Base</a>
                                    </li>
                                    <li style={{marginTop:"30px"}}>
                                        <a href="#2b" data-toggle="tab" className='dash_tab_links'>Job Posting</a>
                                    </li>
                                    <li style={{marginTop:"30px"}}>
                                        <a href="#3b" data-toggle="tab" className='dash_tab_links'>Combo Products</a>
                                    </li>
                                    <hr />
                                </ul>
                            </div>

                            <div className='note_dashboard mt-3'>
                                <p className='d-block'>
                                    <b>Note:</b> <b>Extra recruiter login costs ₹ 499 /- + GST Maximum 3 Recruiter logins for Package.</b>
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 m-auto'>
                            <div className='card p-3'>

                             <div className='d-flex'>

                                <div><img src='https://perfextechnologies.com/assets/img/logo2.png' className='img-fluid' style={{width:"100px"}}/></div>
                                <div>
                                <h1 className='db_c_cname'>Company Name</h1>
                                <p className='db_c_designation'>IT & Software</p>
                                <p className='db_c_url'>www.companyname.com</p>
                                </div>
                             </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div id="exTab3" class="container">
                    <div className='card dabo_profile' >
                    {/* Tabs */}

                    {/* <ul class="nav nav-pills dash_navpills ">
                        <li style={{ marginBottom: "20px" }}>
                            <a href="#1b" data-toggle="tab" className='dash_tab_links active pb-2'>Data Base</a>
                        </li>
                        <li><a href="#2b" data-toggle="tab" className='dash_tab_links pb-2'>Job Posting</a>
                        </li>
                        <li><a href="#3b" data-toggle="tab" className='dash_tab_links pb-2'>Combo Products</a>
                        </li>
                        <hr />
                    </ul>

                    <div className='note_dashboard'>
                        <p className='d-block'>
                            <b>Note:</b> <b>Extra recruiter login costs ₹ 499 /- + GST Maximum 3 Recruiter logins for Package.</b>
                        </p>
                    </div> */}

                    <div class="tab-content clearfix">

                        {/* Dashboard1 */}
                        <div class="tab-pane active" id="1b">

                            <DatabaseDashboard />

                        </div>

                        {/* Dashboard1 */}



                        {/* Dashboard2 */}
                        <div class="tab-pane" id="2b">

                            <JobpostingDatabase />

                        </div>

                        {/* Dashboard2 */}



                        {/* Dashboard3 */}

                        <div class="tab-pane" id="3b">

                            <ComboproductDatabase />


                        </div>

                        {/* Dashboard3 */}





                    </div>
                    </div>
                </div>

            </div>


            {/* Admin Login Profile Modal */}
            {/* <div
                class="modal fade "
                id="LogIn_ProfileTitle"
                tabindex="-1"
                role="dialog"
                aria-labelledby="LogIn_ProfileTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content search_candidates_modal">
                        <div className="modal-header modal-header--sticky">
                            <button
                                type="button"
                                className="close modal_srcand_close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="modal_content">
                                <AdminLoginForm />


                            </div>
                        </div>

                    </div>

                </div>
            </div> */}
            {/* Admin Log In Modal */}


            {/* create hr profile */}
            {/* <div
                class="modal fade "
                id="Create_hrProfile"
                tabindex="-1"
                role="dialog"
                aria-labelledby="Create_hrProfile"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content search_candidates_modal">
                        <div className="modal-header modal-header--sticky">
                            <button
                                type="button"
                                className="close modal_srcand_close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="modal_content">
                                <Createhrprofile /> 
                            </div>
                        </div>

                    </div>

                </div>
            </div> */}
            {/* create hr profile  */}





            {/* <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                // style={{marginTop:"150px"}}
                className="Dashboard_Admin_login_form_modal"
            >

                <div className='position-relative'>
                    <AdminLoginForm />
                    <button className='admin_logIn_modal_close' onClick={toggleModal}>x</button>
                </div>

            </Modal> */}
        </div>


    )
}

export default Dashboard2
