import React from 'react'
import { Link } from 'react-router-dom'

const TopSearches = () => {
  return (
    <div>
      <div className='container'>
        <div className='row'>
        {/* <Link to='/browsefilterlist?skill=Banking'>
        <button className='btn btn-primary'>Banking</button>
        </Link>
        <Link to='/browsefilterlist?keyword=Sales&qlocation='>
        <button className='btn btn-primary'>Sales</button>
        </Link> */}
        <div className='container_grid'>
          <div class="item_grid">Item 1</div>
  <div class="item_grid">Item 2</div>
  <div class="item_grid">Item 3</div>
  <div class="item_grid">Item 1</div>
  <div class="item_grid">Item 2</div>
  <div class="item_grid">Item 3</div>
  </div>
        </div>
      </div>
    </div>
  )
}

export default TopSearches
