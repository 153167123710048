import React,{useState} from 'react'
import Modal from 'react-modal'
import ForgetPassword3 from './ForgetPassword3';

const ForgetPassword2 = () => {

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <div className='container'>
                <div className='row'>

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Enter OTP:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="enterotp"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter OTP</span>
                            </label>
                        </div>
                    </div>

                    <button className="jobfair_btn jobfair_regbtn mt-3" onClick={toggleModal}>Enter OTP</button>
                </div>
            </div>

            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                // style={{marginTop:"150px"}}
                className="Dashboard_Admin_login_form_modal"
            >

                <div className='position-relative'>
                    <ForgetPassword3 />
                    <button className='admin_logIn_modal_close' onClick={toggleModal}>x</button>
                </div>

            </Modal>
        </div>
    )
}

export default ForgetPassword2
