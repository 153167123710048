import React, { useState } from "react";
import { useForm } from "react-hook-form";
import apiList from "./lib/apiList";
import axios from "axios";

const Driveform = () => {
  const [showForm, setShowForm] = useState(true);
  const [driveLink, setDriveLink] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const handleForm = (data) => {
    console.log("ddd ", data);
    axios.post(apiList.driveForm, data).then((res) => {
      setShowForm(false);
      setDriveLink(res.data.link);
    });
  };

  return (
    <div className="drive_form">
      <div className="container">
        <div className="row login1_maincard1">
          <div className="card cardsss" style={{ backgroundColor: "#f0f8ff" }}>
            <div className="row drive_imgs">
              <div className="col-md-3 mb-2 m-auto">
                {/* <p>Mega job fair 2023 powered by :</p> */}
                <div className="drive_pj">
                  <img
                    src="./images/PAB SOL White with BG.png"
                    className="img-fluid"
                    style={{height:"90px"}}
                  />
                </div>
              </div>
              <div className="col-md-3 mb-2 m-auto">
                <div className="drive_ps">
                  <img
                    src="./images/JNTU Logo.png"
                    className="img-fluid"
                    style={{ width: "115px" }}
                  />
                </div>
              </div>
              <div className="col-md-3 mb-2 m-auto">
                <div className="drive_hr">
                  <img src="./images/hr co.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3 mb-2 m-auto">
                <div className="">
                  {/* <img src="./images/lasya.jpeg"  style={{height:"75px",width:"120px"}}/> */}
                  <img src="https://www.lasyainfotech.com/wp-content/uploads/2022/12/creative_new_blue1_hd.jpg"  style={{height:"75px",width:"120px"}}/>
                  {/* https://www.lasyainfotech.com/wp-content/uploads/2022/12/creative_new_blue1_hd.jpg */}
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-6">
                {showForm ? (
                  <div className="login_card">
                    <form onSubmit={handleSubmit(handleForm)}>
                      <h1
                        className="text-center mt-2 mb-2 log_head3"
                        style={{ fontFamily: "emoji" }}
                      >
                        <b>Register Your Details</b>
                      </h1>

                      <input
                        type="text"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Your Name"
                        name="name"
                        {...register("name", { required: true })}
                        required
                      />

                      <input
                        type="text"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Mail Id"
                        name="email"
                        {...register("email", { required: true })}
                        required
                      />

                      <input
                        type="text"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Mobile Number"
                        name="contactNumber"
                        {...register("contactNumber", { required: true })}
                        required
                      />

                      <input
                        type="text"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Graduation"
                        name="graduation"
                        {...register("graduation", { required: true })}
                        required
                      />

                      <input
                        type="number"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Year of Passout"
                        name="graduation"
                        {...register("passoutYear", { required: true })}
                        required
                      />

                      <input
                        type="number"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Pincode"
                        name="graduation"
                        {...register("pincode", { required: true })}
                        required
                      />

                      <input
                        type="text"
                        className="form-control form-control1 mt-3 p-3"
                        placeholder="Enter Location"
                        name="location"
                        {...register("location")}
                      />

                      {/* <input type="file" />
                                        <label for="myfile" className="drive_file_upload">
                                            Upload Resume
                                        </label>
                                        <input
                                            type="file"
                                            id="myfile"
                                            name="myfile"
                                        // hidden
                                        // onChange={(event) =>
                                        //     imageonChangeHandling(event, "resume")

                                        /> */}

                      {/* <input type="text" className="form-control mt-3 p-4" placeholder='Enter Confirm Password' /> */}
                      <button className="log_button2 mt-3" type="submit">
                        Register
                      </button>
                    </form>
                  </div>
                ) : (
                  <div className="login_card">
                    <h3>Your Hall Ticket is Ready!</h3>
                    <a href={driveLink} target="_blank">
                      <button className="log_button2 mt-3">Download</button>
                    </a>
                  </div>
                )}
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 text-left m-auto order-first order-md-last">
                <div className="drive_heads">
                  <h1>Eligibility: </h1>
                  <p>
                    10th, Inter, B.Tech, M.Tech, MBA all Degree/PG, B.Pharm,
                    M.Pharm, Nursing For Freshers & Experienced Candidates of
                    2012 to 2022.
                  </p>
                </div>

                <div className="drive_heads">
                  <h1>Sectors: </h1>
                  <p>
                    IT, ITES, Management, Pharma Core, Banking, Health care,
                    Hospitality, many more.
                  </p>
                </div>
              </div>
            </div>
            {/* <p>Appling for mega job fair 2023 JNTUH 25/02/2023 and 26/02/2023 . Powered by <span><img src='./images/PAB.png' className='img-fluid'/></span> </p> */}

            <div className="row mt-4">
              <div className="drive_line">
                <div className="col-md-8 col-sm-12 drive_under">
                  <p>
                    Appling for mega job fair 2023 in JNTUH 25/02/2023 and
                    26/02/2023 . Powered by :{" "}
                  </p>
                </div>
                <div className="col-md-4 col-sm-12 p-0 text-left">
                  <div className="drive_under_img">
                    <img
                      src="./images/pab  RUSSIA  violet-01.png"
                      className="img-fluid"
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driveform;
