import React from 'react'
import { Link } from 'react-router-dom'

const HomeBanners = () => {
  return (



    <div className='container position-relative'>



      <div className=" text-center">
        <img
          className="img-fluid homebanner_img"
          src="images/homesys.png"
          alt="Generic placeholder image"
        />
      </div>
      <Link to="#" style={{ position: "absolute", bottom: "0", right: "13px" }} className="text-right d-block"> <button
        className="text-white btn-lg homebanner_btn">
        Click Here
      </button></Link>



    </div>


  )
}

export default HomeBanners
