import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
// import apiList from "../../../lib/apiList";
import apiList from "../../lib/apiList";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const DatabaseServices = () => {

  const result = useSelector((state) => state.data);
  console.log(result);
  const [modalShow, setModalShow] = React.useState(false);
  const [totalAmount, setTotalamount] = useState();
  const [user, setUser] = useState();
  const [priceDetails, setPriceDetails] = useState({
    value: "",
    gst: "",
  });
  const onHide = () => setModalShow(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const modalDynamic = (e) => {
    setPriceDetails({
      ...priceDetails,
      value: e.value,
      gst: e.gst,
    });
    setTotalamount(Number(e.value) + Number(e.gst));
    setModalShow(true);
  };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    axios
      .get(apiList.user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setUser(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }
  function isObj(val) {
    return typeof val === "object";
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    return fetch(`${apiList.paytmpayment}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const makePayment = () => {
    if (result) {
      // console.log("working")
      getData({
        name: user.companyname,
        email: user.email,
        phone: JSON.stringify(user.contactNumber),
        amount: JSON.stringify(totalAmount),
      }).then((response) => {
        var information = {
          action: "https://securegw.paytm.in/theia/processTransaction",
          params: response,
        };
        post(information);
      });
    } else {
      toast.error("You Must Login First");
    }
  };

  return (
    <>


      {/* <div className="container">
        <div className="cards">
          <div className="row">
            <div className="col-sm-4 mt-3">
              <div className="card">
                <span className="close"></span>
                <span className="arrow"></span>
                <article>
                  <h2>Monthly</h2>
                  <div className="title"><i className="fas fa-rupee-sign"></i> 700</div>
                  <div className="pic">
                    <img src="images/MonthlyNew.png" />
                  </div>
                  <div className="desc">
                    <label  className="font-weight-bold new_Job_lists"> Description :</label>
                    <ul className="pl-1">
                      <li>Exel downloads 4000 profiles</li>
                      <li>Reach out to 2000 views</li>
                      <li>Easy to access 10k mails</li>
                    </ul>
                  </div>
                </article>
                <div className="actions">
                  <button className="btn">
                    <span>Buy Now</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mt-3">
              <div className="card">
                <span className="close"></span>
                <span className="arrow"></span>
                <article>
                  <h2>3 Months</h2>
                  <div className="title"><i className="fas fa-rupee-sign"></i> 1400</div>
                  <div className="pic">
                    <img src="images/3 MonthsNew.png" />
                  </div>
                  <div className="desc">
                  <label  className="font-weight-bold"> Description :</label>
                    <ul className="pl-1">
                      <li>Exel downloads 4000 profiles</li>
                      <li>Reach out to 2000 views</li>
                      <li>Easy to access 10k mails</li>
                    </ul>
                  </div>
                </article>
                <div className="actions">
                  <button className="btn">
                  <span>Buy Now</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mt-3">
              <div className="card">
                <span className="close"></span>
                <span className="arrow"></span>
                <article>
                  <h2>6 Months</h2>
                  <div className="title"><i className="fas fa-rupee-sign"></i> 2100</div>
                  <div className="pic">
                    <img src="images/6 MonthsNew.png" />
                  </div>
                  <div className="desc">
                  <label className="font-weight-bold "> Description :</label>
                    <ul className="pl-1">
                      <li>Exel downloads 4000 profiles</li>
                      <li>Reach out to 2000 views</li>
                      <li>Easy to access 10k mails</li>
                    </ul>
                  </div>
                </article>
                <div className="actions">
                  <button className="btn">
                    <span>Buy Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>

        <div className="container">
          <div className="row">
            {/* <div className="col-md-4">
              <article className="card__content1 grid">
                <div className="card__pricing">
                  <div className="card__pricing-number">
                    <span className="card__pricing-symbol">₹ </span>12999
                  </div>
                  <span className="card__pricing-month">+ GST</span>
                </div>

                <header className="card__header">
                  <div className="card__header-circle grid">
                    <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-pricing-card-using-html-and-css/free-coin.png" alt="" className="card__header-img" />
                  </div>

                  <span className="card__header-subtitle"></span>
                  <h1 className="card__header-title">3 Months Plan</h1>
                </header>

                <ul className="card__list grid">
                  {/* <li className="card__list-item"> */}
            {/* <i className="fas fa-check card__list-icon"></i> *
                  <p className="card__list-description"><h4>Daily Limit</h4></p>
                  {/* </li> *
                  <div className="combo_product_list">
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">250 Excel Downloads</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">150 Profile views</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">30 SMS</p>
                    </li>
                    {/* <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">10 Job Posts</p>
                    </li> *
                  </div>

                  <p className="card__list-description"><h4>Monthly Limit</h4></p>
                  <div className="combo_product_list">
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">2000 Excel Downloads</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">1500 Profile views</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">150 SMS</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">10 Job Posts</p>
                    </li>
                  </div>
                </ul>

                <button className="card__button">Buy Now</button>
              </article>
            </div> */}

            {/* <!--==================== CARD 2 ====================--> */}
            {/* <div className="col-md-4">
              <article className="card__content1 grid">
                <div className="card__pricing">
                  <div className="card__pricing-number">
                    <span className="card__pricing-symbol">₹</span>44999
                  </div>
                  <span className="card__pricing-month">+ GST</span>
                </div>

                <header className="card__header">
                  <div className="card__header-circle grid">
                    <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-pricing-card-using-html-and-css/pro-coin.png" alt="" className="card__header-img" />
                  </div>

                  {/* <span className="card__header-subtitle">6 Months Plan</span> *
                  <h1 className="card__header-title">6 Months Plan</h1>
                </header>

                {/* <ul className="card__list grid">
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">100 user request</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Unlimited downloads</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Unlock all features from our site</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Daily content updates</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Fully editable files</p>
          </li>
        </ul> *

                <ul className="card__list grid">
                  {/* <li className="card__list-item"> */}
            {/* <i className="fas fa-check card__list-icon"></i> *
                  <p className="card__list-description"><h4>Daily Limit</h4></p>
                  {/* </li> *
                  <div className="combo_product_list">
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">250 Excel Downloads</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">1000 Profile views</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">50 SMS</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">10 Job Posts</p>
                    </li>
                  </div>

                  <p className="card__list-description"><h4>Monthly Limit</h4></p>

                  <div className="combo_product_list">
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">3000 Excel Downloads</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">1500 Profile views</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">150 SMS</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">10 Job Posts</p>
                    </li>
                  </div>
                </ul>

                <button className="card__button">Buy Now</button>
              </article>
            </div> */}

            {/* <!--==================== CARD 3 ====================--> */}
            {/* <div className="col-md-4">
              <article className="card__content1 grid">
                <div className="card__pricing">
                  <div className="card__pricing-number">
                    <span className="card__pricing-symbol">₹</span>79999
                  </div>
                  <span className="card__pricing-month">+ GST</span>
                </div>

                <header className="card__header">
                  <div className="card__header-circle grid">
                    <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-pricing-card-using-html-and-css/enterprise-coin.png" alt="" className="card__header-img" />
                  </div>

                  {/* <span className="card__header-subtitle">1 Year Plan</span> *
                  <h1 className="card__header-title">1 Year Plan</h1>
                </header>

                {/* <ul className="card__list grid">
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Unlimited  user request</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Unlimited downloads</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Unlock all features from our site</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Daily content updates</p>
          </li>
          <li className="card__list-item">
            <i className="fas fa-check card__list-icon"></i>
            <p className="card__list-description">Fully editable files</p>
          </li>
        </ul> *

                <ul className="card__list grid">
                  {/* <li className="card__list-item"> */}
            {/* <i className="fas fa-check card__list-icon"></i> *
                  <p className="card__list-description"><h4>Daily Limit</h4></p>
                  {/* </li> *
                  <div className="combo_product_list">
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">250 Excel Downloads</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">1000 Profile views</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">50 SMS</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">10 Job Posts</p>
                    </li>
                  </div>

                  <p className="card__list-description"><h4>Monthly Limit</h4></p>
                  <div className="combo_product_list">
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">3000 Excel Downloads</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">1500 Profile views</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">150 SMS</p>
                    </li>
                    <li className="card__list-item">
                      <i className="fas fa-check card__list-icon"></i>
                      <p className="card__list-description">10 Job Posts</p>
                    </li>
                  </div>
                </ul>

                <button className="card__button">Buy Now</button>
              </article>
            </div> */}
          </div>
        </div>


        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card database_card">
                <table className="table  text-center table-borderless">
                  <thead style={{ backgroundColor: "#2c0264", color: "#ffffff", borderRadius: "10px" }}>
                    <tr>
                      <th scope="col">Monthly</th>
                      <th scope="col">Daily</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2000 Excel Downloads</td>
                      <td>250 Excel Downloads</td>
                    </tr>
                    <tr>
                      {/* <th scope="row">2</th>
                    <td>Jacob</td> */}
                      <td>1000 Profile Views</td>
                      <td>150 Profile Views</td>
                    </tr>
                    <tr>
                      {/* <th scope="row">3</th>
                    <td>Larry</td> */}
                      <td>100 SMS</td>
                      <td>30 SMS</td>
                    </tr>
                  </tbody>


                </table>

                <Modal
                  isOpen={modalShow}
                  shouldCloseOnOverlayClick={true}
                  onRequestClose={() => setModalShow(false)}
                  style={customStyles}
                  ariaHideApp={false}
                >
                  <div className="payment_modal">
                    <div className="close_modal_btn">
                      <a
                        className="close_modal"
                        onClick={() => setModalShow(false)}
                      >
                        <i className="fas fa-times"></i>
                      </a>
                    </div>
                    <div className="payment_details_heading ">
                      <h5>Payment Details</h5>
                    </div>

                    <div className="payment_details">
                      <p>
                        <span className="payment_1">Total </span> :
                        <span className="payment_2">
                          {" "}
                          &#8377; {priceDetails.value}
                        </span>{" "}
                      </p>
                      <p>
                        <span className="payment_1">Estimated GST </span> :
                        <span className="payment_2">
                          {" "}
                          &#8377; {priceDetails.gst}
                        </span>{" "}
                      </p>
                    </div>
                    <hr className="hr_divider" />
                  </div>
                  <div className="payment_details">
                    <p>
                      {" "}
                      <span className="payment_1">
                        Total Payable Amount
                      </span> :{" "}
                      <span className="payment_2"> &#8377; {totalAmount}</span>
                    </p>
                  </div>
                  <div className="buynow_payment">
                    <button className="buynow_here" onClick={makePayment}>
                      Proceed
                    </button>
                  </div>
                </Modal>

             

                <div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 1 Month</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "4999",
                        gst: "899",
                      })
                    }} >₹ 4999+</button>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container ">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 3 Months</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "12999",
                        gst: "2339",
                      })
                    }} >₹ 12999+</button>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 6 Months</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "22999",
                        gst: "4139",
                      })
                    }} >₹ 22999+</button>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 1 Year</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "38999",
                        gst: "7019",
                      })
                    }} >₹ 38999+</button>  
                      </div>
                    </div>
                  </div>
                </div>

                

              </div>

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 1 Month Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 4999+</a>
                </div>
              </div> */}

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 3 Months Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 12999+</a>
                </div>
              </div> */}

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 6 Months Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 22999+</a>
                </div>
              </div> */}

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 1 Year Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 38999+</a>
                </div>
              </div> */}

            </div>

            <div className="col-md-6">
              <div className="card database_card">
                <table className="table   text-center">
                  <thead style={{ backgroundColor: "#2c0264", color: "#ffffff", borderRadius: "10px" }}>
                    <tr>
                      <th scope="col">Monthly</th>
                      <th scope="col">Daily</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>4000 Excel Downloads</td>
                      <td>500 Excel Downloads</td>
                    </tr>
                    <tr>
                      {/* <th scope="row">2</th>
                    <td>Jacob</td> */}
                      <td>2000 Profile Views</td>
                      <td>300 Profile Views</td>
                    </tr>
                    <tr>
                      {/* <th scope="row">3</th>
                    <td>Larry</td> */}
                      <td>250 SMS</td>
                      <td>100 SMS</td>
                    </tr>
                  </tbody>
                </table>

                

                <div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 1 Month</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "8999",
                        gst: "1619",
                      })
                    }} >₹ 8999+</button>  
                      </div>
                    </div>
                  </div>
                </div>

              

                <div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 3 Months</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "23999",
                        gst: "4319",
                      })
                    }} >₹ 23999+</button>  
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 3 Months</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "44999",
                        gst: "8009",
                      })
                    }} >₹ 44999+</button>  
                      </div>
                    </div>
                  </div>
                </div>


<div className="container">
                  <div className="row db_mny">
                    {/* <div className="col-6 text-center db_mnyt">
                      1 month
                    </div> */}
                    <div className="col-12 text-center mb-3">
                      <div className="btn-group db_mny_btn_gup">
                        <button className="btn  db_mny_btn" type="button" style={{borderRight:"1px solid #9d9595  "}}>For 3 Months</button>
                        <button className="btn db_mny_btn" type="button" onClick={() => {
                      modalDynamic({
                        value: "79999",
                        gst: "14339",
                      })
                    }} >₹ 79999+</button>  
                      </div>
                    </div>
                  </div>
                </div>
                

                

              </div>

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 1 Month Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 23999+</a>
                </div>
              </div> */}

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 3 Months Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 44999+</a>
                </div>
              </div> */}

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 6 Months Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 79999+</a>
                </div>
              </div> */}

              {/* <div className="my-4 py-4 px-5  d-sm-flex align-items-center justify-content-between jobposting_main1">
                <div className="text-center">
                  {/* <span style={{ fontWeight: "600" }}>Express Job Posting</span>
                  <h1 className="my-2" style={{ color: "#270d44" }}><b>₹1499+</b></h1>
                  <small className="text-uppercase text-secondary">Month</small> *
                  <h5><b>For 1 Year Pack   </b></h5>
                </div>
                {/* <div className="jobposting_content"><i class="fas fa-check"></i>150 direct relevant profiles</div> *
                <div className="text-center">
                  <a  className="btn text-white px-2 py-1" style={{ backgroundColor: "#270d44", color: "#ffffff", borderRadius: "10px" }}>₹ 38999+</a>
                </div>
              </div> */}

            </div>


          </div>
        </div>

        <p className="mt-5 text-center">Note : Select the money that you  need and click on money value option Taxes are excluded in above plans</p>
      </div>


             

    </>
  );
};

export default DatabaseServices;
