import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import apiList from "../lib/apiList";
import { toast } from "react-toastify";
import moment from "moment";
import ReactPaginate from "react-paginate";

const SavedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [recruiter, setRecruiter] = useState([]);
  const [onHold, setOnHold] = useState(false);
  const [offset, setOffset] = useState(1);
  //   const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const indexOfLastPost = offset * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentPosts = jobs.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  };

  const handleRemoveWishlist = async (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    console.log("hhhh", headers);
    if (onHold) {
      return;
    }
    setOnHold(true);
    // if(isItFromMain){
    await axios
      .get(apiList.wishlist + "remove/" + id, {
        headers,
      })
      .then((response) => {
        let jobList = jobs;
        jobList = jobList.map((jobItem) => {
          if (jobItem._id == id) {
            jobItem.wishlist = false;
          }
          return jobItem;
        });
        setJobs(jobList);
        toast.success("Removed from Wishlist!");
        setOnHold(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      });
    getJobs();
  };

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = () => {
    axios
      .get(apiList.wishlist + "list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPageCount(Math.ceil(response.data.data.length) / perPage);
        console.log(response.data, "hello");
        setJobs(response.data.data.reverse());
        setRecruiter(response.data.postedby);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
  };

  console.log("jobsjobs ", jobs);
  console.log(pageCount, "pageCount");

  return (
    <>
      <div id="sec4" style={{ backgroundColor: "#f8f8f8" }}>
        <div class="container main_content my-5">
          <div class="row" style={{marginTop:"100px"}}>
            <div class="col-lg-4 col-md-4">
              <Sidebar />
            </div>
            <div className="col-lg-8  col-md-8">
              <div className="wrapper">
                <div className="">
                  <div class=" ">
                    <h5 class=" pull-left cp">
                      {jobs?.length} Saved jobs found
                    </h5>
                  </div>

                  {currentPosts.map((job, key) => {
                    if (job.jobId === null) {
                      return null;
                    }
                    return (
                      <div className="card applied_jobs mb-2" key={job._id}>
                       
                          <div className="container-fluid">
                            <div>
                            <Link to={`/jobdetailes/${job.jobId && job.jobId._id}`}>
                              <h1 className="s_jobs_title">
                                {" "}
                                {job.jobId?.title}
                              </h1>
                              <h4 className="s_jobs_cname">
                                {job.recruiter?.companyname}
                              </h4>
                              <div className="s_jobs_icons">
                                <span class="">
                                  <em class="icon">
                                    <i
                                      className="ri-briefcase-line"
                                      style={{ paddingRight: "5px" }}
                                    ></i>
                                  </em>
                                  <span>{job.jobId?.experience}</span>
                                  <span class="separator"></span>
                                </span>
                                <span class="">
                                  <em class="icon">
                                    <i style={{ paddingRight: "5px" }}>₹</i>
                                  </em>
                                  <span> {job.jobId?.salary}</span>
                                  <span class="separator"></span>
                                </span>
                                <span class="">
                                  <em class="icon">
                                    <i
                                      className="ri-map-pin-line"
                                      style={{ paddingRight: "5px" }}
                                    ></i>
                                  </em>
                                  <span>{job.jobId?.cities[0]}</span>
                                </span>
                              </div>
                              <div className="s_jobs_description">
                                <i
                                  class="ri-book-line"
                                  style={{ paddingRight: "5px" }}
                                ></i>
                                <p>{job.jobId?.description}</p>
                              </div>
                              <div>
                                <ul className="job_roles_details">
                                  {job.jobId?.skillsets.map((each) => (
                                    <span className="b_side_card_skills">
                                      {each}
                                    </span>
                                  ))}
                                </ul>
                              </div>
                              </Link>
                              <div className="sjobs_posting">
                                <span>
                                  <p>
                                    Posted:
                                    {moment(
                                      job.jobId?.postedAt
                                        ? Number(job.jobId?.postedAt)
                                        : job.jobId?.dateOfPosting
                                    ).fromNow()}
                                  </p>
                                </span>
                                <a
                                  onClick={() =>
                                    handleRemoveWishlist(job.jobId._id)
                                  }
                                >
                                  <em>
                                    <i className="ri-bookmark-fill" style={{color:"black"}}></i>
                                  </em>
                                  <span style={{ paddingRight: "5px" }}>
                                    Remove
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                       
                      </div>
                    );
                  })}
                  <div class="d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel="Prev"
                      nextLabel="Next"
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedJobs;
