import React from 'react'

const Updatehrprofile = () => {
    return (
        <div>
            <div className='container'>
                <div className='row'>


                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>HR ID:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="hrid"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter HR Id</span>
                            </label>
                        </div>
                    </div>

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>HR Name:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="hrname"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter HR Name</span>
                            </label>
                        </div>
                    </div>


                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>HR Mail:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="hrmail"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter HR Mail</span>
                            </label>
                        </div>
                    </div>


                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>HR Position:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="companyname"
                                    placeholder=" "
                                />
                                <span className="input__label">HR Position</span>
                            </label>
                        </div>
                    </div>


                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Date Of Joining:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="Date"
                                    style={{ borderRadius: "5px" }}
                                    name="joindate"
                                    placeholder=" "
                                />
                                <span className="input__label">Date Of Joining</span>
                            </label>
                        </div>
                    </div>



                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Create Password:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="createpassword"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Your Password</span>
                            </label>
                        </div>
                    </div>

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Confirm Password:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="confirmpassword"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Your confirm Password</span>
                            </label>
                        </div>
                    </div>

                    {/* <div className="col-lg-12 col-md-12">
                        <label>Meeting</label>
                        <div className="form-group">
                            <div className="form-check form-check-inline">
                                <input
                                
                                    className="form-check-input"
                                    type="radio"
                                    name="meeting"
                                    id="scheduled"
                                  value="Scheduled"
                                />
                                <label
                                    className="form-check-label"
                                    for="scheduled"
                                >
                                    Scheduled
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="meeting"
                                    id="Postponed"
                                  value="Postponed"
                                />
                                <label
                                    className="form-check-label"
                                    for="Postponed"
                                >
                                    Postponed
                                </label>
                            </div>
                        </div>
                    </div> */}


                    <button className="jobfair_btn jobfair_regbtn mt-3" >Update</button>


                </div>
            </div>
        </div>
    )
}

export default Updatehrprofile
