import React from "react";
import { Link, NavLink } from "react-router-dom";
import MobileAds from "../../ads/MobileAds";
import FeaturedCities from "../../HOME/FeaturedCities";
import CategoryJobs from "./CategoryJobs";
import CompanyJobs from "./CompanyJobs";
import DesignationJobs from "./DesignationJobs";
import LocationalJobs from "./LocationalJobs";
import SearchFilter from "./SearchFilter";
import SkillJobs from "./SkillJobs";
import Subfilter from "./subfilter";
import JobsSidebar from "./JobsSidebar";

const AllJobs = () => {
  return (
    <div style={{ marginTop: "150px" }}>
      {/* <div className="jobs_alljobs">
                <div className="heading_pic_alljobs w-100 text-white  h-100">
                    <div className="container">
                        <SearchFilter />
                        <Subfilter />
                    </div>
                </div>
            </div>
            <FeaturedCities />
           
            <div className='container mt-4 mb-4'>
                <MobileAds />
            </div>
            <CompanyJobs />
            <div className='container mt-3 mb-4'>
                <MobileAds />
            </div>
            <CategoryJobs />
            <div className='container mt-3 mb-4'>
                <MobileAds />
            </div>
            <DesignationJobs />
            <div className='container mt-3 mb-4'>
                <MobileAds />
            </div>
            <SkillJobs />
            <div className='container mt-3 mb-4'>
                <MobileAds />
            </div> */}

      <div className="container jobs_alljobs">
        <div className="row  ">
          <div className="col-md-3">
              <JobsSidebar/>
          </div>

          <div className="col-md-9">
            <div>
              <FeaturedCities />
            </div>
            <div>
              <CompanyJobs />
            </div>
            <div>
              <CategoryJobs />
            </div>
            <div>
              <DesignationJobs />
            </div>
            <div>
              <SkillJobs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllJobs;
