import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import axios from "axios";
import apiList from "../lib/apiList";
import ReactPaginate from "react-paginate";
import moment from "moment";

const JobAlerts = () => {
  const [jobs, setJobs] = useState([]);

  // Pagination code
  const [offset, setOffset] = useState(1);
  //   const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const indexOfLastPost = offset * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentPosts = jobs.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(apiList.jobAlerts + "list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPageCount(Math.ceil(response.data.data.length) / perPage);
        console.log("ddd ", response.data);
        // let jobs = response.data.data.map((job) => {
        //   let output = job.jobId;
        //   output.recruiter = { ...job.jobId, recruiter: job.recruiter };
        //   return output;
        // });

        // console.log("jjjjssss ", jobs);
        setJobs(response.data.data);
      })
      .catch((err) => {
        // if (err.response && err.response.data) {
        //   console.log(err.response.data);
        // } else {
        //   console.log(err);
        // }
        console.log(err.response);
      });
  };

  console.log(jobs);
  console.log(currentPosts);
  return (
    <div id="sec4" style={{ backgroundColor: "rgb(248, 248, 248)" }}>
      <div class="container main_content my-5">
        <div class="row" style={{ marginTop: "100px" }}>
          <div class="col-lg-4 col-md-4">
            <Sidebar />
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="wrapper">
              <div className="">
                <div class="">
                  <h5 className=" pull-left  cp">
                    {jobs.length} JobAlerts found
                  </h5>
                </div>

                {jobs.length > 0 ? (
                  currentPosts.map((job) => (
                    <div className="card applied_jobs mb-2">
                      <Link to={`/jobdetailes/${job._id}`}>
                        {/* <ul className="job-post">
                        <li key={job._id}>
                          <div className="job-box">
                            <div className="d-flex mb-2">
                              <div className="job-company">
                                <span>
                                  <img alt="" src="" />
                                </span>
                              </div>
                              <div className="job-info">
                                <h4>{job.title}</h4>
                                <ul>
                                  <li>
                                    <h5 className="home_company_name">
                              
                                    </h5>
                                  </li>
                                  <li>
                                    <h6 className="star_box">
                                      {" "}

                                    </h6>
                                  </li>
                                </ul>
                                <ul className="home_job_details">
                                  <li>
                                    <i className="fas fa-map-marker-alt"></i>
                                    {job.cities.map((job, index, arr) => {
                                      return (
                                        <>
                                          {job}
                                          {index != arr.length - 1
                                            ? "/"
                                            : ""}
                                        </>
                                      );
                                    })}
                                  </li>
                                  <li>
                                    <i className="far fa-bookmark"></i>
                                    {job.jobType}
                                  </li>

                                  <li>
                                    <i className="fas fa-shopping-bag"></i>
                                    {job.experience}
                                  </li>
                                  <li>
                                    <i className="fas fa-rupee-sign"></i>
                                    {job.salary}
                                  </li>
                                </ul>
                                <div className="mt-3">
                                  {job.skillsets.map((job) => {
                                    return (
                                      <>
                                        <button className="home_job_btn">
                                          {job}
                                        </button>
                                      </>
                                    );
                                  })}

                                  <div className="posted_home">
                                    <div className="job-type">
                                      <a href="#">
                                      <span>Posted : <i className="fas fa-history"></i>{" "}
                                            {moment(
                                              job.postedAt
                                                ? Number(job?.postedAt)
                                                : job?.dateOfPosting
                                            ).fromNow()}</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul> */}
                        <h1 className="s_jobs_title"> {job.title}</h1>
                        <h4 className="s_jobs_cname">{job.companyname}</h4>
                        <div className="s_jobs_icons">
                          <span class="">
                            <em class="icon">
                              <i
                                className="ri-briefcase-line"
                                style={{ paddingRight: "5px" }}
                              ></i>
                            </em>
                            <span>{job.experience}</span>
                            <span class="separator"></span>
                          </span>
                          <span class="">
                            <em class="icon">
                              <i style={{ paddingRight: "5px" }}>₹</i>
                            </em>
                            <span> {job.salary}</span>
                            <span class="separator"></span>
                          </span>
                          <span class="">
                            <em class="icon">
                              <i
                                className="ri-map-pin-line"
                                style={{ paddingRight: "5px" }}
                              ></i>
                            </em>
                            <span>{job.cities[0]}</span>
                          </span>
                        </div>
                        <div className="s_jobs_description">
                          <i
                            class="ri-book-line"
                            style={{ paddingRight: "5px" }}
                          ></i>
                          <p>{job.description}</p>
                        </div>
                        <div>
                          <ul className="job_roles_details">
                            {job.skillsets.map((each) => (
                              <span className="b_side_card_skills">{each}</span>
                            ))}
                          </ul>
                        </div>
                      </Link>
                      <div className="sjobs_posting">
                        <span>
                          <p>
                            Posted:
                            {moment(
                              job.postedAt
                                ? Number(job.postedAt)
                                : job.dateOfPosting
                            ).fromNow()}
                          </p>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="text-center">
                      <img
                        src="https://img.freepik.com/free-vector/instruction-manual-guide-document-with-cogwheel-isolated-design-element-male-character-analyzing-file-business-analysis-data-processing-updating-concept-illustration_335657-1666.jpg?w=740&t=st=1688814820~exp=1688815420~hmac=a4f763ae2e4fa21d277185d407b0f995a6497bbe1428243af24e2342a9ccaa77"
                        className="img-fluid"
                        style={{ width: "35%" }}
                      />
                    </div>
                    <h1
                      variant="h5"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "31px",
                      }}
                    >
                      No Applications Found
                    </h1>
                  </>
                )}
                <div class="d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel="Prev"
                    nextLabel="Next"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobAlerts;
