import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
// import apiList, { server } from "../../lib/apiList";
import apiList from "../../../lib/apiList";
import ReactPaginate from "react-paginate";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import moment from "moment";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useSelector, useDispatch } from "react-redux";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";

import Chip from "@material-ui/core/Chip";

import { useNavigate } from "react-router-dom";

const BrowseFilterList11 = () => {
  const isItFromMain = useSelector((state) => state.isItFromMain);
  const company = useSelector((state) => state.company);
  const newLocation = useSelector((state) => state.location);
  const newSkills = useSelector((state) => state.skill);
  const selectedCategories = useSelector((state) => state.selectedCategories);
  const selectedCompanies = useSelector((state) => state.selectedCompanies);
  const selectedLocations = useSelector((state) => state.selectedLocations);

  const selectedSkills = useSelector((state) => state.selectedSkills);
  const selectedDesignations = useSelector(
    (state) => state.selectedDesignations
  );
  const newDesignation = useSelector((state) => state.designation);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [post, setPost] = useState({
    skillsets: [],
    cities: [],
  });

  const result = useSelector((state) => state.data);
  let { search } = useLocation();
  let locate = useLocation();

  const query = new URLSearchParams(search);
  console.log("search ", query.get("category"));
  let paramKeyword = "";
  let paramQLocation = "";
  let paramTopCompanies = [];
  let paramIndustryType = [];

  let paramLocation = [];

  let paramDesignation = [];
  let paramSkill = [];

  paramSkill = query.get("keyword") ? query.get("keyword")?.split(",") : [];
  paramLocation = query.get("qlocation")?.split(",")
    ? query.get("qlocation")?.split(",")
    : [];

  if (query.get("company")) {
    paramTopCompanies.push(query.get("company"));
  }

  if (query.get("category")) {
    paramIndustryType.push(query.get("category"));
  }

  if (query.get("locate")) {
    paramLocation.push(query.get("locate"));
  }

  if (query.get("skill")) {
    paramSkill.push(query.get("skill"));
  }

  if (query.get("designate")) {
    paramDesignation.push(query.get("designate"));
  }

  console.log({
    paramKeyword,
    paramQLocation,
  });
  const [keyword, setKeyword] = useState(paramKeyword);
  const [qlocation, setQLocation] = useState(paramQLocation);

  const [jobs, setJobs] = useState([]);

  const [selectedJob, setSeletedJob] = useState(null);
  console.log(jobs)
  console.log(selectedJob);

  const [topCompanies, setTopCompanies] = useState(paramTopCompanies);
  const [experience, setExperience] = useState([]);

  const [location, setLocation] = useState(paramLocation);

  const [industryType, setIndustryType] = useState(paramIndustryType);
  const [education, setEducation] = useState([]);
  const [skill, setSkill] = useState(paramSkill);
  const [designation, setDesignation] = useState(paramDesignation);
  const [salary, setSalary] = useState();
  const [listType, setListType] = useState("list");
  const [isLoading, setLoading] = useState(false);
  const [onHold, setOnHold] = useState(false);
  const [keywordError, setKeywordError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [presentData, setPresentData] = useState([]);

  const list = [1, 2, 3, 4, 5, 6];

  // Pagination code
  const [offset, setOffset] = useState(1);
  //   const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [presentType, setPresentType] = useState("");
  // const indexOfLastPost = offset * perPage;
  // const indexOfFirstPost = indexOfLastPost - perPage;
  // const currentPosts = jobs.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
    console.log("selectedPage", selectedPage);
    fetchJobs(selectedPage);
    window.scrollTo({
      top: 450,
      behavior: "smooth",
    });
  };

  const handleTopCompaniesAdd = async (companies) => {
    setTopCompanies(companies);
    fetchJobs();
  };

  const handleTopCompaniesRemove = async (companies) => {
    setTopCompanies(companies);
    // fetchJobs();
  };

  const handleLocationAdd = async (locations) => {
    setLocation(locations);
    fetchJobs();
  };
  const handleLocationRemove = async (locations) => {
    setLocation(locations);
    // fetchJobs();
  };

  const handleIndustryTypeAdd = async (industry) => {
    setIndustryType(industry);
    fetchJobs();
  };

  const handleIndustryTypeRemove = async (industry) => {
    setIndustryType(industry);
    // fetchJobs();
  };

  const handleDesignationAdd = async (designate) => {
    setDesignation(designate);
    fetchJobs();
  };

  const handleDesignationRemove = async (designate) => {
    setDesignation(designate);
    // fetchJobs();
  };

  const handleEducationAdd = async (educations) => {
    setEducation(educations);
    fetchJobs();
  };

  const handleEducationRemove = async (educations) => {
    setEducation(educations);
    // fetchJobs();
    // resetFilter
  };

  const handleSkillAdd = async (educations) => {
    setSkill(educations);
    fetchJobs();
  };

  const handleSkillRemove = async (educations) => {
    setSkill(educations);
    // fetchJobs();
  };

  const resetFilter = () => {
    setTopCompanies([]);
    setExperience([]);
    setLocation([]);
    setEducation([]);

    setSkill([]);

    setSalary();
    setKeyword("");
    setQLocation("");
  };

  const handleAddWishlist = async (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    console.log("hhhh", headers);
    if (onHold) {
      return;
    }
    setOnHold(true);
    await axios
      .get(apiList.wishlist + "add/" + id, {
        headers,
      })
      .then((response) => {
        let jobList = jobs;
        jobList = jobList.map((jobItem) => {
          if (jobItem._id == id) {
            jobItem.wishlist = true;
          }
          return jobItem;
        });
        setJobs(jobList);
        toast.success("Added to Wishlist!");
        setOnHold(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      });
  };

  const handleRemoveWishlist = async (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    console.log("hhhh", headers);
    if (onHold) {
      return;
    }
    setOnHold(true);
    // if(isItFromMain){
    await axios
      .get(apiList.wishlist + "remove/" + id, {
        headers,
      })
      .then((response) => {
        let jobList = jobs;
        jobList = jobList.map((jobItem) => {
          if (jobItem._id == id) {
            jobItem.wishlist = false;
          }
          return jobItem;
        });
        setJobs(jobList);
        toast.success("Removed from Wishlist!");
        setOnHold(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      });
  };

  const fetchJobs = async (page = 0) => {
    console.log("====================================");
    console.log({ location, skill });
    console.log("====================================");
    setJobs([]);
    setLoading(true);
    console.log("topCompaniesssss", topCompanies);
    let token = localStorage.getItem("token");
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }
    let data = {
      location,
      experience,
      companies: topCompanies,
      educations: education,
      category: designation,
      industryType,
      skills: skill,
      // salaryMin: 0,
      // salaryMax: 18000
    };
    if (post.cities.length > 0) {
      data.location = post.cities;
    }
    if (post.skillsets.length > 0) {
      data.skills = post.skillsets;
    }
    //from here
    if (
      isItFromMain &&
      !company &&
      !newLocation &&
      !newSkills &&
      !newDesignation
    ) {
      setPresentData(selectedCategories);
      setPresentType("category");
      delete data.category;
      let rawData = [...selectedCategories];
      let result = rawData?.map(({ value }) => value);
      data.category = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / 20);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setSeletedJob(response.data.posts[0])
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.message);
        });
    } else if (company && !newLocation && !newSkills && !newDesignation) {
      setPresentData(selectedCompanies);
      setPresentType("company");
      delete data.companies;
      let rawData = [...selectedCompanies];
      let result = rawData?.map(({ key }) => key);
      data.companies = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / 20);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.message);
        });
    } else if (!company && newLocation && !newSkills && !newDesignation) {
      setPresentData(selectedLocations);
      setPresentType("location");
      delete data.location;
      let rawData = [...selectedLocations];
      let result = rawData?.map(({ value }) => value);
      data.location = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / 20);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data, "Yoooooo");
          toast.error(err.response.data.message);
        });
    } else if (!company && !newLocation && newSkills && !newDesignation) {
      setPresentType("skill");
      setPresentData(selectedSkills);
      delete data.skills;
      let rawData = [...selectedSkills];
      let result = rawData?.map(({ value }) => value);
      data.skills = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / 20);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data, "Yoooooo");
          toast.error(err.response.data.message);
        });
    } else if (!company && !newLocation && !newSkills && newDesignation) {
      setPresentData(
        selectedDesignations.length > 0 ? selectedDesignations : []
      );
      setPresentType("designation");
      delete data.industryType;
      let rawData = [...selectedDesignations];
      let result = rawData?.map(({ value }) => value);
      data.industryType = result;
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / 20);
          console.log("Yoooooo", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
          return;
        })
        .catch((err) => {
          console.log(err.response.data, "Yoooooo");
          toast.error(err.response.data.message);
        });
    } else {
      await axios
        .post(apiList.jobSearch + "?page=" + page, data, {
          headers,
        })
        .then((response) => {
          setPageCount(Math.ceil(response.data.counts) / 20);
          console.log("posts", response.data.posts);
          setJobs(response.data.posts);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.message);
        });
    }
  };

  const handleSearch = (e) => {
    let haveError = false;
    if (keyword == "") {
      haveError = true;
      setKeywordError("Keyword is required!");
    }
    if (qlocation == "") {
      haveError = true;
      setLocationError("Location is required!");
    }
    if (haveError) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    
    fetchJobs();
   
    

    return () => {
      dispatch({ type: "NEW_CLEAR" });
      dispatch({ type: "FROM_MAIN_COMPANY_CLEAR" });

      dispatch({ type: "FROM_MAIN_LOCATION_CLEAR" });
      dispatch({ type: "FROM_MAIN_DES_CLEAR" });
      dispatch({ type: "FROM_MAIN_SKILL_CLEAR" });
    };
  }, [topCompanies, experience, location, education, salary, industryType]);

  console.log("kkkkkkkk", keyword, qlocation);
  const handleDelete = (chipToDelete) => {
    let newData = [...presentData];
    let index = newData.findIndex((item) => item.value === chipToDelete.value);
    newData.splice(index, 1);
    setPresentData(newData);
    if (presentType === "category") {
      dispatch({
        type: "SELECTED_CATEGORIES",
        payload: chipToDelete.value,
      });
    } else if (presentType === "company") {
      dispatch({
        type: "SELECTED_COMPANIES",
        payload: chipToDelete,
      });
    } else if (presentType === "location") {
      dispatch({
        type: "SELECTED_LOCATIONS",
        payload: chipToDelete.value,
      });
    } else if (presentType === "skill") {
      dispatch({
        type: "SELECTED_SKILLS",
        payload: chipToDelete.value,
      });
    } else if (presentType === "designation") {
      dispatch({
        type: "SELECTED_DESIGNATIONS",
        payload: chipToDelete.value,
      });
    }
  };

  const handleCardClick = (job) => {
  
    // if(selectedJob && selectedJob.id === job.id)
    // {
    //   setSeletedJob(null);
    //       }
    //       else{
    //         setSeletedJob(jobs)
    //       }
   setSeletedJob((a) => (a === job ? a :job));
 
  };
  console.log(jobs);

  return (
    <div style={{ marginTop: "200px" }}>
      <div id="job_filter_list">
        <div className="container BFL">
          <div className="row ">
          

            <div className="list_view_width col-lg-12">
             

              {listType === "list" ? (  
                <>
                  {jobs.length > 0 ? (
                    
                    /* jobs?.map((job, index) => {
                      return (
                        <>
                          <div className="row">
                            <div
                              className={`col-lg-4 ${
                                selectedJob === job ? "active" : " "
                              } `}
                              key={job.id}
                              onClick={() => handleCardClick(job)}
                            >
                              <div className="card p-3">
                                <div className="row">
                                  <div className="col-8">
                                    <h1 className="browse_filter_designation">
                                      {job.title}
                                    </h1>
                                    <p className="browse_filter_company">
                                      {job.recruiter?.companyname}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <h1 className="browse_filter_package">
                                      {job.salary}
                                    </h1>
                                  </div>
                                </div>

                                <div className="row browse_filter_details">
                                  <p>{job.jobType}</p>
                                  <p>
                                    {" "}
                                    {job.cities.map((job, index, arr) => {
                                      return (
                                        <>
                                          {job}
                                          {index != arr.length - 1 ? "/" : ""}
                                        </>
                                      );
                                    })}
                                  </p>
                                  <p>Experience</p>
                                </div>
                                <div className="row text-end">
                                  <p>
                                    {" "}
                                    Published{" "}
                                    {moment(
                                      job.postedAt
                                        ? Number(job.postedAt)
                                        : job.dateOfPosting
                                    ).fromNow()}
                                  </p>
                                </div>
                              </div>
                            </div> 

                            <div className="col-lg-8">
                              <ul className="filter_list_job_post ">
                                <li>
                                  <Link to={`/jobdetailes/${job._id}`}>
                                    <div className="filter_list_job_box filter_list_main">
                                      <div className="d-flex mb-4">
                                        <div className="filter_list_job_company">
                                          <img
                                            src={
                                              job.recruiter?.profileImage
                                                ? job.recruiter.profileImage
                                                : "images/company icon-01.png"
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <div className="filter_list_job_info">
                                          <h4>{job.title}</h4>
                                          <h5 className="home_company_name">
                                            {job.recruiter?.companyname}
                                          </h5>
                                          <ul>
                                            <li>
                                              <i className="fas fa-map-marker-alt"></i>
                                              {job.cities.map(
                                                (job, index, arr) => {
                                                  return (
                                                    <>
                                                      {job}
                                                      {index != arr.length - 1
                                                        ? "/"
                                                        : ""}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </li>
                                            <li>
                                              <i className="far fa-bookmark"></i>
                                              {job.jobType}
                                            </li>
                                            <li>
                                              <i className="far fa-clock"></i>
                                              Published{" "}
                                              {moment(
                                                job.postedAt
                                                  ? Number(job.postedAt)
                                                  : job.dateOfPosting
                                              ).fromNow()}
                                            </li>
                                           
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <div className="filter_list_job_type mr-auto">
                                          <a href="#">
                                            <span>Full Time</span>
                                          </a>
                                        </div>
                                        <div className="filter_list_salary">
                                          <span>
                                            <i className="fas fa-rupee-sign"></i>{" "}
                                            {job.salary}
                                          </span>
                                        </div>
                                      </div>
                                      <label className="wishlist">
                                        {result?.type === "applicant" ? (
                                          <button className="btn job_details_applybtn filter_list_wishlist">
                                            {" "}
                                            Apply{" "}
                                          </button>
                                        ) : result?.type ===
                                          "recruiter" ? null : (
                                          <Link to="/auth">
                                            {" "}
                                            <button className="btn job_details_applybtn filter_list_wishlist">
                                              {" "}
                                              Login to Apply{" "}
                                            </button>{" "}
                                          </Link>
                                        )}
                                      </label>
                                    </div>
                                  </Link>

                                  {result?.type === "applicant" ? (
                                    <>
                                      {job.wishlist ? (
                                        <label
                                          className="job_filter_grid_wishlist"
                                          onClick={() =>
                                            handleRemoveWishlist(job._id)
                                          }
                                          style={{
                                            position: "absolute",
                                            right: 105,
                                            top: 31,
                                          }}
                                        >
                                          <span className="filter_grid_added">
                                            <i
                                              class="fab fa-gratipay"
                                              style={{
                                                fontSize: "28px",
                                                color: "green",
                                              }}
                                            />
                                          </span>
                                        </label>
                                      ) : (
                                        <label
                                          className="job_filter_grid_wishlist"
                                          onClick={() =>
                                            handleAddWishlist(job._id)
                                          }
                                          style={{
                                            position: "absolute",
                                            right: 105,
                                            top: 35,
                                          }}
                                        >
                                          <span className="filter_grid_added">
                                            <i className="fas fa-heart" />
                                          </span>
                                        </label>
                                      )}
                                    </>
                                  ) : null}
                                </li>
                              </ul>
                            </div>







                          </div>
                        </>
                      );
                    } 
    
                    ) */

                    <div className="container" style={{marginTop:"50px"}}> 
                    <div className="row">
                      <div className="col-md-4">
                        {jobs.map((job)=>
                        
                          <div key={job.id} onClick={()=>handleCardClick(job)} className={`card ${selectedJob === job ? 'active' : ' '}`}>
                          <div className="card p-3">
                                  <div className="row">
                                    <div className="col-8">
                                      <h1 className="browse_filter_designation">
                                        {job.title}
                                      </h1>
                                      <p className="browse_filter_company">
                                        {job.recruiter?.companyname}
                                      </p>
                                    </div>
                                    <div className="col-4">
                                      <h1 className="browse_filter_package">
                                        {job.salary}
                                      </h1>
                                    </div>
                                  </div>

                                  <div className="row browse_filter_details">
                                    <p>{job.jobType}</p>
                                    <p>
                                      {" "}
                                      {job.cities.map((job, index, arr) => {
                                        return (
                                          <>
                                            {job}
                                            {index != arr.length - 1 ? "/" : ""}
                                          </>
                                        );
                                      })}
                                    </p>
                                    <p>Experience</p>
                                  </div>
                                  <div className="row text-end">
                                    <p>
                                      {" "}
                                      Published{" "}
                                      {/* <ReactTimeAgo date={job.dateOfPosting} locale="en-US" /> */}
                                      {/* {moment(job.postedAt).fromNow()} */}
                                      {moment(
                                        job.postedAt
                                          ? Number(job.postedAt)
                                          : job.dateOfPosting
                                      ).fromNow()}
                                    </p>  
                                  </div>
                                </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-8">
                        {selectedJob ? (
                          <div className="filter_list_job_box filter_list_main">
                                        <div className="d-flex mb-4">
                                          <div className="filter_list_job_company">
                                            {/* <span><img alt="" src={job.recruiter?.profileImage? `${server}/public/profile/${job.recruiter.profileImage}`: " " }/></span> */}
                                            <img
                                              src={
                                                selectedJob.recruiter?.profileImage
                                                  ? selectedJob.recruiter.profileImage
                                                  : "images/company icon-01.png"
                                              }
                                              alt=""
                                            />
                                          </div>

                                          <div className="filter_list_job_info">
                                            <h4>{selectedJob.title}</h4>
                                            <h5 className="home_company_name">
                                              {selectedJob.recruiter?.companyname}
                                            </h5>
                                            <ul>
                                              <li>
                                                <i className="fas fa-map-marker-alt"></i>
                                                {selectedJob.cities.map(
                                                  (selectedJob, index, arr) => {
                                                    return (
                                                      <>
                                                        {selectedJob}
                                                        {index != arr.length - 1
                                                          ? "/"
                                                          : ""}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </li>
                                              <li>
                                                <i className="far fa-bookmark"></i>
                                                {selectedJob.jobType}
                                              </li>
                                              <li>
                                                <i className="far fa-clock"></i>
                                                Published{" "}
                                                {/* <ReactTimeAgo date={job.dateOfPosting} locale="en-US" /> */}
                                                {/* {moment(job.postedAt).fromNow()} */}
                                                {moment(
                                                  selectedJob.postedAt
                                                    ? Number(selectedJob.postedAt)
                                                    : selectedJob.dateOfPosting
                                                ).fromNow()}
                                              </li>
                                              {/* 1646973039978 */}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <div className="filter_list_job_type mr-auto">
                                            <a href="#">
                                              <span>Full Time</span>
                                            </a>
                                          </div>
                                          <div className="filter_list_salary">
                                            <span>
                                              <i className="fas fa-rupee-sign"></i>{" "}
                                              {selectedJob.salary}
                                            </span>
                                          </div>
                                        </div>
                                        <label className="wishlist">
                                          {result?.type === "applicant" ? (
                                            <button className="btn job_details_applybtn filter_list_wishlist">
                                              {" "}
                                              Apply{" "}
                                            </button>
                                          ) : result?.type ===
                                            "recruiter" ? null : (
                                            <Link to="/auth">
                                              {" "}
                                              <button className="btn job_details_applybtn filter_list_wishlist">
                                                {" "}
                                                Login to Apply{" "}
                                              </button>{" "}
                                            </Link>
                                          )}
                                        </label>

                                        {result?.type === "applicant" ? (
                                      <>
                                        {selectedJob.wishlist ? (
                                          <label
                                            className="job_filter_grid_wishlist"
                                            onClick={() =>
                                              handleRemoveWishlist(selectedJob._id)
                                            }
                                            style={{
                                              position: "absolute",
                                              right: 105,
                                              top: 31,
                                            }}
                                          >
                                            {/* <input type="checkbox" /> */}
                                            <span className="filter_grid_added">
                                              <i
                                                class="fab fa-gratipay"
                                                style={{
                                                  fontSize: "28px",
                                                  color: "green",
                                                }}
                                              />
                                            </span>
                                          </label>
                                        ) : (
                                          <label
                                            className="job_filter_grid_wishlist"
                                            onClick={() =>
                                              handleAddWishlist(selectedJob._id)
                                            }
                                            style={{
                                              position: "absolute",
                                              right: 105,
                                              top: 35,
                                            }}
                                          >
                                            {/* <input type="checkbox" /> */}
                                            <span className="filter_grid_added">
                                              <i className="fas fa-heart" />
                                            </span>
                                          </label>
                                        )}
                                      </>
                                    ) : null}
                                      </div>
                        ):(<>

                          <div className="filter_list_job_box filter_list_main">
                                        <div className="d-flex mb-4">
                                          <div className="filter_list_job_company">
                                            {/* <span><img alt="" src={job.recruiter?.profileImage? `${server}/public/profile/${job.recruiter.profileImage}`: " " }/></span> */}
                                            <img
                                              src={
                                                jobs[0].recruiter?.profileImage
                                                  ? jobs[0].recruiter.profileImage
                                                  : "images/company icon-01.png"
                                              }
                                              alt=""
                                            />
                                          </div>

                                          <div className="filter_list_job_info">
                                            <h4>{jobs[0].title}</h4>
                                            <h5 className="home_company_name">
                                              {jobs[0].recruiter?.companyname}
                                            </h5>
                                            <ul>
                                              <li>
                                                <i className="fas fa-map-marker-alt"></i>
                                                {jobs[0].cities.map(
                                                  (selectedJob, index, arr) => {
                                                    return (
                                                      <>
                                                        {selectedJob}
                                                        {index != arr.length - 1
                                                          ? "/"
                                                          : ""}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </li>
                                              <li>
                                                <i className="far fa-bookmark"></i>
                                                {jobs[0].jobType}
                                              </li>
                                              <li>
                                                <i className="far fa-clock"></i>
                                                Published{" "}
                                                {/* <ReactTimeAgo date={job.dateOfPosting} locale="en-US" /> */}
                                                {/* {moment(job.postedAt).fromNow()} */}
                                                {moment(
                                                  jobs[0].postedAt
                                                    ? Number(jobs[0].postedAt)
                                                    : jobs[0].dateOfPosting
                                                ).fromNow()}
                                              </li>
                                              {/* 1646973039978 */}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <div className="filter_list_job_type mr-auto">
                                            <a href="#">
                                              <span>Full Time</span>
                                            </a>
                                          </div>
                                          <div className="filter_list_salary">
                                            <span>
                                              <i className="fas fa-rupee-sign"></i>{" "}
                                              {jobs[0].salary}
                                            </span>
                                          </div>
                                        </div>
                                        <label className="wishlist">
                                          {result?.type === "applicant" ? (
                                            <button className="btn job_details_applybtn filter_list_wishlist">
                                              {" "}
                                              Apply{" "}
                                            </button>
                                          ) : result?.type ===
                                            "recruiter" ? null : (
                                            <Link to="/auth">
                                              {" "}
                                              <button className="btn job_details_applybtn filter_list_wishlist">
                                                {" "}
                                                Login to Apply{" "}
                                              </button>{" "}
                                            </Link>
                                          )}
                                        </label>

                                        {result?.type === "applicant" ? (
                                      <>
                                        {jobs[0].wishlist ? (
                                          <label
                                            className="job_filter_grid_wishlist"
                                            onClick={() =>
                                              handleRemoveWishlist(jobs[0]._id)
                                            }
                                            style={{
                                              position: "absolute",
                                              right: 105,
                                              top: 31,
                                            }}
                                          >
                                            {/* <input type="checkbox" /> */}
                                            <span className="filter_grid_added">
                                              <i
                                                class="fab fa-gratipay"
                                                style={{
                                                  fontSize: "28px",
                                                  color: "green",
                                                }}
                                              />
                                            </span>
                                          </label>
                                        ) : (
                                          <label
                                            className="job_filter_grid_wishlist"
                                            onClick={() =>
                                              handleAddWishlist(jobs[0]._id)
                                            }
                                            style={{
                                              position: "absolute",
                                              right: 105,
                                              top: 35,
                                            }}
                                          >
                                            {/* <input type="checkbox" /> */}
                                            <span className="filter_grid_added">
                                              <i className="fas fa-heart" />
                                            </span>
                                          </label>
                                        )}
                                      </>
                                    ) : null}
                                      </div>

                          
                        </>)}
                      </div>
                    </div>
                    </div>
                    
                  ) : (
                    <>
                      {isLoading ? (
                        <div className="skeleton">
                          {list.map((item) => {
                            return (
                              <div className="contact__item mb-5" key={item}>
                                <ul className="job-post">
                                  <li>
                                    <SkeletonTheme
                                      color="#f3f3f3"
                                      highlightColor="#ecebeb"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        <Skeleton
                                          circle={false}
                                          height={50}
                                          width={50}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Skeleton
                                            height={12}
                                            width="30%"
                                            style={{
                                              marginLeft: "1rem",
                                              marginBottom: "0.5rem",
                                            }}
                                          />
                                          <Skeleton
                                            height={8}
                                            width="40%"
                                            style={{ marginLeft: "1rem" }}
                                          />
                                          <Skeleton
                                            height={8}
                                            width="50%"
                                            style={{
                                              marginLeft: "1rem",
                                              marginTop: 0,
                                            }}
                                          />
                                          <Skeleton
                                            height={12}
                                            width="80%"
                                            style={{
                                              marginLeft: "1rem",
                                              marginTop: "0.6rem",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </SkeletonTheme>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div style={{ textAlign: "-webkit-center" }}>
                          <div>
                            <img
                              src="images/NO JOBS FOUND-01-01.png"
                              className="no_jobs_found_img"
                            />
                          </div>
                          <p>
                            There is no jobs to list with the current filter
                          </p>
                          <button
                            className={`btn list_view mb-2 `}
                            onClick={resetFilter}
                          >
                            Reset Filter
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div class="row" id="job_filter_grid">
                  {jobs.length > 0 ? (
                    jobs?.map((job) => {
                      return (
                        <>
                          <div className="col-lg-6 col-sm-12">
                            <Link to={`/jobdetailes/${job._id}`}>
                              <div className="job_filter_grid_box position-relative">
                                <div className="d-flex mb-4">
                                  <div className="job_filter_grid_info">
                                    <div className="job_heading ">
                                      <h5>{job.title}</h5>
                                    </div>
                                    <div className="apply_grid_box">
                                      <label className="wishlist ">
                                        {result?.type === "applicant" ? (
                                          <button className="btn job_details_applybtn_grid apply_job_grid_new">
                                            {" "}
                                            Apply
                                          </button>
                                        ) : result?.type ===
                                          "recruiter" ? null : (
                                          <Link to="/auth">
                                            <div className="login_apply">
                                              {" "}
                                              <button className="btn job_details_applybtn_grid ">
                                                {" "}
                                                Login to Apply{" "}
                                              </button>{" "}
                                            </div>{" "}
                                          </Link>
                                        )}
                                      </label>
                                    </div>
                                    <div className="job_filter_grid_in_up">
                                      <i className="fas fa-map-marker-alt" />
                                      <span> Hyderabad</span>
                                      <i className="far fa-bookmark" />
                                      <span> {job.jobType}</span>
                                      <div className="job_filter_grid_in_down">
                                        <i className="far fa-clock" />{" "}
                                      </div>
                                    </div>
                                    <p />
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="job_filter_grid_type mr-auto mb-3">
                                    <span>Full Time</span>
                                  </div>
                                  <div className="job_filter_grid_salary">
                                    <span>
                                      <i className="fas fa-rupee-sign" />{" "}
                                      {job.salary}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {isLoading ? (
                        <div style={{ margin: "auto" }}>
                          <ReactLoading
                            type="balls"
                            color={"#270D44"}
                            height={150}
                            width={150}
                          />
                        </div>
                      ) : (
                        <div style={{ margin: "auto" }}>
                          <p>
                            There is no jobs to list with the current filter
                          </p>
                          <div className="text-center">
                            <button
                              className={`btn list_view mb-2 `}
                              onClick={resetFilter}
                            >
                              Reset Filter
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BrowseFilterList11;
