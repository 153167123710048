import React from 'react'
import { Link } from 'react-router-dom'

const Syshome = () => {
    return (
        <div>
            <div className='container' style={{ marginTop: "200px" }}>
                <div className='card p-5 mb-3'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <video width="100%" height="100%" controls>
                                <source src="images/Pab Jobs introduction.mp4" />
                            </video>
                        </div>

                        <div className='col-md-6'>
                            <p>
                                What if you could use your skills and talents to earn money so that you could live the life you love? A difficult situation for many people and a very alluring question.
                            </p>
                            <p>
                                Although many people aspire to this, for those in the traditional job market, working with what you truly enjoy while also having flexibility and independence over when and where to perform this work seems like a faraway dream.
                            </p>
                            <p>
                                The good news is that we can assure you that this kind of action is more feasible than you might think because we are here to tell you. More than that, we wish to assist you in discovering a means of generating income from your abilities and talents.
                            </p>
                            <p>
                                We offer a clear path for development and enjoy internal promotion. Your efforts will be given the credit they merit.
                            </p>

                        </div>


                    </div>
                    <Link to="/sellskill"> <div className='sys_home_register'>
                        Register
                    </div></Link>
                </div>
            </div>
        </div>
    )
}

export default Syshome
