import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiList from "../lib/apiList";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import moment from "moment";

const AppliedJobs = () => {
  const [applications, setApplications] = useState([]);

  // Pagination code
  const [offset, setOffset] = useState(1);
  //   const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const indexOfLastPost = offset * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentPosts = applications.slice(indexOfFirstPost, indexOfLastPost);
  
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(apiList.applications, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPageCount(Math.ceil(response.data.length) / perPage);
        console.log(response.data);
        setApplications(response.data);
      })
      .catch((err) => {
        // history.push("/login")
        // console.log(err.response);
        console.log(err.response.data);
      });
  };

  return (
    <div  style={{ backgroundColor: "#f8f8f8" }}>
    <div
      className="container main_content my-5"
     
    >
      <div className="row" style={{marginTop:"100px"}}>
        <div className="col-lg-4 mt-4">
          <Sidebar />
        </div>
        <div className="col-lg-8" style={{ marginTop: "27px" }}>
          <div className="wrapper">
            <div className="">
              <div className=" ">
                <h5 className=" pull-left  cp">
                  {applications.length} Applied jobs found
                </h5>
              </div>

              {applications.length > 0 ? (
                currentPosts.map((application) => (
                  <Link to={`/jobdetailes/${application.job._id}`}>
                    {/* <div className="card applied_jobs">
                      <div className="container-fluid">
                        <div className="row apply_first_row">
                          <div
                            className="col-md-6"
                            style={{ borderRight: "1px solid #ada6a680" }}
                          >
                            <div className="d-flex">
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png"
                                className="img-fluid"
                                style={{ width: "20%" }}
                              />
                              <div className="m-auto">
                                <h1 className="apply_role">
                                  {application.job.title
                                    .charAt(0)
                                    .toUpperCase() +
                                    application.job.title.slice(1)}
                                </h1>
                                <p className="apply_companyname">
                                  {application.recruiter?.companyname}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 m-auto mt-2">
                            <h1 className="apply_package">
                              <i
                                class="fa-sharp fa-solid fa-indian-rupee-sign mr-3"
                                style={{ visibility: "hidden" }}
                              ></i>
                              {application.job.salary}
                            </h1>
                            <p className="apply_location">
                              <i class="fas fa-sharp fa-light fa-location-dot mr-3"></i>{" "}
                              {application.job.cities}
                            </p>
                            <p className="apply_experience">
                              <i class="fas fa-regular fa-briefcase mr-3"></i>
                              {application.job?.experience}
                            </p>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-8 apply_postings">
                                <p>
                                  Posted : <i className="fas fa-history"></i>{" "}
                                  {moment(
                                    application.job?.postedAt
                                      ? Number(application.job?.postedAt)
                                      : application.job?.dateOfPosting
                                  ).fromNow()}
                                </p>
                                <p>
                                  Openings : {application.job?.maxPositions}
                                </p>
                                <p>Job Applicants : 580</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 apply_applied">
                            <p>Applied</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                     <div className="card applied_jobs mb-2" key={application.job._id}>
                          <div className="container-fluid">
                            <div>
                              <h1 className="s_jobs_title">
                                {" "}
                                {application.job.title}
                              </h1>
                              <h4 className="s_jobs_cname">
                                {application.job.companyname}
                              </h4>
                              <div className="s_jobs_icons">
                                <span class="">
                                  <em class="icon">
                                    <i
                                      className="ri-briefcase-line"
                                      style={{ paddingRight: "5px" }}
                                    ></i>
                                  </em>
                                  <span>{application.job.experience}</span>
                                  <span class="separator"></span>
                                </span>
                                <span class="">
                                  <em class="icon">
                                    <i style={{ paddingRight: "5px" }}>₹</i>
                                  </em>
                                  <span> {application.job.salary}</span>
                                  <span class="separator"></span>
                                </span>
                                <span class="">
                                  <em class="icon">
                                    <i
                                      className="ri-map-pin-line"
                                      style={{ paddingRight: "5px" }}
                                    ></i>
                                  </em>
                                  <span>{application.job.cities[0]}</span>
                                </span>
                              </div>
                              {/* <div className="s_jobs_description">
                                <i
                                  class="ri-book-line"
                                  style={{ paddingRight: "5px" }}
                                ></i>
                                <p>{application.job.description}</p>
                              </div> */}
                              <span class="s_jobs_description">
                                  <em class="icon" style={{fontWeight:100}}>
                                  <i
                                  class="ri-book-line"
                                  style={{ paddingRight: "5px" }}
                                ></i>
                                  </em>
                                  <span>{application.job.description}</span>
                                </span>
                              <div>
                                <ul className="job_roles_details">
                                  {application.job.skillsets.map((each) => (
                                    <span className="b_side_card_skills">
                                      {each}
                                    </span>
                                  ))}
                                </ul>
                              </div>

                              <div className="sjobs_posting">
                                <span>
                                  <p>
                                    Posted:
                                    {moment(
                                     application.job.postedAt
                                        ? Number(application.job.postedAt)
                                        : application.job.dateOfPosting
                                    ).fromNow()}
                                  </p>
                                </span>
                                <div>
                                  <em>
                                    <i
                                      className="ri-bookmark-fill"
                                      style={{ paddingRight: "5px" }}
                                    ></i>
                                  </em>
                                  <span>Applied</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  </Link>
                ))
              ) : (
                <>
                  <div className="text-center">
                    <img
                      src="https://img.freepik.com/free-vector/instruction-manual-guide-document-with-cogwheel-isolated-design-element-male-character-analyzing-file-business-analysis-data-processing-updating-concept-illustration_335657-1666.jpg?w=740&t=st=1688814820~exp=1688815420~hmac=a4f763ae2e4fa21d277185d407b0f995a6497bbe1428243af24e2342a9ccaa77"
                      className="img-fluid"
                      style={{ width: "35%" }}
                    />
                  </div>
                  <h1
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "31px",
                    }}
                  >
                    No Applications Found
                  </h1>
                </>
              )}
              <div class="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel="Prev"
                  nextLabel="Next"
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default AppliedJobs;
