import React from 'react'
import { Link } from 'react-router-dom'

const Hrloginform = () => {
  return (
    <div>
        <div>
            <div className='Log_In_ADMIN_Form'>
                <div className='container'>
                    <div className='row'>

                        <div className=" col-lg-12 col-md-12">
                            <div className="form-group hire_group">
                                <label>HR Id:</label>
                                <label
                                    className="input mt-1 mb-2"
                                    style={{
                                        border: "1px solid #270d44",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        className="input__field form_control form_hire"
                                        type="text"
                                        style={{ borderRadius: "5px" }}
                                        name="hrid"
                                        placeholder=" "
                                    />
                                    <span className="input__label">Enter HR id</span>
                                </label>
                            </div>
                        </div>

                        <div className=" col-lg-12 col-md-12">
                            <div className="form-group hire_group">
                                <label>Mail:</label>
                                <label
                                    className="input mt-1 mb-2"
                                    style={{
                                        border: "1px solid #270d44",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        className="input__field form_control form_hire"
                                        type="text"
                                        style={{ borderRadius: "5px" }}
                                        name="Mail"
                                        placeholder=" "
                                    />
                                    <span className="input__label">Enter Mail</span>
                                </label>
                            </div>
                        </div>



                        <div className=" col-lg-12 col-md-12">
                            <div className="form-group hire_group">
                                <label>Password:</label>
                                <label
                                    className="input mt-1 mb-2"
                                    style={{
                                        border: "1px solid #270d44",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <input
                                        className="input__field form_control form_hire"
                                        type="text"
                                        style={{ borderRadius: "5px" }}
                                        name="password"
                                        placeholder=" "
                                    />
                                    <span className="input__label">Enter Your Password</span>
                                </label>
                            </div>
                        </div>




                       
                        <Link to="/viewhrprofile" > <button className="jobfair_btn jobfair_regbtn mt-3">Log In</button></Link>


                    </div>
                </div>
            </div>


        </div>
    </div>
  )
}

export default Hrloginform
