import React, { useState } from 'react'
import CreateAdminProfile from './CreateAdminProfile'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import AdminLoginForm from './AdminLoginForm'
import Updatehrprofile from './Updatehrprofile'
import Createhrprofile from './Createhrprofile'
import Hrloginform from './Hrloginform'

const JobpostingDatabase = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }



    return (
        <div>
            <div className='container'>
                <h4 className='mt-4'><b>Monthly Usages</b></h4>

                <div className='row'>
                    <div className='col-md-3'>
                        <div className='card dashboard_card'>
                            <div className=' d-inline'>
                                <span>Excel Downloads</span>
                                <h3>00</h3>
                                <span>Since last month</span>
                            </div>
                            {/* <div>Hello</div> */}
                        </div>

                    </div>

                    <div className='col-md-3' >
                        <div className='card dashboard_card'>
                            <span>Profile Views</span>
                            <h3>00</h3>
                            <span>Since last month</span>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='card dashboard_card'>
                            <span>SMS</span>
                            <h3>00</h3>
                            <span>Since last month</span>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='card dashboard_card'>
                            <span>Job Postings</span>
                            <h3>100</h3>
                            <span>Since last month</span>
                        </div>
                    </div>
                </div>

                <h4 className='mt-4'><b>Daily Usages</b></h4>

                <div className='row'>
                    <div className='col-md-3'>
                        <div className='card dashboard_card'>
                            <div className=' d-inline'>
                                <span>Excel Downloads</span>
                                <h3>00</h3>
                                <span>Since last month</span>
                            </div>
                            {/* <div>Hello</div> */}
                        </div>

                    </div>

                    <div className='col-md-3' >
                        <div className='card dashboard_card'>
                            <span>Profile Views</span>
                            <h3>00</h3>
                            <span>Since last month</span>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='card dashboard_card'>
                            <span>SMS</span>
                            <h3>00</h3>
                            <span>Since last month</span>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='card dashboard_card'>
                            <span>Job Postings</span>
                            <h3>50</h3>
                            <span>Since last month</span>
                        </div>
                    </div>
                </div>


                <div className='card  mt-5'>
                    <div className='row py-2'>
                        <div className='col-6 m-auto'>
                            <h1 style={{ marginLeft: "20px", marginBottom: '0px' }}>Recruiters</h1>
                        </div>

                        <div className='col-6 dashboard_create_btn text-right  m-auto'>
                            <button
                                data-toggle="modal"
                                data-target="#Create_admin_Profile2">
                                <i className="fas fa-plus-circle pr-2"></i>Create Admin</button>
                            {/* <h1 style={{ marginLeft: "20px", marginTop: "10px" }}>Admins</h1> */}
                        </div>

                    </div>
                </div>




                <div className="table-responsive dashboard_total_table">
                    <table className="table table-hover table-nowrap text-center">


                        <thead className="" style={{ backgroundColor: "#270d44", color: "#ffffff" }}>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Position</th>
                                {/* <th scope="col">Offer</th>
                <th scope="col">Meeting</th> */}
                                <th scope="col">Employee Id</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>


                        <tbody>
                            <tr style={{ backgroundColor: "#ffffff" }}>
                                <td>
                                    <img alt="..." src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" className="avatar avatar-sm rounded-circle me-2 ml-4" />
                                    <a className="text-heading font-semibold" href="#">
                                        HR Name
                                    </a>
                                </td>
                                <td>
                                    oct 5, 2021
                                </td>
                                <td>
                                    <img alt="..." src="images/ProHireeLogo.png" className="avatar avatar-xs rounded-circle me-2" />
                                    <a className="text-heading font-semibold" href="#">
                                        HR Manager
                                    </a>
                                </td>
                                <td>
                                    123456789
                                </td>
                                {/* <td>
                    <span className="text-success">
                        Scheduled
                    </span>
                </td> */}
                                <td className="">
                                    {/* <a href="#" className="btn btn-sm btn-neutral dashboard2_view_btn" data-toggle="modal"
                        data-target="#update_HR_Profile">Edit</a>
                    <Link to="/viewhrprofile" className="btn btn-sm btn-neutral dashboard2_view_btn">View</Link>
                    <button type="button" className="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i class="fas fa-trash"></i>
                    </button> */}
                                    <a href="#" className="btn btn-sm btn-neutral dashboard2_view_btn" onClick={toggleModal}
                                    >Log In</a>
                                </td>
                            </tr>




                        </tbody>


                    </table>
                </div>



                <div className='card  mt-5'>
                    <div className='row py-2'>
                        <div className='col-6 m-auto'>
                            <h1 style={{ marginLeft: "20px", marginBottom: '0px' }}>HR's</h1>
                        </div>

                        <div className='col-6 dashboard_create_btn text-right  m-auto'>
                            {/* <button
                                data-toggle="modal"
                                data-target="#Create_hrProfile">
                                <i className="fas fa-plus-circle pr-2" data-toggle="modal"
                                    data-target="#Create_HR_Profile"></i>Create HR Profile</button> */}
                            <button
                                data-toggle="modal"
                                data-target="#create_HR_Profile2">
                                <i className="fas fa-plus-circle pr-2"></i>Create HR Profile</button>
                            {/* <h1 style={{ marginLeft: "20px", marginTop: "10px" }}>Admins</h1> */}
                        </div>

                    </div>
                </div>


                <div className="table-responsive dashboard_total_table">
                    <table className="table table-hover table-nowrap text-center">


                        <thead className="" style={{ backgroundColor: "#270d44", color: "#ffffff" }}>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Position</th>
                                {/* <th scope="col">Offer</th>
                <th scope="col">Meeting</th> */}
                                <th scope="col">Employee Id</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>


                        <tbody>
                            <tr style={{ backgroundColor: "#ffffff" }}>
                                <td>
                                    <img alt="..." src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" className="avatar avatar-sm rounded-circle me-2 ml-4" />
                                    <a className="text-heading font-semibold" href="#">
                                        HR Name
                                    </a>
                                </td>
                                <td>
                                    oct 5, 2021
                                </td>
                                <td>
                                    <img alt="..." src="images/ProHireeLogo.png" className="avatar avatar-xs rounded-circle me-2" />
                                    <a className="text-heading font-semibold" href="#">
                                        HR Manager
                                    </a>
                                </td>
                                <td>
                                    123456789
                                </td>
                                {/* <td>
                    <span className="text-success">
                        Scheduled
                    </span>
                </td> */}
                                <td className="">
                                    {/* <a href="#" className="btn btn-sm btn-neutral admin_view_btn" data-toggle="modal"
                                        data-target="#update_HR_Profile2">Edit</a>
                                    <Link to="/viewhrprofile" className="btn btn-sm btn-neutral admin_view_btn">View</Link> */}
                                     <a href="#" className="btn btn-sm btn-neutral dashboard2_view_btn" onClick={toggleModal2}
                                    >Log In</a>
                                    {/* <button type="button" className="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i class="fas fa-trash"></i>
                    </button> */}
                                    {/* <a href="#" className="btn btn-sm btn-neutral dashboard2_view_btn" data-toggle="modal"
                        data-target="#update_HR_Profile">Log In</a> */}
                                </td>
                            </tr>




                        </tbody>


                    </table>
                </div>

            </div>


             {/* Create HR Profile Modal */}
             <div
                class="modal fade "
                id="create_HR_Profile2"
                tabindex="-1"
                role="dialog"
                aria-labelledby="create_HR_Profile2"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content search_candidates_modal">
                        <div className="modal-header modal-header--sticky">
                            <button
                                type="button"
                                className="close modal_srcand_close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="modal_content">
                                <Createhrprofile />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/* Create HR Profile Modal */}

            {/* Update HR Profile Modal */}
            <div
                class="modal fade "
                id="update_HR_Profile2"
                tabindex="-1"
                role="dialog"
                aria-labelledby="update_HR_Profile2"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content search_candidates_modal">
                        <div className="modal-header modal-header--sticky">
                            <button
                                type="button"
                                className="close modal_srcand_close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="modal_content">
                                <Updatehrprofile />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/* Update HR Profile Modal */}


            {/* Create Admin Profile Modal */}
            <div
                class="modal fade "
                id="Create_admin_Profile2"
                tabindex="-1"
                role="dialog"
                aria-labelledby="Create_admin_Profile2"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered  modal-dialog-scrollable"
                    role="document"
                >
                    <div class="modal-content search_candidates_modal">
                        <div className="modal-header modal-header--sticky">
                            <button
                                type="button"
                                className="close modal_srcand_close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="modal_content">
                                <CreateAdminProfile />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/* Create Admin Profile Modal */}





            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                // style={{marginTop:"150px"}}
                className="Dashboard_Admin_login_form_modal"
            >

                <div className='position-relative'>
                    <AdminLoginForm />
                    <button className='admin_logIn_modal_close' onClick={toggleModal}>x</button>
                </div>

            </Modal>

            <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModal2}
                contentLabel="My dialog"
                // style={{marginTop:"150px"}}
                className="Dashboard_Admin_login_form_modal"
            >

                <div className='position-relative'>
                    <Hrloginform/>
                    <button className='admin_logIn_modal_close' onClick={toggleModal2}>x</button>
                </div>

            </Modal>


        </div>
    )
}

export default JobpostingDatabase
