 // export const server = "http://localhost:4444";

export const server = "https://pab-updated.onrender.com";

const apiList = {
  login: `${server}/auth/login`,
  signup: `${server}/auth/signup`,
  sendOTP: `${server}/auth/mobile/send-otp`,
  verifyOTP: `${server}/auth/mobile/verify-otp`,
  contactSendOTP: `${server}/auth/contact-verification/send-otp`,
  contactVerifyOTP: `${server}/auth/contact-verification/verify-otp`,
  forgotPassword: `${server}/auth/forgot-password`,
  mobilelogin: `${server}/auth/mobilelogin`,
  uploadResume: `${server}/upload/resume`,
  uploadProfileImage: `${server}/upload/profile`,
  jobs: `${server}/api/jobs`,
  sys: `${server}/api/sys`,
  dayjob: `${server}/api/dayjob`,
  applications: `${server}/api/applications`,
  rating: `${server}/api/rating`,
  user: `${server}/api/user`,
  applicants: `${server}/api/applicants`,
  sendotp: `${server}/api/sendotp`,
  alljobs: `${server}/api/alljobs`,
  jobSearch: `${server}/api/jobs/search`,
  listFiveCompanies: `${server}/api/company/list/filter`,
  listCompanies: `${server}/api/company/list`,
  listSkills: `${server}/api/skills/list`,
  searchFilterList: `${server}/api/search/list`,
  changepassword: `${server}/api/changepassword`,
  resumeUpload: `${server}/api/resume/upload`,
  profileUpload: `${server}/api/profile/upload`,
  wishlist: `${server}/api/wishlist/`,
  jobAlerts: `${server}/api/job-alerts/`,
  paytmpayment: `${server}/paytm/payment`,
  allapplicants: `${server}/api/allapplicants`,
  searchApplicats: `${server}/api/allapplicants/search`,
  notification: `${server}/api/notification`,
  multiCategory: `${server}/api/role/list/filter`,
  sendEmailOtp: `${server}/auth/email/send-otp`,
  verifyEmailOtp: `${server}/auth/email/verify-otp`,
  sendPhoneOtp: `${server}/auth/mobile/contact/send-otp`,
  verifyPhoneOtp: `${server}/auth/mobile/contact/verify-otp`,
  byDate: `${server}/api/jobapplicants/list/Bydates`,
  driveForm: `${server}/auth/drive-register`
};

export default apiList;
