const Education = [
  {
    name: "SSC",
    id: 1,
    value: false,
    childs: [
      {
        name: "10th",
        id: 1,
        value: false,
      },
    ],
  },
  {
    name: "Intermediate",
    id: 2,
    value: false,
    childs: [
      {
        name: "MPC",
        id: 1,
        value: false,
      },
      {
        name: "MEC",
        id: 2,
        value: false,
      },
      {
        name: "BPC",
        id: 3,
        value: false,
      },
      {
        name: "CEC",
        id: 4,
        value: false,
      },
      {
        name: "HEC",
        id: 5,
        value: false,
      },
      {
        name: "ECO",
        id: 6,
        value: false,
      },
      {
        name: "ECML",
        id: 7,
        value: false,
      },
      {
        name: "GHML",
        id: 8,
        value: false,
      },
      {
        name: "HIS CIV",
        id: 9,
        value: false,
      },
      {
        name: "HIS ML",
        id: 10,
        value: false,
      },
      {
        name: "CIV SOC",
        id: 11,
        value: false,
      },
      {
        name: "PSY ECO",
        id: 12,
        value: false,
      },
      {
        name: "CO CIV",
        id: 13,
        value: false,
      },
      {
        name: "ME LO",
        id: 14,
        value: false,
      },
      {
        name: "GO PHY CHE",
        id: 15,
        value: false,
      },
    ],
  },

  {
    name: "Diploma",
    id: 3,
    value: false,
    childs: [
      {
        name: "Computer Science",
        id: 1,
        value: false,
      },
      {
        name: "Civil",
        id: 2,
        value: false,
      },
      {
        name: "Automobile",
        id: 3,
        value: false,
      },
      {
        name: "Electronics and Communication",
        id: 4,
        value: false,
      },
      {
        name: "Electrical",
        id: 5,
        value: false,
      },
      {
        name: "Interior Dectoration",
        id: 6,
        value: false,
      },
      {
        name: "Ceramic",
        id: 7,
        value: false,
      },
      {
        name: "Art and Craft",
        id: 8,
        value: false,
      },
      {
        name: "Mechanical",
        id: 9,
        value: false,
      },
      {
        name: "Chemical",
        id: 10,
        value: false,
      },
      {
        name: "Instrumentation and Control",
        id: 11,
        value: false,
      },
      {
        name: "IT",
        id: 12,
        value: false,
      },
      {
        name: "Electronics and Telecommunication",
        id: 13,
        value: false,
      },
      {
        name: "Aeronautical",
        id: 14,
        value: false,
      },
      {
        name: "Petroleum",
        id: 15,
        value: false,
      },
      {
        name: "Aerospace",
        id: 16,
        value: false,
      },
      {
        name: "Mining",
        id: 17,
        value: false,
      },
      {
        name: "Automobile",
        id: 18,
        value: false,
      },
      {
        name: "Genetic",
        id: 19,
        value: false,
      },
      {
        name: "Biotechnology",
        id: 20,
        value: false,
      },
      {
        name: "Plastics",
        id: 21,
        value: false,
      },
      {
        name: "Agricultural",
        id: 22,
        value: false,
      },
      {
        name: "Food Processing",
        id: 23,
        value: false,
      },
      {
        name: "Dairy Technology",
        id: 24,
        value: false,
      },
      {
        name: "Power",
        id: 25,
        value: false,
      },
      {
        name: "Infrastructure",
        id: 26,
        value: false,
      },
      {
        name: "Production",
        id: 27,
        value: false,
      },
      {
        name: "Metallurgy",
        id: 28,
        value: false,
      },
      {
        name: "Motorsport",
        id: 29,
        value: false,
      },
      {
        name: "Environmental",
        id: 30,
        value: false,
      },
      {
        name: "Textile",
        id: 31,
        value: false,
      },
    ],
  },

  {
    name: "Graduate",
    id: 4,
    value: false,
    childs: [
      {
        name: "Other",
        id: 1,
        value: false,
      },
      {
        name: "BA",
        id: 2,
        value: false,
        subchilds: [
          {
            name: "Philosophy",
            id: 1,
            isSelected: false,
          },
          {
            name: "Economics",
            id: 2,
            isSelected: false,
          },
          {
            name: "Political Science",
            id: 3,
            isSelected: false,
          },
          {
            name: "Geography",
            id: 4,
            isSelected: false,
          },
          {
            name: "Sociology",
            id: 5,
            isSelected: false,
          },
          {
            name: "Literature",
            id: 6,
            isSelected: false,
          },
          {
            name: "History",
            id: 7,
            isSelected: false,
          },
          {
            name: "Psychology",
            id: 8,
            isSelected: false,
          },
          {
            name: "Anthropology",
            id: 9,
            isSelected: false,
          },
          {
            name: "Library Science",
            id: 10,
            isSelected: false,
          },
          {
            name: "Archaeology",
            id: 11,
            isSelected: false,
          },
          {
            name: "Education",
            id: 12,
            isSelected: false,
          },
        ],
      },

      {
        name: "B.Arch",
        id: 3,
        value: false,
        subchilds: [
          {
            name: "Architectural Design",
            id: 1,
            isSelected: false,
          },
          {
            name: "Art and Design",
            id: 2,
            isSelected: false,
          },
          {
            name: "Building Information Modeling",
            id: 3,
            isSelected: false,
          },
          {
            name: "Building Technology",
            id: 4,
            isSelected: false,
          },
          {
            name: "Community Design",
            id: 5,
            isSelected: false,
          },
          {
            name: "Computer-aided Design",
            id: 6,
            isSelected: false,
          },
          {
            name: "Engineering ",
            id: 7,
            isSelected: false,
          },
          {
            name: "Environment/sustainability",
            id: 8,
            isSelected: false,
          },
          {
            name: "Graphic Design ",
            id: 9,
            isSelected: false,
          },
          {
            name: "Housing",
            id: 10,
            isSelected: false,
          }
        ]
      },

      {
        name: "BCA",
        id: 4,
        value: false,
        subchilds: [
          {
            name: "Animation",
            id: 1,
            isSelected: false,
          },
          {
            name: "Accounting Applications",
            id: 2,
            isSelected: false,
          },
          {
            name: "Systems Analysis",
            id: 3,
            isSelected: false,
          },
          {
            name: "Personal Information Management",
            id: 4,
            isSelected: false,
          },
          {
            name: "Programming Languages",
            id: 5,
            isSelected: false,
          },
          {
            name: "Internet Technologies",
            id: 6,
            isSelected: false,
          },
          {
            name: "Computer Graphics",
            id: 7,
            isSelected: false,
          },
          {
            name: "Music and Video Processing",
            id: 8,
            isSelected: false,
          },
          {
            name: "Cyber Law",
            id: 9,
            isSelected: false,
          }
        ]
      },

      {
        name: "BBA",
        id: 5,
        value: false,
        subchilds: [
          {
            name: "Marketing",
            id: 1,
            isSelected: false,
          },
          {
            name: "Human Resource Management",
            id: 2,
            isSelected: false,
          },
          {
            name: "Finance",
            id: 3,
            isSelected: false,
          },
          {
            name: "International Business",
            id: 4,
            isSelected: false,
          },
          {
            name: "Computer Applications",
            id: 5,
            isSelected: false,
          },
          {
            name: "Digital Marketing",
            id: 6,
            isSelected: false,
          },
          {
            name: "Retail Management",
            id: 7,
            isSelected: false,
          },
          {
            name: "Entrepreneurship",
            id: 8,
            isSelected: false,
          },
          {
            name: "Logistics Management",
            id: 9,
            isSelected: false,
          }
        ]
      },

      {
        name: "B.Com",
        id: 6,
        value: false,
        subchilds: [
          {
            name: "Accounts and Finance",
            id: 1,
            isSelected: false,
          },
          {
            name: "Investment Management",
            id: 2,
            isSelected: false,
          },
          {
            name: "Financial Market",
            id: 3,
            isSelected: false,
          },
          {
            name: "Statistics",
            id: 4,
            isSelected: false,
          },
          {
            name: "Tourism and Travel Management",
            id: 5,
            isSelected: false,
          },
          {
            name: "Banking and Finance",
            id: 6,
            isSelected: false,
          },
          {
            name: "Banking and Insurance",
            id: 7,
            isSelected: false,
          },
          {
            name: "E-Commerce",
            id: 8,
            isSelected: false,
          },
          {
            name: "Economics",
            id: 9,
            isSelected: false,
          },
          {
            name: "Banking and Insurance",
            id: 10,
            isSelected: false,
          },
          {
            name: "Computer Applications",
            id: 11,
            isSelected: false,
          },
          {
            name: "Information Technology",
            id: 12,
            isSelected: false,
          },
          {
            name: "International Business",
            id: 13,
            isSelected: false,
          },
          {
            name: "Accountancy",
            id: 14,
            isSelected: false,
          },
          {
            name: "Business Economics",
            id: 15,
            isSelected: false,
          },
          {
            name: "Foreign Trade Management",
            id: 16,
            isSelected: false,
          }
        ]
      },

      {
        name: "BEd",
        id: 7,
        value: false,
        subchilds: [
          {
            name: "Accounts",
            id: 1,
            isSelected: false,
          },
          {
            name: "Arabic",
            id: 2,
            isSelected: false,
          },
          {
            name: "Biological Sciences",
            id: 3,
            isSelected: false,
          },
          {
            name: "Business Studies",
            id: 4,
            isSelected: false,
          },
          {
            name: "Child Development",
            id: 5,
            isSelected: false,
          },
          {
            name: "Commerce",
            id: 6,
            isSelected: false,
          },
          {
            name: "Computer Science",
            id: 7,
            isSelected: false,
          },
          {
            name: "Economics",
            id: 8,
            isSelected: false,
          },
          {
            name: "Education of Handicapped",
            id: 9,
            isSelected: false,
          },
          {
            name: "English",
            id: 10,
            isSelected: false,
          },
          {
            name: "Geography",
            id: 11,
            isSelected: false,
          },
          {
            name: "Home Science",
            id: 12,
            isSelected: false,
          },
          {
            name: "Information Technology",
            id: 13,
            isSelected: false,
          },
          {
            name: "Malayalam",
            id: 14,
            isSelected: false,
          },
          {
            name: "Maths",
            id: 15,
            isSelected: false,
          },
          {
            name: "Natural Science",
            id: 16,
            isSelected: false,
          },
          {
            name: "Physical Science",
            id: 17,
            isSelected: false,
          },
          {
            name: "Sanskrit",
            id: 18,
            isSelected: false,
          },
          {
            name: "Social Science",
            id: 19,
            isSelected: false,
          },
          {
            name: "Special Education – Locomotor and Neurological Disorder",
            id: 20,
            isSelected: false,
          },
          {
            name: "Special Education",
            id: 21,
            isSelected: false,
          },
          {
            name: "Special Education (Learning Disability)",
            id: 22,
            isSelected: false,
          },
          {
            name: "Special Education (Mental Retardation)",
            id: 23,
            isSelected: false,
          },
          {
            name: "Special Education (Visually Impaired)",
            id: 24,
            isSelected: false,
          },
          {
            name: "Tamil",
            id: 25,
            isSelected: false,
          }
        ]
      },

      {
        name: "BDS",
        id: 8,
        value: false,
        subchilds: [
          {
            name: "Oral Medicine, Diagnosis, and Radiology",
            id: 1,
            isSelected: false,
          },
          {
            name: "Oral and Maxillofacial Surgery",
            id: 2,
            isSelected: false,
          },
          {
            name: "Oral Pathology and Microbiology",
            id: 3,
            isSelected: false,
          },
          {
            name: "Conservative Dentistry and Endodontics",
            id: 4,
            isSelected: false,
          },
          {
            name: "Orthodontics",
            id: 5,
            isSelected: false,
          },
          {
            name: "Prosthodontics",
            id: 6,
            isSelected: false,
          },
          {
            name: "Periodontal Surgery and Oral Implantology",
            id: 7,
            isSelected: false,
          },
          {
            name: "Pedodontics",
            id: 8,
            isSelected: false,
          },
          {
            name: "Public Health Dentistry",
            id: 9,
            isSelected: false,
          }
        ]
      },

      {
        name: "BHM",
        id: 9,
        value: false,
      },

      {
        name: "B.Pharma",
        id: 10,
        value: false,
        subchilds: [
          {
            name: "Pharmaceutical Chemistry",
            id: 1,
            isSelected: false,
          },
          {
            name: "Pharmaceutical Technology",
            id: 2,
            isSelected: false,
          },
          {
            name: "Pharmaceutical Analysis and Quality Assurance",
            id: 3,
            isSelected: false,
          },
          {
            name: "Clinical Pharmacy",
            id: 4,
            isSelected: false,
          },
          {
            name: "Pharmaceutics",
            id: 5,
            isSelected: false,
          },
          {
            name: "Pharmacy Practice",
            id: 6,
            isSelected: false,
          },
          {
            name: "Pharmacognosy",
            id: 7,
            isSelected: false,
          },
          {
            name: "Pharmacology",
            id: 8,
            isSelected: false,
          },
          {
            name: "Pharmaceutical Marketing Management",
            id: 9,
            isSelected: false,
          },
          {
            name: "Quality Assurance",
            id: 10,
            isSelected: false,
          },
          {
            name: "Phytopharmaceuticals and Natural products",
            id: 11,
            isSelected: false,
          },
          {
            name: "Drug Discovery and Development",
            id: 12,
            isSelected: false,
          },
          {
            name: "Medicinal Chemistry",
            id: 13,
            isSelected: false,
          },
          {
            name: "Bulk Drugs",
            id: 14,
            isSelected: false,
          },
          {
            name: "Drug Regulatory Affairs",
            id: 15,
            isSelected: false,
          },
          {
            name: "Biopharmaceutics",
            id: 16,
            isSelected: false,
          },
          {
            name: "Industrial Pharmacy",
            id: 17,
            isSelected: false,
          }
        ]
      },

      {
        name: "BSc",
        id: 11,
        value: false,
        subchilds: [
          {
            name: "Agriculture",
            id: 1,
            isSelected: false,
          },
          {
            name: "Animation",
            id: 2,
            isSelected: false,
          },
          {
            name: "Aquaculture",
            id: 3,
            isSelected: false,
          },
          {
            name: "Aviation",
            id: 4,
            isSelected: false,
          },
          {
            name: "Biochemistry",
            id: 5,
            isSelected: false,
          },
          {
            name: "Computer Science",
            id: 6,
            isSelected: false,
          },
          {
            name: "Dietetics",
            id: 7,
            isSelected: false,
          },
          {
            name: "Electronic",
            id: 8,
            isSelected: false,
          },
          {
            name: "Fashion Technology",
            id: 9,
            isSelected: false,
          },
          {
            name: "Food Technology",
            id: 10,
            isSelected: false,
          },
          {
            name: "Forensic Science",
            id: 11,
            isSelected: false,
          },
          {
            name: "Forestry",
            id: 12,
            isSelected: false,
          },
          {
            name: "Medical Technology",
            id: 13,
            isSelected: false,
          },
          {
            name: "Microbiology",
            id: 14,
            isSelected: false,
          },
          {
            name: "Multimedia",
            id: 15,
            isSelected: false,
          },
          {
            name: "Nautical Science",
            id: 16,
            isSelected: false,
          },
          {
            name: "Nursing",
            id: 17,
            isSelected: false,
          },
          {
            name: "Nutrition",
            id: 18,
            isSelected: false,
          },
          {
            name: "Physiotherapy",
            id: 19,
            isSelected: false,
          },
          {
            name: "Psychology",
            id: 20,
            isSelected: false,
          },
          {
            name: "Genetics",
            id: 21,
            isSelected: false,
          },
          {
            name: "Information Technology",
            id: 22,
            isSelected: false,
          },
          {
            name: "Interior Design",
            id: 23,
            isSelected: false,
          },
          {
            name: "PCM",
            id: 24,
            isSelected: false,
          },
          {
            name: "Physics",
            id: 25,
            isSelected: false,
          },
          {
            name: "Chemistry",
            id: 26,
            isSelected: false,
          },
          {
            name: "Maths",
            id: 27,
            isSelected: false,
          },
          {
            name: "Zoology",
            id: 28,
            isSelected: false,
          },
          {
            name: "Statistics",
            id: 29,
            isSelected: false,
          },
          {
            name: "Botany",
            id: 30,
            isSelected: false,
          },
          {
            name: "Home Science",
            id: 31,
            isSelected: false,
          },
        ],
      },

      {
        name: "Btech/BE",
        id: 12,
        value: false,
        subchilds: [
          {
            name: "Mechanical Engineering",
            id: 1,
            isSelected: false,
          },
          {
            name: "Civil Engineering",
            id: 2,
            isSelected: false,
          },
          {
            name: "Electronics and Communication Engineering",
            id: 3,
            isSelected: false,
          },
          {
            name: "Computer Science and Engineering",
            id: 4,
            isSelected: false,
          },
          {
            name: "Electrical Engineering",
            id: 5,
            isSelected: false,
          },
          {
            name: "Aeronautical Engineering",
            id: 6,
            isSelected: false,
          },
          {
            name: "Biotechnology Engineering",
            id: 7,
            isSelected: false,
          },
          {
            name: "Automobile Engineering",
            id: 8,
            isSelected: false,
          },
        ],
      },
      {
        name: "LLB",
        id: 13,
        value: false,
        subchilds: [
          {
            name: "International Law",
            id: 1,
            isSelected: false,
          },
          {
            name: "Business Law",
            id: 2,
            isSelected: false,
          },
          {
            name: "European Law",
            id: 3,
            isSelected: false,
          },
          {
            name: "Criminal Law",
            id: 4,
            isSelected: false,
          },
          {
            name: "Legal Studies",
            id: 5,
            isSelected: false,
          },
          {
            name: "Patent & Intellectual Property Law",
            id: 6,
            isSelected: false,
          },
          {
            name: "Civil & Private Law",
            id: 7,
            isSelected: false,
          },
          {
            name: "Public Law",
            id: 8,
            isSelected: false,
          },
        ],
      },
      {
        name: "MBBS",
        id: 14,
        value: false,
      },
      {
        name: "BVSC",
        id: 15,
        value: false,
      },
      {
        name: "B.PT(physiotherapy)",
        id: 16,
        value: false,
      },
    ],
  },

  {
    name: "Post Graduate",
    id: 5,
    value: false,
    childs: [
      {
        name: "Master of Arts (MA)",
        id: 1,
        value: false,
        subchilds: [
          {
            name: "MA English",
            id: 1,
            isSelected: false,
          },
          {
            name: "MA French",
            id: 2,
            isSelected: false,
          },
          {
            name: "MA German",
            id: 3,
            isSelected: false,
          },
          {
            name: "MA Chinese / Mandarin",
            id: 4,
            isSelected: false,
          },
          {
            name: "MA Economics",
            id: 5,
            isSelected: false,
          },
          {
            name: "MA Econometrics",
            id: 6,
            isSelected: false,
          },
          {
            name: "MA Political Science",
            id: 7,
            isSelected: false,
          },
          {
            name: "MA Public Administration",
            id: 8,
            isSelected: false,
          },
          {
            name: "MA International Relations",
            id: 9,
            isSelected: false,
          },
          {
            name: "MA Politics",
            id: 10,
            isSelected: false,
          },
          {
            name: "MA Public Policy",
            id: 11,
            isSelected: false,
          },
          {
            name: "MA History",
            id: 12,
            isSelected: false,
          },
          {
            name: "MA Sociology",
            id: 13,
            isSelected: false,
          },
          {
            name: "MA Philosophy",
            id: 14,
            isSelected: false,
          },
          {
            name: "MA in English and Comparative Literature",
            id: 15,
            isSelected: false,
          },
          {
            name: "MA in Comparative Literature and Translation Studies",
            id: 16,
            isSelected: false,
          },
          {
            name: "MA Anthropology",
            id: 17,
            isSelected: false,
          },
          {
            name: "MA Social Work",
            id: 18,
            isSelected: false,
          },
          {
            name: "MA in Social Work in Community Organization and Development Practice",
            id: 19,
            isSelected: false,
          },
          {
            name: "MA Linguistics",
            id: 20,
            isSelected: false,
          },
          {
            name: "MA Archaeology",
            id: 21,
            isSelected: false,
          },
          {
            name: "MA Rural Development",
            id: 22,
            isSelected: false,
          },
          {
            name: "MA Rural Studies",
            id: 23,
            isSelected: false,
          }
        ]
      },
      {
        name: "Master of Science (MSc)",
        id: 2,
        value: false,
        subchilds: [
          {
            name: " Actuarial Science",
            id: 1,
            isSelected: false,
          },
          {
            name: "Advanced Biochemistry",
            id: 2,
            isSelected: false,
          },
          {
            name: "Advertising Management & Public Relations",
            id: 3,
            isSelected: false,
          },
          {
            name: "Agricultural Biotechnology",
            id: 4,
            isSelected: false,
          },
          {
            name: "Agricultural Economics and Business Management",
            id: 5,
            isSelected: false,
          },
          {
            name: "Agricultural Economics",
            id: 6,
            isSelected: false,
          },
          {
            name: "Agricultural Engineering",
            id: 7,
            isSelected: false,
          },
          {
            name: "Agricultural Extension Education",
            id: 8,
            isSelected: false,
          },
          {
            name: "Agricultural Microbiology",
            id: 9,
            isSelected: false,
          },
          {
            name: "Agricultural Physics",
            id: 10,
            isSelected: false,
          },
          {
            name: "Agricultural Statistics",
            id: 11,
            isSelected: false,
          },
          {
            name: "Agricultural Botany",
            id: 12,
            isSelected: false,
          },
          {
            name: "Agriculture Chemistry & Soil Science",
            id: 13,
            isSelected: false,
          },
          {
            name: " Agro-meteorology",
            id: 14,
            isSelected: false,
          },
          {
            name: "Agroforestry",
            id: 15,
            isSelected: false,
          },
          {
            name: "Agronomy",
            id: 16,
            isSelected: false,
          },
          {
            name: "Airlines, Tourism & Hospitality Management",
            id: 17,
            isSelected: false,
          },
          {
            name: "Anatomy",
            id: 18,
            isSelected: false,
          },
          {
            name: " Animal Biotechnology",
            id: 19,
            isSelected: false,
          },
          {
            name: "Animal Science",
            id: 20,
            isSelected: false,
          },
          {
            name: "Animation and Visual Effects",
            id: 21,
            isSelected: false,
          },
          {
            name: "Anthropology",
            id: 22,
            isSelected: false,
          },
          {
            name: "Apparel and Textiles",
            id: 23,
            isSelected: false,
          },
          {
            name: " Apparel Technology and Management",
            id: 24,
            isSelected: false,
          },
          {
            name: "Applications of Mathematics",
            id: 25,
            isSelected: false,
          },
          {
            name: "Applied Biology",
            id: 26,
            isSelected: false,
          },
          {
            name: " Applied Chemistry",
            id: 27,
            isSelected: false,
          },
          {
            name: "Applied Econometrics & Business Forecasting",
            id: 28,
            isSelected: false,
          },
          {
            name: "Applied Economics",
            id: 29,
            isSelected: false,
          },
          {
            name: " Applied Electronics",
            id: 30,
            isSelected: false,
          },
          {
            name: "Applied Fisheries and Aquaculture",
            id: 31,
            isSelected: false,
          },
          {
            name: "Applied Genetics",
            id: 32,
            isSelected: false,
          },
          {
            name: "Applied Geography",
            id: 33,
            isSelected: false,
          },
          {
            name: "Applied Geology",
            id: 34,
            isSelected: false,
          },
          {
            name: "Applied Mathematics and Computing",
            id: 35,
            isSelected: false,
          },
          {
            name: "Applied Mathematics",
            id: 36,
            isSelected: false,
          },
          {
            name: "Applied Microbiology",
            id: 37,
            isSelected: false,
          },
          {
            name: "Applied Physics",
            id: 38,
            isSelected: false,
          },
          {
            name: "Applied Plant Science",
            id: 39,
            isSelected: false,
          },
          {
            name: "Applied Psychology",
            id: 40,
            isSelected: false,
          },
          {
            name: "Applied Statistics and Informatics",
            id: 41,
            isSelected: false,
          },
          {
            name: "Applied Zoology",
            id: 42,
            isSelected: false,
          },
          {
            name: "Aquaculture",
            id: 43,
            isSelected: false,
          },
          {
            name: "Aquatic Biology and Fisheries",
            id: 44,
            isSelected: false,
          },
          {
            name: "Architecture",
            id: 45,
            isSelected: false,
          },
          {
            name: "Astronomy and Space Physics",
            id: 46,
            isSelected: false,
          },
          {
            name: "Astronomy",
            id: 47,
            isSelected: false,
          },
          {
            name: "Astrophysics",
            id: 48,
            isSelected: false,
          },
          {
            name: "Audiology and Speech Rehabilitation",
            id: 49,
            isSelected: false,
          },
          {
            name: "Audiology",
            id: 50,
            isSelected: false,
          },
          {
            name: "Aviation",
            id: 51,
            isSelected: false,
          },
          {
            name: "Bacteriology",
            id: 52,
            isSelected: false,
          },
          {
            name: "Banking & Finance",
            id: 53,
            isSelected: false,
          },
          {
            name: "Bio-textiles",
            id: 54,
            isSelected: false,
          },
          {
            name: "Biochemistry",
            id: 55,
            isSelected: false,
          },
          {
            name: "Biodiversity & Conservation",
            id: 56,
            isSelected: false,
          },
          {
            name: "Bioinformatics",
            id: 57,
            isSelected: false,
          },
          {
            name: "Biological Sciences",
            id: 58,
            isSelected: false,
          },
          {
            name: "Biology",
            id: 59,
            isSelected: false,
          },
          {
            name: "Biomaterials and Tissue Engineering",
            id: 60,
            isSelected: false,
          },
          {
            name: "Biomedical Genetics",
            id: 61,
            isSelected: false,
          },
          {
            name: "Biomedical Science",
            id: 62,
            isSelected: false,
          },
          {
            name: "Biophysics",
            id: 63,
            isSelected: false,
          },
          {
            name: "Bioresource Management",
            id: 64,
            isSelected: false,
          },
          {
            name: "Bioscience",
            id: 65,
            isSelected: false,
          },
          {
            name: "Biostatistics",
            id: 66,
            isSelected: false,
          },
          {
            name: "Biotechnology",
            id: 67,
            isSelected: false,
          },
          {
            name: "Botany",
            id: 68,
            isSelected: false,
          },
          {
            name: "Chemical Sciences",
            id: 69,
            isSelected: false,
          },
          {
            name: "Chemistry",
            id: 70,
            isSelected: false,
          },
          {
            name: "Child Guidance and Family Counselling",
            id: 71,
            isSelected: false,
          },
          {
            name: "Child Health Nursing",
            id: 72,
            isSelected: false,
          },
          {
            name: "Clinical Microbiology",
            id: 73,
            isSelected: false,
          },
          {
            name: "Clinical Nutrition",
            id: 74,
            isSelected: false,
          },
          {
            name: "Clinical Psychology",
            id: 75,
            isSelected: false,
          },
          {
            name: "Clinical Research and Regulatory Affairs",
            id: 76,
            isSelected: false,
          },
          {
            name: "Clinical Research",
            id: 77,
            isSelected: false,
          },
          {
            name: "Clothing and Textiles",
            id: 78,
            isSelected: false,
          },
          {
            name: "Co-operation and Banking (Co-Operative Management)",
            id: 79,
            isSelected: false,
          },
          {
            name: "Coastal & Disaster Management",
            id: 80,
            isSelected: false,
          },
          {
            name: "Communication Media for Children",
            id: 81,
            isSelected: false,
          },
          {
            name: "Communication",
            id: 82,
            isSelected: false,
          },
          {
            name: "Computational Biology",
            id: 83,
            isSelected: false,
          },
          {
            name: "Computer Animation and Visual Effects",
            id: 84,
            isSelected: false,
          },
          {
            name: "Computer Applications",
            id: 85,
            isSelected: false,
          },
          {
            name: "Computer Communication",
            id: 86,
            isSelected: false,
          },
          {
            name: "Computer Science",
            id: 87,
            isSelected: false,
          },
          {
            name: "Computer Technology",
            id: 88,
            isSelected: false,
          },
          {
            name: "Conservation Biology",
            id: 89,
            isSelected: false,
          },
          {
            name: "Costume Design and Fashion",
            id: 90,
            isSelected: false,
          },
          {
            name: "Counseling Psychology",
            id: 91,
            isSelected: false,
          },
          {
            name: "Criminology & Criminal Justice",
            id: 92,
            isSelected: false,
          },
          {
            name: "Criminology",
            id: 93,
            isSelected: false,
          },
          {
            name: "Cyber Law and Information Security",
            id: 94,
            isSelected: false,
          },
          {
            name: "Dairy Engineering",
            id: 95,
            isSelected: false,
          },
          {
            name: "Dairy Science",
            id: 96,
            isSelected: false,
          },
          {
            name: "Dairy Technology",
            id: 97,
            isSelected: false,
          },
          {
            name: "Data Mining and Warehousing",
            id: 98,
            isSelected: false,
          },
          {
            name: "Development Communications",
            id: 99,
            isSelected: false,
          },
          {
            name: "Disaster Mitigation",
            id: 100,
            isSelected: false,
          },
          {
            name: "Drug Chemistry",
            id: 101,
            isSelected: false,
          },
          {
            name: "e-Learning Technology",
            id: 102,
            isSelected: false,
          },
          {
            name: "Earth Science & Resource Management",
            id: 103,
            isSelected: false,
          },
          {
            name: "Earth Science",
            id: 104,
            isSelected: false,
          },
          {
            name: "Eco Restoration",
            id: 105,
            isSelected: false,
          },
          {
            name: "Ecology & Environmental Science",
            id: 106,
            isSelected: false,
          },
          {
            name: "Economics",
            id: 107,
            isSelected: false,
          },
          {
            name: "Ecotourism",
            id: 108,
            isSelected: false,
          },
          {
            name: "Electro Chemistry",
            id: 109,
            isSelected: false,
          },
          {
            name: "Electronic Media",
            id: 110,
            isSelected: false,
          },
          {
            name: "Electronic Science",
            id: 111,
            isSelected: false,
          },
          {
            name: "Electronics & Communication",
            id: 112,
            isSelected: false,
          },
          {
            name: "Electronics and Instrumentation",
            id: 113,
            isSelected: false,
          },
          {
            name: "Electronics and Telecommunications",
            id: 114,
            isSelected: false,
          },
          {
            name: "Electronics",
            id: 115,
            isSelected: false,
          },
          {
            name: "Endocrinology",
            id: 116,
            isSelected: false,
          },
          {
            name: "Engineering Physics",
            id: 117,
            isSelected: false,
          },
          {
            name: "Entomology",
            id: 118,
            isSelected: false,
          },
          {
            name: "Entrepreneurship",
            id: 119,
            isSelected: false,
          },
          {
            name: "Environment & Solid Waste Management",
            id: 120,
            isSelected: false,
          },
          {
            name: "Environmental and Climate Change Management",
            id: 121,
            isSelected: false,
          },
          {
            name: "Environmental Management",
            id: 122,
            isSelected: false,
          },
          {
            name: "Environmental Science",
            id: 123,
            isSelected: false,
          },
          {
            name: "Epidemiology",
            id: 124,
            isSelected: false,
          },
          {
            name: "Extension and Communication",
            id: 125,
            isSelected: false,
          },
          {
            name: "Fabric and Apparel Designing",
            id: 126,
            isSelected: false,
          },
          {
            name: "Fashion & Textile Merchandising",
            id: 127,
            isSelected: false,
          },
          {
            name: "Fashion Design and Technology",
            id: 128,
            isSelected: false,
          },
          {
            name: "Fashion Designing",
            id: 129,
            isSelected: false,
          },
          {
            name: "Fermentation & Microbial Technology",
            id: 130,
            isSelected: false,
          },
          {
            name: "Financial Computing",
            id: 131,
            isSelected: false,
          },
          {
            name: "Financial Economics and Administration",
            id: 132,
            isSelected: false,
          },
          {
            name: "Financial Mathematics",
            id: 133,
            isSelected: false,
          },
          {
            name: "Floriculture and Landscaping",
            id: 134,
            isSelected: false,
          },
          {
            name: "Food Biotechnology",
            id: 135,
            isSelected: false,
          },
          {
            name: "Food Nutrition",
            id: 136,
            isSelected: false,
          },
          {
            name: "Food Processing Technology",
            id: 137,
            isSelected: false,
          },
          {
            name: "Food Science & Quality Control",
            id: 138,
            isSelected: false,
          },
          {
            name: "Food Science and Nutrition",
            id: 139,
            isSelected: false,
          },
          {
            name: "Food Science and Technology",
            id: 140,
            isSelected: false,
          },
          {
            name: "Food Science",
            id: 141,
            isSelected: false,
          },
          {
            name: "Food Technology",
            id: 142,
            isSelected: false,
          },
          {
            name: "Forensic Science & Criminology",
            id: 143,
            isSelected: false,
          },
          {
            name: "Forensic Sciences",
            id: 144,
            isSelected: false,
          },
          {
            name: "Forestry",
            id: 145,
            isSelected: false,
          },
          {
            name: "Fruit Science",
            id: 146,
            isSelected: false,
          },
          {
            name: "Game Design and Development",
            id: 147,
            isSelected: false,
          },
          {
            name: "Gaming",
            id: 148,
            isSelected: false,
          },
          {
            name: "Garment Manufacturing Technology",
            id: 149,
            isSelected: false,
          },
          {
            name: "Garment Production & Export Management",
            id: 150,
            isSelected: false,
          },
          {
            name: "General Biotechnology",
            id: 151,
            isSelected: false,
          },
          {
            name: "Genetics and Plant Breeding",
            id: 152,
            isSelected: false,
          },
          {
            name: "Genetics Engineering",
            id: 153,
            isSelected: false,
          },
          {
            name: "Genetics",
            id: 154,
            isSelected: false,
          },
          {
            name: "Genomics and Proteomics",
            id: 155,
            isSelected: false,
          },
          {
            name: "Genomics",
            id: 156,
            isSelected: false,
          },
          {
            name: "Geography",
            id: 157,
            isSelected: false,
          },
          {
            name: "Geoinformatics",
            id: 158,
            isSelected: false,
          },
          {
            name: "Geological Sciences",
            id: 159,
            isSelected: false,
          },
          {
            name: "Geology",
            id: 160,
            isSelected: false,
          },
          {
            name: "Geophysics",
            id: 161,
            isSelected: false,
          },
          {
            name: "Global Warming Reduction",
            id: 162,
            isSelected: false,
          },
          {
            name: "Green Business",
            id: 163,
            isSelected: false,
          },
          {
            name: "Green Technology",
            id: 164,
            isSelected: false,
          },
          {
            name: "Habitat and Population Studies",
            id: 165,
            isSelected: false,
          },
          {
            name: "Hardware & Networking",
            id: 166,
            isSelected: false,
          },
          {
            name: "Health and Yoga Therapy",
            id: 167,
            isSelected: false,
          },
          {
            name: "Herbal Science",
            id: 168,
            isSelected: false,
          },
          {
            name: "Home Management",
            id: 169,
            isSelected: false,
          },
          {
            name: "Home Science",
            id: 170,
            isSelected: false,
          },
          {
            name: "Hons. in Biochemistry",
            id: 171,
            isSelected: false,
          },
          {
            name: "Hons. in Botany",
            id: 172,
            isSelected: false,
          },
          {
            name: "Hons. in Chemistry",
            id: 173,
            isSelected: false,
          },
          {
            name: "Hons. in Computer Science",
            id: 174,
            isSelected: false,
          },
          {
            name: "Hons. in Environmental Science",
            id: 175,
            isSelected: false,
          },
          {
            name: "Hons. in Fermentation & Microbial Technology",
            id: 176,
            isSelected: false,
          },
          {
            name: "Hons. in Genetics",
            id: 177,
            isSelected: false,
          },
          {
            name: "Hons. in Industrial Chemistry",
            id: 178,
            isSelected: false,
          },
          {
            name: "Hons. in Mathematics",
            id: 179,
            isSelected: false,
          },
          {
            name: "Hons. in Microbiology",
            id: 180,
            isSelected: false,
          },
          {
            name: "Hons. in Molecular Biology & Biochemistry",
            id: 181,
            isSelected: false,
          },
          {
            name: "Hons. in Physics",
            id: 182,
            isSelected: false,
          },
          {
            name: " Hons. in Zoology",
            id: 183,
            isSelected: false,
          },
          {
            name: "Horticulture",
            id: 184,
            isSelected: false,
          },
          {
            name: "Hospital Administration",
            id: 185,
            isSelected: false,
          },
          {
            name: "Hospitality & Tourism Studies",
            id: 186,
            isSelected: false,
          },
          {
            name: "Hotel Management",
            id: 187,
            isSelected: false,
          },
          {
            name: "Human Development",
            id: 188,
            isSelected: false,
          },
          {
            name: "Human Genetics",
            id: 189,
            isSelected: false,
          },
          {
            name: "Human Physiology",
            id: 190,
            isSelected: false,
          },
          {
            name: "Hydrochemistry",
            id: 191,
            isSelected: false,
          },
          {
            name: "Hydrology",
            id: 192,
            isSelected: false,
          },
          {
            name: "Industrial Biotechnology",
            id: 193,
            isSelected: false,
          },
          {
            name: "Industrial Chemistry",
            id: 194,
            isSelected: false,
          },
          {
            name: "Industrial Engineering",
            id: 195,
            isSelected: false,
          },
          {
            name: "Industrial Mathematics",
            id: 196,
            isSelected: false,
          },
          {
            name: "Industrial Microbiology",
            id: 197,
            isSelected: false,
          },
          {
            name: "Information and Communication Technology",
            id: 198,
            isSelected: false,
          },
          {
            name: "Information Science",
            id: 199,
            isSelected: false,
          },
          {
            name: "Information Security and Cyber Forensics",
            id: 200,
            isSelected: false,
          },
          {
            name: "Information Technology and Management",
            id: 201,
            isSelected: false,
          },
          {
            name: "Information Technology",
            id: 202,
            isSelected: false,
          },
          {
            name: "Instrumentation Technology",
            id: 203,
            isSelected: false,
          },
          {
            name: "Integrated Biotechnology",
            id: 204,
            isSelected: false,
          },
          {
            name: "Interior Design",
            id: 205,
            isSelected: false,
          },
          {
            name: "Life Science",
            id: 206,
            isSelected: false,
          },
          {
            name: "Limnology & Fisheries",
            id: 207,
            isSelected: false,
          },
          {
            name: "Live Stock Production & Management",
            id: 208,
            isSelected: false,
          },
          {
            name: "Marine Biotechnology",
            id: 209,
            isSelected: false,
          },
          {
            name: "Marine Geophysics",
            id: 210,
            isSelected: false,
          },
          {
            name: "Marine Science",
            id: 211,
            isSelected: false,
          },
          {
            name: "Mass Communication & Journalism",
            id: 212,
            isSelected: false,
          },
          {
            name: "Mass Communication",
            id: 213,
            isSelected: false,
          },
          {
            name: "Materials Science",
            id: 214,
            isSelected: false,
          },
          {
            name: "Maternity Nursing",
            id: 215,
            isSelected: false,
          },
          {
            name: "Mathematical Ecology & Environment Studies",
            id: 216,
            isSelected: false,
          },
          {
            name: "Mathematical Science",
            id: 217,
            isSelected: false,
          },
          {
            name: "Mathematics and Computing",
            id: 218,
            isSelected: false,
          },
          {
            name: "Mathematics",
            id: 219,
            isSelected: false,
          },
          {
            name: "Maths & Computer Applications",
            id: 220,
            isSelected: false,
          },
          {
            name: "Maths & Statistics",
            id: 221,
            isSelected: false,
          },
          {
            name: "Media Technology",
            id: 222,
            isSelected: false,
          },
          {
            name: "Medical Biochemistry",
            id: 223,
            isSelected: false,
          },
          {
            name: "Medical Biotechnology",
            id: 224,
            isSelected: false,
          },
          {
            name: "Medical Imaging Technology",
            id: 225,
            isSelected: false,
          },
          {
            name: "Medical Microbiology",
            id: 226,
            isSelected: false,
          },
          {
            name: "Medical Physics",
            id: 227,
            isSelected: false,
          },
          ,
          {
            name: "Medical Radiation Physics",
            id: 228,
            isSelected: false,
          },
          {
            name: "Medical Surgical Nursing",
            id: 229,
            isSelected: false,
          },
          {
            name: "Medical Technology",
            id: 230,
            isSelected: false,
          },
          {
            name: "Medicinal Plants",
            id: 231,
            isSelected: false,
          },
          {
            name: "Mental Health",
            id: 232,
            isSelected: false,
          },
          {
            name: "Meteorology",
            id: 233,
            isSelected: false,
          },
          {
            name: "Microbiology & Immunology",
            id: 234,
            isSelected: false,
          },
          {
            name: "Microbiology",
            id: 235,
            isSelected: false,
          },
          {
            name: "Microelectronics and Advanced Communication",
            id: 236,
            isSelected: false,
          },
          {
            name: "Military Science",
            id: 237,
            isSelecte: false,
          },
          {
            name: "Molecular Biology & Biochemistry",
            id: 238,
            isSelected: false,
          },
          {
            name: "Molecular Biology & Genetic Engineering",
            id: 239,
            isSelected: false,
          },
          {
            name: "Molecular Biology & Human Genetics",
            id: 240,
            isSelected: false,
          },
          {
            name: "Molecular Biology",
            id: 241,
            isSelected: false,
          },
          {
            name: "Multimedia and Animation",
            id: 242,
            isSelected: false,
          },
          {
            name: "Multimedia Technology",
            id: 243,
            isSelected: false,
          },
          {
            name: "Multimedia",
            id: 244,
            isSelected: false,
          },
          {
            name: "Museology",
            id: 245,
            isSelected: false,
          },
          {
            name: "Nano Sciences & Technology",
            id: 246,
            isSelected: false,
          },
          {
            name: "Nanotechnology",
            id: 247,
            isSelected: false,
          },
          {
            name: "Natural Resource Management",
            id: 248,
            isSelected: false,
          },
          {
            name: "Nematology",
            id: 249,
            isSelected: false,
          },
          {
            name: "Network Protocol Design",
            id: 250,
            isSelected: false,
          },
          {
            name: "Neuroscience",
            id: 251,
            isSelected: false,
          },
          {
            name: "Nuclear Medicine Technology",
            id: 252,
            isSelected: false,
          },
          {
            name: "Nursing",
            id: 253,
            isSelected: false,
          },
          {
            name: "Nutrition and Dietetics",
            id: 254,
            isSelected: false,
          },
          {
            name: "Nutrition and Food Processing",
            id: 255,
            isSelected: false,
          },
          {
            name: "Obstetrics and Gynecological Nursing",
            id: 256,
            isSelected: false,
          },
          {
            name: "Occupational Therapy",
            id: 257,
            isSelected: false,
          },
          {
            name: "Oceanography",
            id: 258,
            isSelected: false,
          },
          {
            name: "Olericulture",
            id: 259,
            isSelected: false,
          },
          {
            name: "Operation Research & Computer Applications",
            id: 260,
            isSelected: false,
          },
          {
            name: "Operational Research",
            id: 261,
            isSelected: false,
          },
          {
            name: "Optometry",
            id: 262,
            isSelected: false,
          },
          {
            name: "Orthopedics",
            id: 263,
            isSelected: false,
          },
          {
            name: "Osteopathy",
            id: 264,
            isSelected: false,
          },
          {
            name: "Paediatric Nursing",
            id: 265,
            isSelected: false,
          },
          {
            name: "Petroleum Geology",
            id: 266,
            isSelected: false,
          },
          {
            name: "Petroleum Technology",
            id: 267,
            isSelected: false,
          },
          {
            name: "Polymer Science",
            id: 268,
            isSelected: false,
          },
          {
            name: "Pharmaceutical Chemistry",
            id: 269,
            isSelected: false,
          },
          {
            name: "Pharmacology",
            id: 270,
            isSelected: false,
          },
          {
            name: "Photonics",
            id: 271,
            isSelected: false,
          },
          {
            name: "Physical Oceanography",
            id: 272,
            isSelected: false,
          },
          {
            name: "Physics",
            id: 273,
            isSelected: false,
          },
          {
            name: "Physiology",
            id: 274,
            isSelected: false,
          },
          {
            name: "Phytomedical Science & Technology",
            id: 275,
            isSelected: false,
          },
          {
            name: "Plant Biochemistry",
            id: 276,
            isSelected: false,
          },
          {
            name: "Plant Biology & Plant Biotechnology",
            id: 277,
            isSelected: false,
          },
          {
            name: "Plant Pathology",
            id: 278,
            isSelected: false,
          },
          {
            name: "Plant Science",
            id: 279,
            isSelected: false,
          },
          {
            name: "Pollution Control",
            id: 280,
            isSelected: false,
          },
          {
            name: "Post-harvest Technology",
            id: 281,
            isSelected: false,
          },
          {
            name: "Poultry Production",
            id: 282,
            isSelected: false,
          },
          {
            name: "Production Engineering",
            id: 283,
            isSelected: false,
          },
          {
            name: "Prosthodontics",
            id: 284,
            isSelected: false,
          },
          {
            name: "Psychiatric Nursing",
            id: 285,
            isSelected: false,
          },
          {
            name: "Psychological Counselling",
            id: 286,
            isSelected: false,
          },
          {
            name: "Psychology",
            id: 287,
            isSelected: false,
          },
          {
            name: "Public Health",
            id: 288,
            isSelected: false,
          },
          {
            name: "Regenerative Medicine",
            id: 289,
            isSelected: false,
          },
          {
            name: "Remote Sensing and GIS",
            id: 290,
            isSelected: false,
          },
          {
            name: "Renewable Energy",
            id: 291,
            isSelected: false,
          },
          {
            name: "Resource Managemen",
            id: 292,
            isSelected: false,
          },
          {
            name: "Respiratory Therapy",
            id: 293,
            isSelected: false,
          },
          {
            name: "Rural Development",
            id: 294,
            isSelected: false,
          },
          {
            name: "Rural Health",
            id: 295,
            isSelected: false,
          },
          {
            name: "Science and Technology Communication",
            id: 296,
            isSelected: false,
          },
          {
            name: "Seed Science & Technology",
            id: 297,
            isSelected: false,
          },
          {
            name: "Sericulture",
            id: 298,
            isSelected: false,
          },
          {
            name: "Service Industry Management",
            id: 299,
            isSelected: false,
          },
          {
            name: "Software Engineering",
            id: 300,
            isSelected: false,
          },
          {
            name: "Software System",
            id: 301,
            isSelected: false,
          },
          {
            name: "Soil and Water Conservation",
            id: 302,
            isSelected: false,
          },
          {
            name: "Soil Science",
            id: 303,
            isSelected: false,
          },
          {
            name: "Speech-Language Pathology",
            id: 304,
            isSelected: false,
          },
          {
            name: "Sports Science",
            id: 305,
            isSelected: false,
          },
          {
            name: "Statistics",
            id: 306,
            isSelected: false,
          },
          {
            name: "Stem Cell and Tissue Engineering",
            id: 307,
            isSelected: false,
          },
          {
            name: "Sugar Technology",
            id: 308,
            isSelected: false,
          },
          {
            name: "Sustainable Development",
            id: 309,
            isSelected: false,
          },
          {
            name: "System Administration & Networking",
            id: 310,
            isSelected: false,
          },
          {
            name: "Textile Design",
            id: 311,
            isSelected: false,
          },
          {
            name: "Tourism",
            id: 312,
            isSelected: false,
          },
          {
            name: "Toxicology",
            id: 313,
            isSelected: false,
          },
          {
            name: "Veterinary Medicine",
            id: 314,
            isSelected: false,
          },
          {
            name: "Veterinary Microbiology",
            id: 315,
            isSelected: false,
          },
          {
            name: "Veterinary Parasitology",
            id: 316,
            isSelected: false,
          },
          {
            name: "Veterinary Pharmacology & Toxicology",
            id: 317,
            isSelected: false,
          },
          {
            name: "Veterinary Physiology",
            id: 318,
            isSelected: false,
          },
          {
            name: "Veterinary Public Health",
            id: 319,
            isSelected: false,
          },
          {
            name: "Virology",
            id: 320,
            isSelected: false,
          },
          {
            name: "Visual Communication",
            id: 321,
            isSelected: false,
          },
          {
            name: "Water Management",
            id: 322,
            isSelected: false,
          },
          {
            name: "Wildlife Sciences",
            id: 323,
            isSelected: false,
          },
          {
            name: "Wood Science & Technology",
            id: 324,
            isSelected: false,
          },
          {
            name: "Yoga and Management",
            id: 325,
            isSelected: false,
          },
          {
            name: "Yoga",
            id: 326,
            isSelected: false,
          },
          {
            name: "Zoology",
            id: 327,
            isSelected: false,
          }

        ]
      },
      {
        name: "Master of Research (MRes)",
        id: 3,
        value: false,
        subchilds: [
          {
            name: "Public Policy MRes",
            id: 1,
            isSelected: false,
          },
          {
            name: "Tissue Engineering for Regenerative Medicine",
            id: 2,
            isSelected: false,
          },
          {
            name: "Medical Sciences",
            id: 3,
            isSelected: false,
          },
          {
            name: "Public Health and Primary Care",
            id: 4,
            isSelected: false,
          },
          {
            name: "International Relations MRes",
            id: 5,
            isSelected: false,
          },
          {
            name: "Geography MRes",
            id: 6,
            isSelected: false,
          },
          {
            name: "Public Health and Primary Care",
            id: 7,
            isSelected: false,
          },
          {
            name: "Public Health and Primary Care Distance Learning",
            id: 8,
            isSelected: false,
          },
          {
            name: "Clinical Research Distance Learning",
            id: 9,
            isSelected: false,
          },
          {
            name: "Oncology",
            id: 10,
            isSelected: false,
          },
          {
            name: "Materials Research MRes",
            id: 11,
            isSelected: false,
          },
          {
            name: "Health and Social Care",
            id: 12,
            isSelected: false,
          },
          {
            name: "Clinical Research MRes",
            id: 13,
            isSelected: false,
          },
          {
            name: "Clinical Research",
            id: 14,
            isSelected: false,
          },
          {
            name: "Health and Social Care Distance Learning",
            id: 15,
            isSelected: false,
          },
          {
            name: "Public Health and Primary Care",
            id: 16,
            isSelected: false,
          },
          {
            name: "MRes Art: Exhibition Studies",
            id: 17,
            isSelected: false,
          },
          {
            name: "MRes Art: Theory and Philosophy",
            id: 18,
            isSelected: false,
          },
          {
            name: "MRes Art: Moving Image",
            id: 19,
            isSelected: false,
          },
          {
            name: "Clinical Management of Pain",
            id: 20,
            isSelected: false,
          },
        ],
      },
      {
        name: "Master by Research (MPhil)",
        id: 4,
        value: false,
        subchilds: [
          {
            name: "History",
            id: 1,
            isSelected: false,
          },
          {
            name: "Education",
            id: 2,
            isSelected: false,
          },
          {
            name: "Education",
            id: 3,
            isSelected: false,
          },
          {
            name: "Economics",
            id: 4,
            isSelected: false,
          },
          {
            name: "Biotechnology",
            id: 5,
            isSelected: false,
          },
          {
            name: "Physics",
            id: 6,
            isSelected: false,
          },
          {
            name: "Chemistry",
            id: 7,
            isSelected: false,
          },
          {
            name: "Sociology",
            id: 8,
            isSelected: false,
          },
          {
            name: "Mathematics",
            id: 9,
            isSelected: false,
          },
          {
            name: "Tamil",
            id: 10,
            isSelected: false,
          },
          {
            name: "Commerce",
            id: 11,
            isSelected: false,
          },
          {
            name: "Botany",
            id: 12,
            isSelected: false,
          },
          {
            name: "Zoology",
            id: 13,
            isSelected: false,
          },
          {
            name: "Computer Science",
            id: 14,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Studies (MSt)",
        id: 5,
        value: false,
        subchilds: [
          {
            name: "Computer Science",
            id: 1,
            isSelected: false,
          },
          {
            name: "Embedded Systems",
            id: 2,
            isSelected: false,
          },
          {
            name: "Wireless Communications",
            id: 3,
            isSelected: false,
          },
          {
            name: "Power Engineering",
            id: 4,
            isSelected: false,
          },
          {
            name: "Management Information Systems",
            id: 5,
            isSelected: false,
          },
          {
            name: "Game Design and Game Development",
            id: 6,
            isSelected: false,
          },
          {
            name: "Petroleum Engineering",
            id: 7,
            isSelected: false,
          },
          {
            name: "Life Sciences",
            id: 8,
            isSelected: false,
          },
          {
            name: "Pharmacy",
            id: 9,
            isSelected: false,
          },
          {
            name: "Very-large-scale integration (VLSI)",
            id: 10,
            isSelected: false,
          },
         
        ]  
      },
      {
        name: "Master of Business Administration (MBA)",
        id: 6,
        value: false,
        subchilds: [
          {
            name: "Finance",
            id: 1,
            isSelected: false,
          },
          {
            name: "Marketing",
            id: 2,
            isSelected: false,
          },
          {
            name: "Human Resource Management",
            id: 3,
            isSelected: false,
          },
          {
            name: "International Business",
            id: 4,
            isSelected: false,
          },
          {
            name: "Logistics & Supply Chain Management",
            id: 5,
            isSelected: false,
          },
          {
            name: "Big Data Analytics/ Data Science/ Business Analytics",
            id: 6,
            isSelected: false,
          },
          {
            name: "Operations Management",
            id: 7,
            isSelected: false,
          },
          {
            name: "Banking & Financial Services",
            id: 8,
            isSelected: false,
          },
          {
            name: "Insurance Business Management",
            id: 9,
            isSelected: false,
          },
          {
            name: "Rural Management",
            id: 10,
            isSelected: false,
          },
          {
            name: "Healthcare Management",
            id: 11,
            isSelected: false,
          },
          {
            name: "Pharmaceutical Management",
            id: 12,
            isSelected: false,
          },
          {
            name: "Retail Management",
            id: 13,
            isSelected: false,
          },
          {
            name: "Energy Management",
            id: 14,
            isSelected: false,
          },
          {
            name: "Infrastructure Management",
            id: 15,
            isSelected: false,
          },
          {
            name: "Agri-Business Management",
            id: 16,
            isSelected: false,
          },
          {
            name: "Entrepreneurship",
            id: 17,
            isSelected: false,
          },
          {
            name: "Family Managed Business",
            id: 18,
            isSelected: false,
          },
          {
            name: "Sustainability Management",
            id: 19,
            isSelected: false,
          },
          {
            name: "Dual Country Program",
            id: 20,
            isSelected: false,
          },
        ],
      },
      {
        name: "Master of Library Science (MLS, MLIS, MSLS) ",
        id: 7,
        value: false,
      },
      {
        name: "Master of Public Administration (MPA)",
        id: 8,
        value: false,
        subchilds: [
          {
            name: "Homeland Security",
            id: 1,
            isSelected: false,
          },
          {
            name: "Non-Profit Management",
            id: 2,
            isSelected: false,
          },
          {
            name: "International Affairs",
            id: 3,
            isSelected: false,
          },
          {
            name: "Health Field",
            id: 4,
            isSelected: false,
          },
          {
            name: "Criminal Justice",
            id: 5,
            isSelected: false,
          },
          {
            name: "Urban Development",
            id: 6,
            isSelected: false,
          },
          {
            name: "Transportation Systems",
            id: 7,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Public Health (MPH)",
        id: 9,
        value: false,
        subchilds: [
          {
            name: "Epidemiology",
            id: 1,
            isSelected: false,
          },
          {
            name: "Health services administration",
            id: 2,
            isSelected: false,
          },
          {
            name: "Environmental health science",
            id: 3,
            isSelected: false,
          },
          {
            name: "Disaster management and emergency readiness",
            id: 4,
            isSelected: false,
          },
          {
            name: "Biostatistics",
            id: 5,
            isSelected: false,
          },
          {
            name: "Maternal and Child Health",
            id: 6,
            isSelected: false,
          },
          {
            name: "Disaster Management",
            id: 7,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Social Work (MSW) ",
        id: 10,
        value: false,
        subchilds: [
          {
            name: "Human Resource Management",
            id: 1,
            isSelected: false,
          },
          {
            name: "Personnel Management",
            id: 2,
            isSelected: false,
          },
          {
            name: "Criminology & Correction Administration",
            id: 3,
            isSelected: false,
          },
          {
            name: "Family & Child Welfare",
            id: 4,
            isSelected: false,
          },
          {
            name: "Urban and Rural Community Development",
            id: 5,
            isSelected: false,
          },
          {
            name: "Industrial Relation & Labour Welfare",
            id: 6,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Laws (LLM)",
        id: 11,
        value: false,
        subchilds: [
          {
            name: "Civil Law",
            id: 1,
            isSelected: false,
          },
          {
            name: "Tax Law",
            id: 2,
            isSelected: false,
          },
          {
            name: "Criminal Law",
            id: 3,
            isSelected: false,
          },
          {
            name: "Corporate Law",
            id: 4,
            isSelected: false,
          },
          {
            name: "Commercial Law",
            id: 5,
            isSelected: false,
          },
          {
            name: "International Law",
            id: 6,
            isSelected: false,
          },
          {
            name: "Labour Law",
            id: 7,
            isSelected: false,
          },
          {
            name: "Real Estate Law",
            id: 8,
            isSelected: false,
          },
          {
            name: "Patent Law",
            id: 9,
            isSelected: false,
          },
          {
            name: "Competition Law",
            id: 10,
            isSelected: false,
          },
          {
            name: "Intellectual Property Law",
            id: 11,
            isSelected: false,
          },
          {
            name: "Mergers and Acquisition Law",
            id: 12,
            isSelected: false,
          },
          {
            name: "Tax Law",
            id: 13,
            isSelected: false,
          },
          {
            name: "Administrative Law",
            id: 14,
            isSelected: false,
          },
          {
            name: "Banking Law",
            id: 15,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Arts in Liberal Studies (MA, MALS, MLA/ALM, MLS)",
        id: 12,
        value: false,
      },
      {
        name: "Master of Fine Arts (MFA)",
        id: 13,
        value: false,
        subchilds: [
          {
            name: "Master of Fine Arts in Applied Arts",
            id: 1,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Painting",
            id: 2,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Visual Communication",
            id: 3,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Art History",
            id: 4,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Acting",
            id: 5,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Dance",
            id: 6,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Creative Writing",
            id: 7,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Photography",
            id: 8,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Filmmaking",
            id: 9,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Design",
            id: 10,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Textile Design",
            id: 11,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Pottery and Ceramics",
            id: 12,
            isSelected: false,
          },
          {
            name: "Master of Fine Arts in Sculpture",
            id: 13,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Music (MM/MMus)",
        id: 14,
        value: false,
        subchilds: [
          {
            name: "Music Composition",
            id: 1,
            isSelected: false,
          },
          {
            name: "Music Managemen",
            id: 2,
            isSelected: false,
          },
          {
            name: "Carnatic Music",
            id: 3,
            isSelected: false,
          },
          {
            name: "Hindustani Classical Music",
            id: 4,
            isSelected: false,
          },
          {
            name: "Vocal Music",
            id: 5,
            isSelected: false,
          },
          {
            name: "Instrumental Music",
            id: 6,
            isSelected: false,
          },
          
        ]
      },
      {
        name: "Master of Education (MEd)",
        id: 15,
        value: false,
        subchilds: [
          {
            name: "Educational Technology",
            id: 1,
            isSelected: false,
          },
          {
            name: "Educational Management",
            id: 2,
            isSelected: false,
          },
          {
            name: "Language Education",
            id: 3,
            isSelected: false,
          },
          {
            name: "Special Education",
            id: 4,
            isSelected: false,
          },
          {
            name: "Women Studies ",
            id: 5,
            isSelected: false,
          },
          {
            name: "Environment Education",
            id: 6,
            isSelected: false,
          },
          {
            name: "Guidance and Counseling",
            id: 7,
            isSelected: false,
          },
          {
            name: "Rural Education ",
            id: 8,
            isSelected: false,
          },
          {
            name: "Teacher Education",
            id: 9,
            isSelected: false,
          },
          {
            name: "Yoga Education",
            id: 10,
            isSelected: false,
          },
        ] 
      },
      {
        name: "Master of Engineering (MEng)",
        id: 16,
        value: false,
        subchilds: [
          {
            name: "Artificial Intelligence and Machine Learning",
            id: 1,
            isSelected: false,
          },
          {
            name: "Biomedical Engineering",
            id: 2,
            isSelected: false,
          },
          {
            name: "Computer Networking and Security",
            id: 3,
            isSelected: false,
          },
          {
            name: "Nanoelectronic Circuits and Systems",
            id: 4,
            isSelected: false,
          },
          {
            name: "Nanoelectronic Devices and Materials",
            id: 5,
            isSelected: false,
          },
          {
            name: "Software",
            id: 6,
            isSelected: false,
          },
          {
            name: "Sustainable Energy",
            id: 7,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Architecture (M.Arch)",
        id: 17,
        value: false,
        subchilds: [
          {
            name: "Architectural conservation",
            id: 1,
            isSelected: false,
          },
          {
            name: "Building engineering and management",
            id: 2,
            isSelected: false,
          },
          {
            name: "Housing",
            id: 3,
            isSelected: false,
          },
          {
            name: "Urban design",
            id: 4,
            isSelected: false,
          },
          {
            name: "Landscape architecture ",
            id: 5,
            isSelected: false,
          },
          {
            name: "Architectural design",
            id: 6,
            isSelected: false,
          },
          {
            name: "Sustainable architecture",
            id: 7,
            isSelected: false,
          },
          {
            name: "Transport planning ",
            id: 8,
            isSelected: false,
          },
          {
            name: "Town and country planning",
            id: 9,
            isSelected: false,
          },
          {
            name: "Regional planning",
            id: 10,
            isSelected: false,
          },
          {
            name: "Industrial design ",
            id: 11,
            isSelected: false,
          },
          {
            name: "Environmental planning ",
            id: 12,
            isSelected: false,
          },
          {
            name: "Architecture and digital design",
            id: 13,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master in Management (MIM)",
        id: 18,
        value: false,
        subchilds: [
          {
            name: "Economics",
            id: 1,
            isSelected: false,
          },
          {
            name: "Finance",
            id: 2,
            isSelected: false,
          },
          {
            name: "Analytics",
            id: 3,
            isSelected: false,
          },
          {
            name: "Accounting",
            id: 4,
            isSelected: false,
          },
          {
            name: "Marketing",
            id: 5,
            isSelected: false,
          }, 
        ]  
      },
      {
        name: "Executive master's degrees (EMBA, EMS)",
        id: 19,
        value: false,
      },
      {
        name: "Postgraduate research master's degrees (Master by Research)",
        id: 20,
        value: false,
      },
      {
        name: "Integrated master's degrees (MEng, MMath, MSci, etc)",
        id: 21,
        value: false,
      },
      {
        name: "Masters of Technology(M.Tech)",
        id: 22,
        value: false,
        subchilds: [
          {
            name: "Software Engineering",
            id: 1,
            isSelected: false,
          },
          {
            name: "Distributed Computing",
            id: 2,
            isSelected: false,
          },
          {
            name: "Advanced Computing",
            id: 3,
            isSelected: false,
          },
          {
            name: "Power Systems",
            id: 4,
            isSelected: false,
          },
          {
            name: "Instrumentation & Control Systems",
            id: 5,
            isSelected: false,
          },
          {
            name: "Micro Electronics and VLSI Design",
            id: 6,
            isSelected: false,
          },
          {
            name: "Thermal Engineering",
            id: 7,
            isSelected: false,
          },
          {
            name: "Computer Aided Design & Manufacturing",
            id: 8,
            isSelected: false,
          },
          {
            name: "Mechatronics Engineering",
            id: 9,
            isSelected: false,
          },
          {
            name: "Hydraulic and Water Resources Engineering",
            id: 10,
            isSelected: false,
          },
          {
            name: "Chemical Plant Design",
            id: 11,
            isSelected: false,
          },
          {
            name: "Chemical Process Design",
            id: 12,
            isSelected: false,
          },
          {
            name: "Aerodynamics",
            id: 13,
            isSelected: false,
          },
          {
            name: "Biomedical Engineering",
            id: 14,
            isSelected: false,
          }
        ]
      },
      {
        name: "Ministry of Corporate Affairs(MCA)",
        id: 23,
        value: false,
        subchilds: [
          {
            name: "Systems Management",
            id: 1,
            isSelected: false,
          },
          {
            name: "Management Information Systems (MIS)",
            id: 2,
            isSelected: false,
          },
          {
            name: "Systems Development",
            id: 3,
            isSelected: false,
          },
          {
            name: "Systems Engineering",
            id: 4,
            isSelected: false,
          },
          {
            name: "Networking",
            id: 5,
            isSelected: false,
          },
          {
            name: "Internet working",
            id: 6,
            isSelected: false,
          },
          {
            name: "Application Software",
            id: 7,
            isSelected: false,
          },
          {
            name: "Software Development",
            id: 8,
            isSelected: false,
          },
          {
            name: "Troubleshooting",
            id: 9,
            isSelected: false,
          },
          {
            name: "Hardware Technology",
            id: 10,
            isSelected: false,
          },
        ]
      },

      {
        name: "Master School of Management(MSM)",
        id: 24,
        value: false,
        subchilds: [
          {
            name: "Business Analytics",
            id: 1,
            isSelected: false,
          },
          {
            name: "Finance",
            id: 2,
            isSelected: false,
          },
          {
            name: "Marketing Management",
            id: 3,
            isSelected: false,
          },
          {
            name: "Human Resource Management",
            id: 4,
            isSelected: false,
          },
          {
            name: "Operations",
            id: 5,
            isSelected: false,
          },
          {
            name: "International Business and Family Business & Entrepreneurship",
            id: 6,
            isSelected: false,
          },
        ]
      },
      {
        name: "Masters in Engineering Management (MEM)",
        id: 25,
        value: false,
        subchilds: [
          {
            name: "Computer Simulation for Risk & Operational Analytics",
            id: 1,
            isSelected: false,
          },
          {
            name: "Project Management",
            id: 2,
            isSelected: false,
          },
          {
            name: "Product Management",
            id: 3,
            isSelected: false,
          },
          {
            name: "Manufacturing Management",
            id: 4,
            isSelected: false,
          },
          {
            name: "Systems Engineering and Architecture",
            id: 5,
            isSelected: false,
          },
          {
            name: "Environmental Systems Analysis, Economics & Public Policy",
            id: 6,
            isSelected: false,
          },
          {
            name: "Applied Machine Learning",
            id: 7,
            isSelected: false,
          },
          {
            name: "Data Science for Business Intelligence",
            id: 8,
            isSelected: false,
          },
          {
            name: "Operations and Supply Chain Management",
            id: 9,
            isSelected: false,
          },
          {
            name: "Technology Development and Commercialization",
            id: 10,
            isSelected: false,
          },
        ]
      },
      {
        name: "Master of Technology (M.Tech)",
        id: 26,
        value: false,
        subchilds: [
          {
            name: "Software Engineering",
            id: 1,
            isSelected: false,
          },
          {
            name: "Distributed Computing",
            id: 2,
            isSelected: false,
          },
          {
            name: "Advanced Computing",
            id: 3,
            isSelected: false,
          },
          {
            name: "Power Systems",
            id: 4,
            isSelected: false,
          },
          {
            name: "Instrumentation & Control Systems",
            id: 5,
            isSelected: false,
          },
          {
            name: "Micro Electronics and VLSI Design",
            id: 6,
            isSelected: false,
          },
          {
            name: "Thermal Engineering",
            id: 7,
            isSelected: false,
          },
          {
            name: "Computer Aided Design & Manufacturing",
            id: 8,
            isSelected: false,
          },
          {
            name: "Mechatronics Engineering",
            id: 9,
            isSelected: false,
          },
          {
            name: "Hydraulic and Water Resources Engineering",
            id: 10,
            isSelected: false,
          },
          {
            name: "Chemical Plant Design",
            id: 11,
            isSelected: false,
          },
          {
            name: "Chemical Process Design",
            id: 12,
            isSelected: false,
          },
          {
            name: "Aerodynamics",
            id: 13,
            isSelected: false,
          },
          {
            name: "Biomedical Engineering",
            id: 14,
            isSelected: false,
          },
        ]
      },
    ],
  
  
  },
];

export default Education;
