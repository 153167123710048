import React from 'react'

const Fairsuccess = () => {
  return (
    <div style={{margin:"150px 30px"}}>
      <div className='text-center'>
        <img src='images/RSuccess.gif'  className='fair_success_img img-fluid'/>
      </div>
    </div>
  )
}

export default Fairsuccess
