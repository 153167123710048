import React,{useState} from 'react'
import Modal from 'react-modal'
import AdminLoginForm from './AdminLoginForm';

const ForgetPassword3 = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <div className='container'>
                <div className='row'>

                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Create Password:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="createpassword"
                                    placeholder=" "
                                />
                                <span className="input__label">Enter Password</span>
                            </label>
                        </div>
                    </div>


                    <div className=" col-lg-12 col-md-12">
                        <div className="form-group hire_group">
                            <label>Re-enter Password:</label>
                            <label
                                className="input mt-1 mb-2"
                                style={{
                                    border: "1px solid #270d44",
                                    borderRadius: "5px",
                                }}
                            >
                                <input
                                    className="input__field form_control form_hire"
                                    type="text"
                                    style={{ borderRadius: "5px" }}
                                    name="reenterpassword"
                                    placeholder=" "
                                />
                                <span className="input__label">Re-enter Password</span>
                            </label>
                        </div>
                    </div>

                    <button className="jobfair_btn jobfair_regbtn mt-3" onClick={toggleModal}>Register</button>
                </div>
            </div>

            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                // style={{marginTop:"150px"}}
                className="Dashboard_Admin_login_form_modal"
            >

                <div className='position-relative'>
                    <AdminLoginForm />
                    <button className='admin_logIn_modal_close' onClick={toggleModal}>x</button>
                </div>

            </Modal>

        </div>
    )
}

export default ForgetPassword3
