import React from 'react'
import {Link , NavLink} from 'react-router-dom'
const JobsSidebar = () => {
    return (
        <div>
            
          <div className="collapse show" id="collapseExample">
              <div className="sidebar " id="sidebar">
                <div className="text-center">
                  <NavLink to="/alljobs">
                    <div className=" ">
                      <b>All Jobs</b>
                    </div>
                  </NavLink>
                </div>
                <div className="text-center">
                  <NavLink to="/locationaljobs">
                    <div className="">
                      <b> Jobs By Location</b>
                    </div>
                  </NavLink>
                </div>

                <div className="text-center">
                  <NavLink to="/companyjobs">
                    <div className="">
                      <b>Jobs By Company</b>
                    </div>
                  </NavLink>
                </div>
                <div className="text-center">
                  <NavLink to="/categoryjobs">
                    <div className="">
                      <b>Jobs By Category</b>
                    </div>
                  </NavLink>
                </div>
                <div className="text-center">
                  <NavLink to="/designationjobs">
                    <div className="">
                      <b> Jobs By Designation</b>
                    </div>
                  </NavLink>
                </div>

                <div className="text-center">
                  <NavLink to="/skilljobs">
                    <div className="">
                      <b>Jobs By Skills</b>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
         
        </div>
    )
}

export default JobsSidebar
