import React, { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const JobFair = () => {
  const navigate = useNavigate();

  const [hireandtraindetails, setHireandtraindetails] = useState({
    companyname: "",
    hrname: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    companyname: "",
    hrname: "",
    email: "",
  });

  const validate = (name, value) => {
    switch (name) {
      case "companyname":
        if (!value || value.trim() === "") {
          return "*Name is Required";
        } else {
          return "";
        }
      case "hrname":
        if (!value || value.trim() === "") {
          return "*Name is Required";
        } else {
          return "";
        }
      case "email":
        if (!value) {
          return "*Email is Required";
        } else if (!value.match(/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/)) {
          return "Enter a valid email address";
        } else {
          return "";
        }
      default: {
        return " ";
      }
    }
  };

  const [companyname, setCompanyName] = useState("");
  const [hrname, setHrname] = useState("");
  const [email, setmail] = useState("");
  const [phone, setphone] = useState("");
  const [Qualification, setQualification] = useState("");
  const [percentage, setpercentage] = useState("");
  const [yearofpassing, setyearofpassing] = useState("");
  const [location, setlocation] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    let validationErrors = {};
    Object.keys(hireandtraindetails).forEach((name) => {
      const error = validate(name, hireandtraindetails[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log(
      companyname,
      hrname,
      email,
      phone,
      Qualification,
      percentage,
      yearofpassing,
      location
    );
    const data = {
      Name: companyname,
      HRname: hrname,
      Email: email,
      phone: phone,
      Qualification: Qualification,
      Percentage: percentage,
      YearOfPassing: yearofpassing,
      location: location,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/ee28f7eb-de47-4c1c-80eb-9aabcc1236eb",
        data
      )
      .then((res) => {
        console.log(res);
        setCompanyName("");
        setHrname("");
        setmail("");
        setphone("");
        setQualification("");
        setyearofpassing("");
        setpercentage("");
        setlocation("");
        console.log(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate("/searchcandidate");
    // e.target.reset();
    // setskills([]);
  };

  const formHandling = (e) => {
    setHireandtraindetails({
      ...hireandtraindetails,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: validate(e.target.name, e.target.value),
    });
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "160px", marginBottom: "50px" }}
      >
        <div className="job_fair_middle">
          <div className="row">
            <div className="col-md-4 m-auto">
              <div className="job_fair_logo_img text-center">
                <img
                  src="images/kovinda.png"
                  alt="kovinda"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="job_fair_form">
          <div className="row">

            <div
              className="col-6 col-6 m-auto px-2"
              style={{ padding: "0px 20px" }}
            >
              <form onSubmit={sendEmail}>
                <div
                  className="row job_fair_forms">


                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label> Name:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          style={{ borderRadius: "5px" }}
                          type="text"
                          name="companyname"
                          value={companyname}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                            formHandling(e);
                          }}
                          placeholder=" "
                        />
                        <span className="input__label">Enter Name</span>
                      </label>
                    </div>
                    <div className="text-danger error mb-4">
                      {errors.companyname}
                    </div>
                  </div>


                  {/* <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="first">HR Name:</label>
                                            <input type="text" class="form-control" placeholder=""
                                                name="hrname"
                                                value={hrname}
                                                onChange={(e) => {
                                                    setHrname(e.target.value);
                                                    formHandling(e);
                                                }} id="first" />
                                        </div>
                                        <div className="text-danger error mb-4">
                                            {errors.hrname}
                                        </div>
                                    </div> */}

                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Last Name:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          style={{ borderRadius: "5px" }}
                          name="hrname"
                          value={hrname}
                          onChange={(e) => {
                            setHrname(e.target.value);
                            formHandling(e);
                          }}
                          placeholder=" "
                        />
                        <span className="input__label">Enter Last Name</span>
                      </label>
                    </div>
                    <div className="text-danger error mb-4">{errors.hrname}</div>
                  </div>


                  {/* <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="first">Email ID:</label>
                                            <input type="email" class="form-control" placeholder=""
                                                name="email"
                                                value={email}
                                                onChange={(e) => {
                                                    setmail(e.target.value);
                                                    formHandling(e);
                                                }} id="first" />
                                        </div>
                                        <div className="text-danger error mb-4">
                                            {errors.email}
                                        </div>
                                    </div> */}

                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Email ID:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="email"
                          style={{ borderRadius: "5px" }}
                          name="email"
                          value={email}
                          onChange={(e) => {
                            setmail(e.target.value);
                            formHandling(e);
                          }}
                          // onChange={(e) => formHandling(e)}
                          // onChange={()=>setmail(e.target.name)}

                          placeholder=" "
                        />
                        <span className="input__label">Enter Email</span>
                      </label>
                    </div>
                    <div className="text-danger error mb-4">{errors.email}</div>
                  </div>


                  {/* <div class="col-md-6">
                                        <div class="form-group hire_group">
                                            <label for="first">phone Criteria:</label>
                                            <label className="input mt-1 mb-2">
                                            <input type="text" className="input__field form_control form_hire" placeholder="" id="first" required
                                                value={phone}
                                                onChange={(e) => {
                                                    setphone(e.target.value);

                                                }} />
                                            <span className="input__label">
                                                Enter phone
                                            </span>
                                            </label>
                                        </div>
                                    </div> */}

                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Phone Number:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          style={{ borderRadius: "5px" }}
                          required
                          name="phone"
                          value={phone}
                          onChange={(e) => {
                            setphone(e.target.value);
                          }}
                          // onChange={(e) => formHandling(e)}
                          // onChange={()=>setmail(e.target.name)}

                          placeholder=" "
                        />
                        <span className="input__label">Enter phone</span>
                      </label>
                    </div>
                  </div>



                  <div className=" col-lg-6 col-md-6 ">
                    <div className="form-group hire_group">
                      <label>Qualification:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          style={{ borderRadius: "5px" }}
                          required
                          name="Qualification"
                          value={Qualification}
                          onChange={(e) => {
                            setQualification(e.target.value);
                          }}
                          // onChange={(e) => formHandling(e)}
                          // onChange={()=>setmail(e.target.name)}

                          placeholder=" "
                        />
                        <span className="input__label">Enter Qualification</span>
                      </label>
                    </div>
                  </div>



                  <div className=" col-lg-6 col-md-6 ">
                    <div className="form-group hire_group">
                      <label>Year Of Passing:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="number"
                          style={{ borderRadius: "5px" }}
                          required
                          name="yearofpassing"
                          value={yearofpassing}
                          onChange={(e) => {
                            setyearofpassing(e.target.value);
                          }}
                          placeholder=" "
                        />
                        <span className="input__label">Enter YearOfPassing</span>
                      </label>
                    </div>
                  </div>


                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Percentage(%) / CGPA:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          style={{ borderRadius: "5px" }}
                          required
                          name="percentage"
                          value={percentage}
                          onChange={(e) => {
                            setpercentage(e.target.value);
                          }}
                          placeholder=" "
                        />
                        <span className="input__label">Enter percentage</span>
                      </label>
                    </div>
                  </div>


                  <div className=" col-lg-6 col-md-6">
                    <div className="form-group hire_group">
                      <label>Job location:</label>
                      <label
                        className="input mt-1 mb-2"
                        style={{
                          border: "1px solid #270d44",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          className="input__field form_control form_hire"
                          type="text"
                          required
                          style={{ borderRadius: "5px" }}
                          rows={3}
                          name="location"
                          value={location}
                          onChange={(e) => {
                            setlocation(e.target.value);
                          }}
                          placeholder=" "
                        />
                        <span className="input__label">
                          Enter Job location
                        </span>
                      </label>
                    </div>
                  </div>


                  <button className="jobfair_btn jobfair_regbtn mt-3" >Register</button>
                </div>
                {/* <button className="update" >
                                    Submit
                                </button> */}

                {/* <button className="jobfair_btn ml-auto">Register</button> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobFair;